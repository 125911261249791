/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  role: "",
  roleId: "",
  first_name: "",
  last_name: "",
  username: "",
  email: "",
  image: "",
  token: "",
  isNewAdmin: false,
  isLogin: false,
  permissions: {},
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.id = action.payload.data.adminInfo._id;
      state.role = action.payload.data.adminInfo.role;
      state.roleId = action.payload.data.adminInfo.role_id;
      state.first_name = action.payload.data.adminInfo.first_name;
      state.last_name = action.payload.data.adminInfo.last_name;
      state.username = action.payload.data.adminInfo.username;
      state.email = action.payload.data.adminInfo.email;
      state.image = action.payload.data.adminInfo.image;
      state.token = action.payload.data.adminInfo.token;
      state.isNewAdmin = action.payload.data.adminInfo.isNewAdmin;
      state.isLogin = action.payload.status === "success" ? true : false;
      state.permissions = action.payload.data.permissions;
    },
    updatePermissions: (state, action) => {
      state.permissions = action.payload.data.permissions;
    },
    setUserUpdate: (state, action) => {
      if (action.payload.data.image) {
        state.first_name = action.payload.data.fname;
        state.last_name = action.payload.data.lname;
        state.image = action.payload.data.image;
      } else {
        state.first_name = action.payload.data.fname;
        state.last_name = action.payload.data.lname;
      }
    },
    reset: () => initialState,
  },
});

export const { setUser, reset, setUserUpdate, updatePermissions } =
  UserSlice.actions;

export default UserSlice.reducer;
