import React, { useEffect, useState } from "react";
import Layout from "../Partials/Layout";
// import CreateNft from "./CreateNft";
// import Hero from "./Hero";
import SellHistoryMarketVisitorAnalytic from "./SellHistoryMarketVisitorAnalytic";
import TopCalSection from "./TopCalSection";
import TopSellerTopBuyerSliderSection from "./TopSellerTopBuyerSliderSection";
import TopVotedTable from "./TopVotedTable";
import UpdateTable from "./UpdateTable";

import useRequestFun from "../../hooks/Axios/useRequestFun";
import DashboardSkeleton from "../Helpers/Skeleton/dashboard/DashboardSkeleton";

export default function Home() {
  // const trending = datas.datas;
  const [dashData, setDashData] = useState(null);
  const { getRequest } = useRequestFun();
  useEffect(() => {
    getRequest("getdashboard").then((res) => {
      setDashData(res.data.data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      {dashData ? (
        <div className="wrapperHomePage">
          <TopCalSection className="mb-10" dashData={dashData} />
          {/* <Hero className="mb-10" /> */}
          {/* <CreateNft /> */}
          {/* <TrendingSection trending={trending} className="mb-10" /> */}
          <SellHistoryMarketVisitorAnalytic
            className="mb-10"
            dashData={dashData}
          />
          <TopSellerTopBuyerSliderSection
            className="mb-10"
            dashData={dashData}
          />
          <UpdateTable className="mb-10" dashData={dashData} />
          <TopVotedTable className="mb-10" dashData={dashData} />
        </div>
      ) : (
        <DashboardSkeleton />
      )}
    </Layout>
  );
}
