import React from "react";
import Layout from "../../components/Partials/Layout";
import AddRole from "../../components/Roles/AddRole";

export default function Role() {
  return (
    <>
      <Layout>
        <AddRole />
      </Layout>
    </>
  );
}
