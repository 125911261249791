/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
/* eslint-disable no-else-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import AWS from "aws-sdk";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import profile from "../../../assets/images/Loading_icon.gif";
import profileInfo from "../../../assets/images/profile-info-profile.png";
import useRequestFun from "../../../hooks/Axios/useRequestFun";
import { setUserUpdate } from "../../../store/user/UserSlice";
import Icons from "../../Helpers/Icons";
import InputCom from "../../Helpers/Inputs";
import LottieLoader from "../../Helpers/LottieLoader";
import SelectBox from "../../Helpers/SelectBox";
import Layout from "../../Partials/Layout";

export default function AuthProfile() {
  // const { state } = useLocation();
  //   const [admin, setAdmin] = useState([]);
  const { id } = useSelector((state) => state.user);
  const [profileImg, setProfileImg] = useState(profile);
  const [image, setImage] = useState();
  const profileImgInput = useRef(null);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState();
  const [isLoader, setIsLoader] = useState(true);
  const genderData = ["Select Gender", "Male", "Female", "Other's"];

  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const { getRequest, putRequest } = useRequestFun();
  const [adminRole, setAdminrole] = useState("");
  const [messages, setMessages] = useState({});
  const imageMimeType = /image\/(png|jpg|jpeg)/i;
  const dispatch = useDispatch();

  // const profileImgInput = useRef(null);

  useEffect(() => {
    const getAdmin = () => {
      getRequest(`getadmininfobyid/${id}`).then(async (res) => {
        setFname(res.data.data.first_name);
        setLname(res.data.data.last_name);
        setUserName(res.data.data.username);
        setEmail(res.data.data.email);
        setPhone(res.data.data.phone);
        setGender(res.data.data.gender);
        setAdminrole(res.data.data.role);
        setStatus(res.data.data.status);
        setProfileImg(
          res.data.data.image === "" ? profileInfo : res.data.data.image
        );
        setIsLoader(false);
      });
    };
    getAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const browseProfileImg = () => {
    profileImgInput.current.click();
  };
  const profileImgChangHandler = (e) => {
    if (e.target.value !== "") {
      const imgReader = new FileReader();
      if (e.target.files[0].size > 5000000) {
        setMessages({ image: "Image Size Not More Than 5MB" });
        return;
      } else {
        setMessages({ image: "" });
      }
      if (!e.target.files[0].type.match(imageMimeType)) {
        setMessages({ image: "Image mime type is not valid" });
        return;
      } else {
        setMessages({ image: "" });
      }
      setImage(e.target.files[0]);
      imgReader.onload = (event) => {
        setProfileImg(event.target.result);
      };
      imgReader.readAsDataURL(e.target.files[0]);
    }
  };
  // cover img
  const updateAdmin = async (e) => {
    // Success
    e.preventDefault();
    return toast.warning("Disabled For Demo Version!");
    let isValid = true;
    if (fname === "") {
      setMessages({ fname: "First name is required" });
      isValid = false;
    } else if (lname === "") {
      setMessages({ lname: "Last name is required" });
      isValid = false;
    } else if (gender === "" || gender === "Select Gender") {
      setMessages({ gender: "Gender is required" });
      isValid = false;
    } else if (adminRole === "" || adminRole === "Select Admin Role") {
      setMessages({ role: "Role is required" });
      isValid = false;
    } else if (phone === "") {
      setMessages({ phone: "Role is required" });
      isValid = false;
    }

    if (!isValid) {
      return;
    }
    setIsLoader(true);
    if (image) {
      AWS.config.update({
        region: process.env.REACT_APP_AWS_REGION,
        accessKeyId: process.env.REACT_APP_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_SECRET_KEY,
      });
      let s3 = new AWS.S3({
        apiVersion: "2006-03-01",
      });

      const uploadParams = {
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Key: `admin/${image.name}`,
        Body: image,
        Acl: "public-read",
      };
      s3.upload(uploadParams, (err, data) => {
        if (err) {
          toast.error(`${err}`);
        } else {
          putRequest(`profileupdate`, {
            first_name: fname,
            last_name: lname,
            image: data.Location,
            gender,
            phone,
          })
            .then((res) => {
              const userUpdteData = {
                fname,
                lname,
                image: data.Location,
              };
              dispatch(setUserUpdate({ data: userUpdteData }));
              setIsLoader(false);
              toast.success(`${res.data.message}`, {
                icon: `🙂`,
              });
            })
            .catch((error) => {
              setIsLoader(false);
              toast.error(`${error.response.data.message}`);
            });
        }
      });
    } else {
      putRequest(`profileupdate`, {
        first_name: fname,
        last_name: lname,
        gender,
        phone,
      })
        .then((res) => {
          const userUpdteData = {
            fname,
            lname,
          };
          dispatch(setUserUpdate({ data: userUpdteData }));
          setIsLoader(false);
          toast.success(`${res.data.message}`, {
            icon: `🙂`,
          });
        })
        .catch((error) => {
          setIsLoader(false);
          toast.error(`${error.response.data.message}`);
        });
    }
  };
  return (
    <>
      {isLoader ? (
        <LottieLoader />
      ) : (
        <Layout>
          <div className="settings-wrapper w-full relative mb-10">
            <div className="mainWrapper w-full">
              {/* heading */}

              <div className="content-container w-full rounded-2xl bg-white">
                <div className="content-body-items flex-1 p-10">
                  <div className="heading w-full mb-6">
                    <h1 className="text-26 font-bold text-dark-gray antialiased">
                      My Profile
                    </h1>
                  </div>
                  <form onSubmit={updateAdmin}>
                    <div className="personal-info-tab w-full flex flex-col justify-between">
                      <div className="flex flex-col-reverse sm:flex-row">
                        <div className="flex-1 sm:mr-10">
                          <div className="fields w-full">
                            {/* first name and last name */}
                            <div className="xl:flex xl:space-x-7 mb-6">
                              <div className="field w-full mb-6 xl:mb-0">
                                <InputCom
                                  label="First Name"
                                  type="text"
                                  name="firstName"
                                  inputHandler={(e) => setFname(e.target.value)}
                                  value={fname}
                                  placeholder="First Name Here"
                                />
                                <div className="text-red-500">
                                  {messages.fname}
                                </div>
                              </div>
                              <div className="field w-full">
                                <InputCom
                                  label="Last Name"
                                  type="text"
                                  name="lastName"
                                  inputHandler={(e) => setLname(e.target.value)}
                                  value={lname}
                                  placeholder="Last Name Here"
                                />

                                <div className="text-red-500">
                                  {messages.lname}
                                </div>
                              </div>
                            </div>
                            <div className="xl:flex xl:space-x-7 mb-6">
                              <div className="field w-full mb-6 xl:mb-0">
                                <InputCom
                                  label="User Name"
                                  type="text"
                                  name="userName"
                                  inputHandler={(e) =>
                                    setUserName(e.target.value)
                                  }
                                  value={userName}
                                  readOnlyValue="true"
                                  placeholder="@example"
                                />

                                <div className="text-red-500">
                                  {messages.userName}
                                </div>
                              </div>
                              <div className="field w-full">
                                <InputCom
                                  label="Email"
                                  type="email"
                                  name="email"
                                  inputHandler={(e) => setEmail(e.target.value)}
                                  value={email}
                                  readOnlyValue="true"
                                  placeholder="admin@example.com"
                                />
                                <div className="text-red-500">
                                  {messages.email}
                                </div>
                              </div>
                            </div>
                            {/* User Name */}
                            <div className="xl:flex xl:space-x-7 mb-6">
                              <div className="field w-full mb-6 xl:mb-0">
                                <div className="chart-heading mb-4 xl:flex justify-between items-center">
                                  <SelectBox
                                    label="Gender"
                                    name="Gender"
                                    datas={genderData}
                                    action={(value) => setGender(value)}
                                    selectedValue={gender}
                                  />
                                </div>
                                <div className="text-red-500">
                                  {messages.gender}
                                </div>
                              </div>
                              <div className="field w-full">
                                <InputCom
                                  label="Role"
                                  type="text"
                                  name="Role"
                                  value={adminRole}
                                  readOnlyValue="true"
                                  placeholder="Admin Role"
                                />
                              </div>
                            </div>
                            <div className="xl:flex xl:space-x-7 mb-6">
                              <div className="field w-full mb-6 xl:mb-0">
                                <InputCom
                                  label="Phone"
                                  type="text"
                                  name="phone"
                                  inputHandler={(e) => setPhone(e.target.value)}
                                  value={phone}
                                  placeholder="+880124365464"
                                />
                                <div className="text-red-500">
                                  {messages.phone}
                                </div>
                              </div>
                              <div className="field w-full mb-6 xl:mb-0">
                                {status && (
                                  <>
                                    <label className="input-label text-dark-gray text-xl font-bold block mb-2.5">
                                      Status
                                    </label>
                                    <div className="flex">
                                      <div className="bg-green-600 rounded-full px-5 py-2 text-white">
                                        Active
                                      </div>
                                    </div>
                                  </>
                                )}

                                <div className="text-red-500">
                                  {messages.status}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="w-[232px] mb-10">
                          <div className="update-profile w-full mb-9">
                            <h1 className="text-xl tracking-wide font-bold text-dark-gray flex items-center mb-2">
                              Update Profile
                              <span className="ml-1">
                                <Icons name="block-question" />
                              </span>
                            </h1>
                            <p className="text-base text-thin-light-gray mb-5">
                              {/* Profile of at least Size
                          <span className="ml-1 text-dark-gray">300x300</span>. */}
                              PNG, JPG or JPEG only
                              <span className="ml-1 text-dark-gray">
                                Max 5mb
                              </span>
                              .
                            </p>
                            <div className="flex justify-center">
                              <div className="w-full relative">
                                <img
                                  src={profileImg}
                                  alt=""
                                  className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] rounded-full overflow-hidden object-cover profile_image"
                                />
                                <input
                                  ref={profileImgInput}
                                  onChange={(e) => profileImgChangHandler(e)}
                                  type="file"
                                  className="hidden"
                                />
                                <div
                                  onClick={browseProfileImg}
                                  className="w-[32px] h-[32px] absolute bottom-7 sm:right-10 right-[105px]  hover:bg-pink bg-dark-gray rounded-full cursor-pointer"
                                >
                                  <svg
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M16.5147 11.5C17.7284 12.7137 18.9234 13.9087 20.1296 15.115C19.9798 15.2611 19.8187 15.4109 19.6651 15.5683C17.4699 17.7635 15.271 19.9587 13.0758 22.1539C12.9334 22.2962 12.7948 22.4386 12.6524 22.5735C12.6187 22.6034 12.5663 22.6296 12.5213 22.6296C11.3788 22.6334 10.2362 22.6297 9.09365 22.6334C9.01498 22.6334 9 22.6034 9 22.536C9 21.4009 9 20.2621 9.00375 19.1271C9.00375 19.0746 9.02997 19.0109 9.06368 18.9772C10.4123 17.6249 11.7609 16.2763 13.1095 14.9277C14.2295 13.8076 15.3459 12.6913 16.466 11.5712C16.4884 11.5487 16.4997 11.5187 16.5147 11.5Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M20.9499 14.2904C19.7436 13.0842 18.5449 11.8854 17.3499 10.6904C17.5634 10.4694 17.7844 10.2446 18.0054 10.0199C18.2639 9.76139 18.5261 9.50291 18.7884 9.24443C19.118 8.91852 19.5713 8.91852 19.8972 9.24443C20.7251 10.0611 21.5492 10.8815 22.3771 11.6981C22.6993 12.0165 22.7105 12.4698 22.3996 12.792C21.9238 13.2865 21.4443 13.7772 20.9686 14.2717C20.9648 14.2792 20.9536 14.2867 20.9499 14.2904Z"
                                      fill="white"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <div className="text-red-500">{messages.image}</div>
                          </div>
                        </div>
                      </div>
                      <div className="content-footer w-full">
                        <div className="w-full h-[120px] border-t border-light-purple flex justify-end items-center">
                          <div className="flex space-x-4 mr-9">
                            <button
                              type="submit"
                              className="w-[152px] h-[46px] flex justify-center items-center gradientButton text-base rounded-full text-white"
                            >
                              Update Profile
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}
