import React, { useState } from "react";
// import cover from "../../assets/images/profile-info-cover.png";
// import profile from "../../assets/images/profile-info-profile.png";
// import faq from "../../data/faq.json";
import { AiFillSetting } from "react-icons/ai";
import { ImKey } from "react-icons/im";
import { MdMarkEmailRead, MdEvent } from "react-icons/md";
// import Icons from "../Helpers/Icons";
import { FaBitcoin } from "react-icons/fa";
import { useSelector } from "react-redux";
import Layout from "../Partials/Layout";
import ChangePasswordTab from "./Tabs/ChangePasswordTab";
import EmailSettingsTab from "./Tabs/EmailSettingsTab";
import GeneralSettingsTab from "./Tabs/GeneralSettingsTab";
// import FaqTab from "./Tabs/FaqTab";
import LottieLoader from "../Helpers/LottieLoader";
// import LoginActivityTab from "./Tabs/LoginActivityTab";
import FeeSettingTab from "./Tabs/FeeSettingTab";
import EventsSettings from "./Tabs/EventsSettings";

// import PaymentMathodsTab from "./Tabs/PaymentMathodsTab";
// import PersonalInfoTab from "./Tabs/PersonalInfoTab";
// import TermsConditionTab from "./Tabs/TermsConditionTab";

export default function Settings() {
  const [isLoader, setIsLoader] = useState(false);
  const { role } = useSelector((store) => store.user);
  const tabs = [
    {
      id: 1,
      name: "Change Password",
    },
    {
      id: 2,
      name: "General",
    },
    {
      id: 3,
      name: "Email Settings",
    },
    {
      id: 4,
      name: "Fees Settings",
    },
    {
      id: 5,
      name: "Events Settings",
    },
  ];
  const [tab, setTab] = useState(tabs[0].name);
  const tabHandler = (value) => {
    setTab(value);
  };
  const setLoader = (val) => {
    setIsLoader(val);
  };
  // const [profileImg, setProfileImg] = useState(profile);
  // const [coverImg, setCoverImg] = useState(cover);

  // // profile img
  // const profileImgInput = useRef(null);
  // const browseProfileImg = () => {
  //   profileImgInput.current.click();
  // };
  // const profileImgChangHandler = (e) => {
  //   if (e.target.value !== "") {
  //     const imgReader = new FileReader();
  //     imgReader.onload = (event) => {
  //       setProfileImg(event.target.result);
  //     };
  //     imgReader.readAsDataURL(e.target.files[0]);
  //   }
  // };
  // cover img
  // const coverImgInput = useRef(null);
  // const browseCoverImg = () => {
  //   coverImgInput.current.click();
  // };
  // const coverImgChangHandler = (e) => {
  //   if (e.target.value !== "") {
  //     const imgReader = new FileReader();
  //     imgReader.onload = (event) => {
  //       setCoverImg(event.target.result);
  //     };
  //     imgReader.readAsDataURL(e.target.files[0]);
  //   }
  // };

  // fab tab
  // const faqData = faq.datas;
  return (
    <>
      {isLoader ? (
        <LottieLoader />
      ) : (
        <Layout>
          <div className="settings-wrapper w-full relative mb-10">
            <div className="mainWrapper w-full">
              {/* heading */}

              <div className="content-container w-full pt-10 rounded-2xl bg-white">
                <div className="content-heading w-full mb-8 lg:px-10 px-4">
                  <h1 className="text-xl font-bold text-dark-gray antialiased">
                    Settings
                  </h1>
                </div>
                <div className="content-body w-full lg:flex lg:px-10 px-4">
                  <div className="content-tab-items lg:w-[230px] w-full mr-2">
                    <ul className="overflow-hidden mb-10 lg:mb-0">
                      <li
                        onClick={() => tabHandler("Change Password")}
                        className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-left lg:float-none overflow-hidden ${
                          tab === "Change Password"
                            ? "text-purple"
                            : " text-thin-light-gray"
                        }`}
                      >
                        <div>
                          <ImKey className="text-xl" />
                        </div>
                        <div>
                          <p className="text-18 tracking-wide">
                            Change Password
                          </p>
                        </div>
                      </li>
                      {role === "superadmin" && (
                        <>
                          <li
                            onClick={() => tabHandler("General")}
                            className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-left lg:float-none overflow-hidden ${
                              tab === "General"
                                ? "text-purple"
                                : " text-thin-light-gray"
                            }`}
                          >
                            <div>
                              {/* <Icons name="bank-card" /> */}
                              <AiFillSetting className="text-xl" />
                            </div>
                            <div>
                              <p className="text-18 tracking-wide">
                                General Settings
                              </p>
                            </div>
                          </li>
                          <li
                            onClick={() => tabHandler("Email Settings")}
                            className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-left lg:float-none overflow-hidden ${
                              tab === "Email Settings"
                                ? "text-purple"
                                : " text-thin-light-gray"
                            }`}
                          >
                            <div>
                              {/* <Icons name="notification-setting" /> */}
                              <MdMarkEmailRead className="text-xl" />
                            </div>
                            <div>
                              <p className="text-18 tracking-wide">
                                Email Settings
                              </p>
                            </div>
                          </li>
                          <li
                            onClick={() => tabHandler("Fees Settings")}
                            className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-left lg:float-none overflow-hidden ${
                              tab === "Fees Settings"
                                ? "text-purple"
                                : " text-thin-light-gray"
                            }`}
                          >
                            <div>
                              {/* <Icons name="login-activity" /> */}
                              <FaBitcoin className="text-xl " />
                            </div>
                            <div>
                              <p className="text-18 tracking-wide">
                                Fees Settings
                              </p>
                            </div>
                          </li>
                          <li
                            onClick={() => tabHandler("Events Settings")}
                            className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-left lg:float-none overflow-hidden ${
                              tab === "Events Settings"
                                ? "text-purple"
                                : " text-thin-light-gray"
                            }`}
                          >
                            <div>
                              {/* <Icons name="login-activity" /> */}
                              <MdEvent className="text-xl " />
                            </div>
                            <div>
                              <p className="text-18 tracking-wide">
                                Events Settings
                              </p>
                            </div>
                          </li>
                        </>
                      )}

                      {/* <li
                      onClick={() => tabHandler("password")}
                      className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-left lg:float-none overflow-hidden ${
                        tab === "password"
                          ? "text-purple"
                          : " text-thin-light-gray"
                      }`}
                    >
                      <div>
                        <Icons name="password-hover" />
                      </div>
                      <div>
                        <p className="text-18 tracking-wide">Change Password</p>
                      </div>
                    </li>
                    <li
                      onClick={() => tabHandler("faq")}
                      className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-left lg:float-none overflow-hidden ${
                        tab === "faq" ? "text-purple" : " text-thin-light-gray"
                      }`}
                    >
                      <div>
                        <Icons name="block-question" />
                      </div>
                      <div>
                        <p className="text-18 tracking-wide">FAQ</p>
                      </div>
                    </li>
                    <li
                      onClick={() => tabHandler("terms")}
                      className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-left lg:float-none overflow-hidden ${
                        tab === "terms"
                          ? "text-purple"
                          : " text-thin-light-gray"
                      }`}
                    >
                      <div>
                        <Icons name="page-right" />
                      </div>
                      <div>
                        <p className="text-18 tracking-wide">
                          Terms and Conditions
                        </p>
                      </div>
                    </li> */}
                    </ul>
                  </div>
                  <div className="w-[1px] bg-[#E3E4FE] mr-10"></div>
                  <div className="content-body-items flex-1">
                    {/* {tab === "personal" && (
                    <div className="tab-item">
                      <PersonalInfoTab
                        profileImg={profileImg}
                        coverImg={coverImg}
                        browseProfileImg={browseProfileImg}
                        profileImgInput={profileImgInput}
                        profileImgChangHandler={profileImgChangHandler}
                        coverImgChangHandler={coverImgChangHandler}
                        browseCoverImg={browseCoverImg}
                        coverImgInput={coverImgInput}
                      />
                    </div>
                  )} */}
                    {tab === "General" && (
                      <div className="tab-item">
                        <GeneralSettingsTab
                          setIsLoader={(value) => setLoader(value)}
                        />
                      </div>
                    )}
                    {tab === "Email Settings" && (
                      <div className="tab-item">
                        <EmailSettingsTab
                          setIsLoader={(value) => setLoader(value)}
                        />
                      </div>
                    )}
                    {tab === "Fees Settings" && (
                      <FeeSettingTab handelLoader={setLoader} />
                    )}
                    {tab === "Events Settings" && (
                      <EventsSettings handelLoader={setLoader} />
                    )}

                    {tab === "Change Password" && <ChangePasswordTab />}
                    {/*  {tab === "faq" && <FaqTab datas={faqData} />}
                  {tab === "terms" && <TermsConditionTab />} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}
