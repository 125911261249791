import React, { useState } from "react";
import MarketVisitorAnalytic from "../Charts/MarketVisitorAnalytic";
// import SelectBox from "../Helpers/SelectBox";
import SellHistoryWidget from "./SellHistoryWidget";

export default function SellHistoryMarketVisitorAnalytic({
  className,
  dashData,
}) {
  const [currencyDataLvl] = useState([
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]);
  return (
    <div
      className={`sell-history-market-visitor-analytic w-full ${
        className || ""
      }`}
    >
      <div className="wrapperContent w-full  lg:flex xl:space-x-8 lg:space-x-4">
        <div className="lg:w-1/2 w-full mb-10 lg:mb-0">
          <SellHistoryWidget sellGraph={dashData?.sellHistory} />
        </div>
        <div className="flex-1 ">
          <div className="market-visitor w-full  md:p-8 p-4 h-full bg-white overflow-hidden rounded-2xl  shadowSection">
            <div className="flex flex-col justify-between h-full">
              <div className="content flex justify-between items-center mb-5">
                <div>
                  <h1 className="text-xl font-bold text-dark-gray tracking-wide">
                    Market Users
                  </h1>
                </div>
                {/* <SelectBox datas={filterDatas} action={dataSetHandler} /> */}
              </div>
              <div className="h-[233px]">
                <MarketVisitorAnalytic
                  datasets={dashData?.userGraph}
                  dataLvls={currencyDataLvl}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
