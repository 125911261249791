import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = ({ redirectPath = "/", children }) => {
  const {isLogin} = useSelector((store) => store.user);
  if (isLogin) {
    return <Navigate to={redirectPath} replace />;
  }
  return children || <Outlet />;
};

export default PublicRoute;
