import { GridColDef } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import notfOund from "../../assets/images/not-found.svg";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import Layout from "../Partials/Layout";

import DataTable from "../Helpers/DataGrid";
import LottieLoader from "../Helpers/LottieLoader";
// import { logDOM } from "@testing-library/react";

export default function Auction() {
  const [isLoader, setIsLoader] = useState(true);
  const [auctionlist, setAuctionList] = useState([]);
  const [networkData, setNetworkData] = useState();
  // const [balance, setBalance] = useState("0");
  const { getRequest } = useRequestFun();
  const getRequests = useCallback(() => {
    getRequest(`getNetWorks`).then(async (response) => {
      if (response.data.data[0]) {
        setNetworkData(response.data.data[0]);

        getRequest("getactiveauction").then((res) => {
          if (res?.data?.data) {
            setAuctionList(res?.data?.data);
          }
        });
      }
      setIsLoader(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRequests();
  }, [getRequests]);
  function datediff(dateOne, dateTwo) {
    const daySecs = 24 * 60 * 60 * 1000;

    return Math.ceil((dateTwo - dateOne) / daySecs);
  }
  const truncate = (str) =>
    // eslint-disable-next-line prefer-template
    str.length > 10 ? str.substring(0, 3) + "..." + str.slice(-3) : str;

  const columns: GridColDef[] = [
    {
      field: "name",
      minWidth: 150,
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        // eslint-disable-next-line no-unused-expressions
        <a
          href={`nfts/${params.row.contract_address}/${params.row.token_id}`}
          rel="noreferrer"
          className="hover:text-blue-400"
        >
          {params.row.name ? `${params.row.name}` : "N/A"}
        </a>
      ),
    },
    {
      field: "image",
      minWidth: 150,
      headerName: "Image",
      flex: 1,
      renderCell: (params) =>
        params?.row?.metadata?.metadata?.image !== "" ? (
          <a
            href={`nfts/${params.row.contract_address}/${params.row.token_id}`}
            rel="noreferrer"
            className="hover:text-blue-400"
          >
            <img
              src={params?.row?.metadata?.metadata?.image}
              alt="Collection"
              width={60}
              height={60}
              loading="eager"
            />
          </a>
        ) : (
          <img src={notfOund} alt="Collection" width={60} height={60} />
        ),
    },
    {
      field: "contract_address",
      minWidth: 150,
      headerName: "Contract",
      flex: 1,
      renderCell: (params) => (
        <a
          href={`${networkData.explorer_url}address/${params.row.contract_address}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {truncate(params.row.contract_address)}
        </a>
      ),
    },
    {
      field: "creator_address",
      minWidth: 150,
      headerName: "Creator",
      flex: 1,
      renderCell: (params) =>
        params?.row?.metadata?.contractMetadata?.contractDeployer ? (
          <a
            href={`${networkData.explorer_url}address/${params?.row?.metadata?.contractMetadata?.contractDeployer}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {truncate(
              params?.row?.metadata?.contractMetadata?.contractDeployer
            )}
          </a>
        ) : (
          "Unknown"
        ),
    },
    {
      field: "owner",
      minWidth: 150,
      headerName: "Owner",
      flex: 1,
      renderCell: (params) => (
        <a
          href={`${networkData.explorer_url}address/${params?.row?.owner.wallet}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {truncate(params?.row?.owner.wallet)}
        </a>
      ),
    },
    {
      field: "price",
      minWidth: 150,
      headerName: "Listed Price",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.price ? `${params.row.price} ${networkData.symbol}` : "N/A",
    },
    {
      field: "duration",
      minWidth: 150,
      headerName: "Remaining Day's",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        `${datediff(Date.now(), new Date(params.row.duration))} days`,
    },
  ];

  return (
    <>
      <Layout>
        {isLoader ? (
          <LottieLoader />
        ) : (
          <div
            className={`tableForUpdate w-full p-8 bg-white overflow-hidden rounded-2xl shadowSection relative min-h-[520px] `}
          >
            <div className="header w-full sm:flex justify-between items-center mb-5">
              <div className="flex space-x-2 items-center mb-2 sm:mb-0">
                <h1 className="text-xl font-bold text-dark-gray tracking-wide">
                  Active Auction List
                </h1>
              </div>
            </div>
            <div className="relative w-full overflow-x-auto sm:rounded-lg h-[800px]">
              {auctionlist && (
                <DataTable rowList={auctionlist} columns={columns} />
              )}
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}
