import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  drawer: true,
  administratorCollapse: false,
  categoryCollapse: false,
  userCollapse: false,
  blogCollapse: false,
  productCollapse: false,
  subscriptionCollapse: false,
  sliderSettingsCollapse: false,
};

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    drawerToggle: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.drawer = !state.drawer;
    },
    administratorToggle: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.administratorCollapse = !state.administratorCollapse;
    },
    categoryToggle: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.categoryCollapse = !state.categoryCollapse;
    },
    blogToggleFunc: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.blogCollapse = !state.blogCollapse;
    },
    productToggle: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.productCollapse = !state.productCollapse;
    },
    sliderSettingsToggle: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.sliderSettingsCollapse = !state.sliderSettingsCollapse;
    },
    disAdminColl: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.administratorCollapse = false;
    },
    disCategoryColl: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.categoryCollapse = false;
    },
    usersToggle: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.userCollapse = !state.userCollapse;
    },
    disUsersColl: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.userCollapse = false;
    },
    disBlogCollapse: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.blogCollapse = false;
    },
    disProductCollapse: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.productCollapse = false;
    },
    subscriptionToggle: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.subscriptionCollapse = true;
    },
    disSubscriptionCollapse: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.subscriptionCollapse = false;
    },
    disSliderSettingsCollapse: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.sliderSettingsCollapse = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  drawerToggle,
  administratorToggle,
  disAdminColl,
  categoryToggle,
  disCategoryColl,
  usersToggle,
  disUsersColl,
  blogToggleFunc,
  disBlogCollapse,
  productToggle,
  disProductCollapse,
  subscriptionToggle,
  disSubscriptionCollapse,
  sliderSettingsToggle,
  disSliderSettingsCollapse,
} = counterSlice.actions;

export default counterSlice.reducer;
