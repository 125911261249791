import AWS from "aws-sdk";

export default function useFileUpload() {
  const fileUpload = async (file, path, mimeType) => {
    try {
      AWS.config.update({
        region: process.env.REACT_APP_AWS_REGION,
        accessKeyId: process.env.REACT_APP_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_SECRET_KEY,
      });

      const s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        signatureVersion: "v4",
      });
      const currentTimestamp = new Date().getTime();
      const uploadParams = {
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Key: `${path}/${currentTimestamp}${file?.name}`,
        Body: file,
        Acl: "public-read",
        ContentType: mimeType,
      };
      const uploadedImg = await s3.upload(uploadParams).promise();
      return uploadedImg.Location;
    } catch (error) {
      return error;
    }
  };

  return {
    fileUpload,
  };
}
