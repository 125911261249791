/* eslint-disable no-unreachable */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
// import Switch from "@mui/material/Switch";
// import { useState } from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useRequestFun from "../../../hooks/Axios/useRequestFun";
import SwitchCom from "../../Helpers/SwitchCom";
import ConfirmModal from "../../modal/ConfirmModal";

export default function EventsSettings() {
  const [checked, setChecked] = useState();
  const [settingId, setSettingId] = useState("");
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const { getRequest, putRequest } = useRequestFun();

  useEffect(() => {
    getRequest("generalsetting").then((res) => {
      setSettingId(res.data.data._id);
      setChecked(res.data.data.votingEvent);
    });
  }, [checked]);

  const handleChange = () => {
    setIsConfirmModal(!isConfirmModal);
  };
  const handalerConfirm = () => {
    return toast.warning("Disabled For Demo Version!");
    putRequest(`updateEventVoting/${settingId}`, {
      status: checked !== true,
    }).then((res) => {
      setChecked(res.data.data.votingEvent);
    });

    setIsConfirmModal(!isConfirmModal);
  };
  return (
    <>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <tbody>
          <tr className="text-base text-thin-light-gray border-b borderBottomDefault ">
            <td className="py-4 ">Name</td>
            <td className="py-4 ">Action</td>
          </tr>
          <tr className="bg-white border-b hover:bg-gray-50">
            <td className=" py-4">
              <div className="flex justify-start">
                <span className="text-base text-dark-gray font-medium whitespace-nowrap px-2">
                  Vote Event
                </span>
              </div>
            </td>
            <td className=" py-4">
              {/* <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            /> */}
              <SwitchCom
                value={checked ? "active" : ""}
                handler={handleChange}
              />
            </td>
          </tr>
        </tbody>
      </table>
      {/* bodyMessage={`Are you sure ${
            currentStatus === "active" ? "inactive" : "active"
          } this collection with ${
            totalNft > 1 ? `${totalNft} NFTs` : `${totalNft} NFT`
          }  ? `} */}
      {isConfirmModal && (
        <ConfirmModal
          headMessage="Confirm"
          bodyMessage="Are you sure want to change the event status"
          btn1="Confirm"
          btn2="Not Now"
          isMoadl={() => setIsConfirmModal(!isConfirmModal)}
          actionCofirm={() => handalerConfirm()}
        ></ConfirmModal>
      )}
    </>
  );
}
