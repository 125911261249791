import { Link } from "react-router-dom";
import Bidding from "../../assets/images/icons/auction.png";
import Collection from "../../assets/images/icons/collection.png";
import Dollar from "../../assets/images/icons/dollar-sign.png";
import Ethereum from "../../assets/images/icons/ethereum.png";
import Fees from "../../assets/images/icons/fees.png";
import Listing from "../../assets/images/icons/listing.png";
import Product from "../../assets/images/icons/product.png";
import Sell from "../../assets/images/icons/sell.png";
import HistoryAnalyticsCard from "../Cards/HistoryAnalyticsCard";

export default function TopCalSection({ className, dashData }) {
  return (
    <>
      {dashData && (
        <div
          className={`grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-[30px] ${
            className || ""
          }`}
        >
          <HistoryAnalyticsCard
            data={dashData}
            title="Sells Amount"
            numberOfCount={
              dashData?.totalSellAmount[0]?.total
                ? Number(dashData?.totalSellAmount[0]?.total).toFixed(6)
                : 0
            }
            // numberOfAnalyse={324.75}
            numberOfTotalSell={dashData?.totalSell ? dashData?.totalSell : 0}
            primaryColor="#27AE60"
            iconBg="#E9F7EF"
          >
            <img src={Sell} alt="" width="30" height="30" />
          </HistoryAnalyticsCard>
          <Link to="/nfts">
            <HistoryAnalyticsCard
              data={dashData}
              title="Total NFTs"
              numberOfCount={dashData?.totalNfts}
              numberOfMintOnSystem={
                dashData?.totalNumberOfMintingonSystem
                  ? dashData?.totalNumberOfMintingonSystem
                  : 0
              }
              // numberOfAnalyse={324.75}

              primaryColor="#F539F8"
              iconBg="#FEEBFE"
            >
              <svg
                width="28"
                height="30"
                viewBox="0 0 28 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.8146 13.1935C13.3126 13.0277 12.8406 12.9333 12.4216 12.7261C8.70772 10.891 5.00536 9.03523 1.29839 7.18865C1.0267 7.05281 0.817179 6.87782 0.812574 6.55317C0.80797 6.21471 1.01749 6.02591 1.303 5.88315C4.98464 4.04809 8.66628 2.20842 12.3456 0.368751C13.4048 -0.160815 14.4478 -0.100951 15.4931 0.426313C17.8577 1.61669 20.2316 2.79555 22.6008 3.97901C23.8096 4.58226 25.0161 5.18781 26.2226 5.79336C26.2916 5.82789 26.3607 5.86013 26.4321 5.89467C26.7084 6.0236 26.8995 6.22392 26.8995 6.54396C26.8995 6.86861 26.6946 7.0505 26.4229 7.18405C24.7075 8.03826 22.9945 8.89708 21.2792 9.75129C19.2991 10.7391 17.3282 11.7406 15.3365 12.7031C14.8622 12.931 14.3234 13.0323 13.8146 13.1935Z"
                  fill="#F539F8"
                />
                <path
                  d="M12.8602 22.8893C12.8602 24.9477 12.8625 27.0061 12.8579 29.0645C12.8556 29.7966 12.4434 30.0545 11.7688 29.7437C8.39339 28.1895 5.01567 26.64 1.64717 25.0743C0.562705 24.5701 0.0101156 23.6882 0.00551066 22.4955C-0.0036992 18.5721 0.000903485 14.6487 0.00320595 10.7253C0.00320595 9.96783 0.429164 9.68692 1.10609 10.0001C4.59662 11.6095 8.08485 13.2281 11.5777 14.8306C12.4641 15.2382 12.874 15.9013 12.8671 16.8706C12.8533 18.8784 12.8625 20.8838 12.8625 22.8916C12.8625 22.8893 12.8602 22.8893 12.8602 22.8893Z"
                  fill="#F539F8"
                />
                <path
                  d="M14.848 22.8313C14.848 20.8121 14.8526 18.7928 14.8457 16.7736C14.8434 15.8618 15.2602 15.2332 16.0775 14.8556C19.5911 13.2347 23.107 11.6183 26.6205 9.99971C27.2606 9.705 27.7004 9.96978 27.7004 10.6674C27.705 14.63 27.7119 18.5902 27.6958 22.5527C27.6912 23.7661 27.0695 24.6135 25.9758 25.12C23.2451 26.3817 20.5121 27.6412 17.7814 28.9006C17.1965 29.17 16.614 29.4394 16.0315 29.7088C15.2394 30.0726 14.8457 29.8262 14.8457 28.9651C14.8457 26.9205 14.8457 24.8736 14.8457 22.829C14.8457 22.8313 14.8457 22.8313 14.848 22.8313Z"
                  fill="#F539F8"
                />
              </svg>
            </HistoryAnalyticsCard>
          </Link>
          <Link to="/collection">
            <HistoryAnalyticsCard
              data={dashData}
              title="Total Collections"
              numberOfCount={
                dashData?.totalCollection ? dashData?.totalCollection : 0
              }
              numberOfCollectables={
                dashData?.totalCollectibles ? dashData?.totalCollectibles : 0
              }
              numberOfOnChainCollection={
                dashData?.totalOnchainCollection
                  ? dashData?.totalOnchainCollection
                  : 0
              }
              numberOfLazyChainCollection={
                dashData?.totalLazyCollection
                  ? dashData?.totalLazyCollection
                  : 0
              }
              numberOfCreated={
                dashData?.totalCreateCollection
                  ? dashData?.totalCreateCollection
                  : 0
              }
              primaryColor="#FFFCCE"
              iconBg="#FFFCCE"
            >
              <img src={Collection} alt="" width="30" height="30" />
            </HistoryAnalyticsCard>
          </Link>
          <Link to="/listed-nfts">
            <HistoryAnalyticsCard
              data={dashData}
              title="Total Listing"
              numberOfCount={
                dashData?.totalListing ? dashData?.totalListing : 0
              }
              numberOfFixed={
                dashData?.totalFixedListing ? dashData?.totalFixedListing : 0
              }
              numberOfActive={
                dashData?.totalActiveFixedListing
                  ? dashData?.totalActiveFixedListing
                  : 0
              }
              primaryColor="#EB5757"
              iconBg="#E9FCFF"
            >
              <img src={Listing} alt="" width="30" height="30" />
            </HistoryAnalyticsCard>
          </Link>
          <Link to="/auction">
            <HistoryAnalyticsCard
              data={dashData}
              title="Total Auctions"
              numberOfCount={
                dashData?.totalAuctionListing
                  ? dashData?.totalAuctionListing
                  : 0
              }
              numberOfActiveAuction={
                dashData?.totalActiveAuctionListing
                  ? dashData?.totalActiveAuctionListing
                  : 0
              }
              numberOfActiveBid={
                dashData?.totalActiveBid ? dashData?.totalActiveBid : 0
              }
              primaryColor="#5356FB"
              iconBg="rgba(83, 86, 251, 0.16)"
            >
              <img src={Bidding} alt="" width="50" height="50" />
            </HistoryAnalyticsCard>
          </Link>
          <HistoryAnalyticsCard
            data={dashData}
            title="Total Fees"
            numberOfCount={
              dashData?.totalFees[0]?.totalAmount
                ? dashData?.totalFees[0]?.totalAmount.toFixed(5)
                : 0
            }
            numberOfListingFee={
              dashData?.fees[0]?.totalAmount
                ? dashData?.fees[0]?.totalAmount
                : 0
            }
            numberOfBuyFee={
              dashData?.fees[1]?.totalAmount
                ? dashData?.fees[1]?.totalAmount
                : 0
            }
            primaryColor="#FFFCCE"
            iconBg="#FFFCCE"
          >
            <img src={Fees} alt="" width="30" height="30" />
          </HistoryAnalyticsCard>
          <Link to="/admin/userlist">
            <HistoryAnalyticsCard
              data={dashData}
              title="Total Users"
              numberOfCount={dashData?.totalUser ? dashData?.totalUser : 0}
              primaryColor="#27AE60"
              iconBg="#E9F7EF"
            >
              <svg
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.0808 30.1824C10.7113 30.1824 6.3438 30.1824 1.97428 30.1824C0.641347 30.1824 -0.102099 29.364 0.0113425 28.0453C0.163273 26.3032 0.380025 24.5752 0.807456 22.8736C1.18424 21.3725 1.99859 20.3536 3.48345 19.7783C5.65302 18.9355 7.84892 18.182 10.0772 17.5155C10.1805 17.4851 10.3446 17.5358 10.4398 17.6046C11.9105 18.6702 13.3772 19.7398 14.8377 20.8195C15.018 20.9532 15.1355 20.9572 15.3178 20.8215C16.7784 19.7418 18.245 18.6722 19.7157 17.6067C19.8109 17.5378 19.973 17.4831 20.0763 17.5135C22.3694 18.2002 24.6382 18.9558 26.8584 19.8633C28.1306 20.384 28.8862 21.2591 29.2549 22.5596C29.7208 24.2025 29.9477 25.8798 30.1016 27.5733C30.1239 27.8265 30.1523 28.0818 30.1584 28.337C30.1867 29.364 29.421 30.1683 28.3919 30.1804C27.4094 30.1926 26.429 30.1824 25.4465 30.1824C21.9926 30.1824 18.5367 30.1824 15.0808 30.1824Z"
                  fill="#27AE60"
                />
                <path
                  d="M8.44531 7.35622C8.58509 4.27912 9.72152 2.01232 12.3205 0.671285C15.3146 -0.872326 18.969 0.337039 20.6929 3.36754C21.7443 5.21501 21.8962 7.17795 21.4323 9.21382C21.0008 11.1059 20.2108 12.8338 18.967 14.3389C18.3735 15.056 17.6908 15.6739 16.846 16.0811C15.2498 16.8488 13.7629 16.5774 12.4137 15.5118C10.4366 13.952 9.39133 11.8108 8.78563 9.43057C8.59724 8.6851 8.53444 7.90722 8.44531 7.35622Z"
                  fill="#27AE60"
                />
              </svg>
            </HistoryAnalyticsCard>
          </Link>

          <HistoryAnalyticsCard
            data={dashData}
            title="Total Offer"
            numberOfCount={dashData?.totalOffer ? dashData?.totalOffer : 0}
            numberOfAccept={
              dashData?.totalNumberOfAcceptOffer
                ? dashData?.totalNumberOfAcceptOffer
                : 0
            }
            primaryColor="#EB5757"
            iconBg="#FDEEEE"
          >
            <svg
              width="70"
              height="70"
              viewBox="0 0 70 70"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle opacity="0.1" cx="35" cy="35" r="35" fill="#EB5757" />
              <path
                d="M20.8416 32.9067C24.7077 32.9067 28.5465 32.9067 32.4127 32.9067C32.4127 38.4719 32.4127 44.0165 32.4127 49.616C32.2848 49.616 32.1637 49.616 32.0427 49.616C28.8753 49.616 25.7079 49.6183 22.5406 49.616C21.5084 49.616 20.8233 48.94 20.8233 47.9124C20.821 43.03 20.8233 38.1454 20.8233 33.263C20.8256 33.1465 20.8347 33.0346 20.8416 32.9067Z"
                fill="#EB5757"
              />
              <path
                d="M36.8789 49.6137C36.8789 44.0143 36.8789 38.4696 36.8789 32.9067C40.7154 32.9067 44.529 32.9067 48.3906 32.9067C48.3906 33.062 48.3906 33.1968 48.3906 33.3315C48.3906 38.1796 48.3906 43.0277 48.3906 47.8736C48.3906 48.9377 47.7307 49.6137 46.6802 49.6137C43.5243 49.616 40.3706 49.6137 37.2146 49.6137C37.1118 49.6137 37.0091 49.6137 36.8789 49.6137Z"
                fill="#EB5757"
              />
              <path
                d="M19.002 31.2053C19.002 30.6595 19.002 30.1434 19.002 29.6273C19.002 28.4193 18.9975 27.2089 19.002 26.0009C19.0043 25.0692 19.6734 24.359 20.5983 24.3567C24.4781 24.3453 28.358 24.3521 32.2379 24.3521C32.2836 24.3521 32.3292 24.3613 32.4046 24.3704C32.4046 26.6426 32.4046 28.9125 32.4046 31.2053C27.9515 31.2053 23.503 31.2053 19.002 31.2053Z"
                fill="#EB5757"
              />
              <path
                d="M36.875 31.217C36.875 28.9197 36.875 26.6658 36.875 24.373C37.0052 24.3662 37.1262 24.3502 37.2449 24.3502C40.9878 24.3502 44.7306 24.3479 48.4735 24.3525C49.5719 24.3547 50.2228 25.0307 50.2182 26.1337C50.2136 27.6888 50.2182 29.244 50.2159 30.7968C50.2159 30.9316 50.2159 31.0663 50.2159 31.217C45.7491 31.217 41.3303 31.217 36.875 31.217Z"
                fill="#EB5757"
              />
              <path
                d="M34.6091 21.749C35.8994 19.6001 37.6441 17.9947 39.6651 16.6565C40.8594 15.8664 42.0674 15.7385 43.2686 16.588C44.536 17.4855 45.0407 18.7483 44.7712 20.2669C44.5155 21.7033 43.6454 22.6533 42.241 23.0872C42.1017 23.1306 41.9464 23.1306 41.798 23.1306C37.1006 23.1328 32.4032 23.1214 27.7057 23.142C26.8905 23.1443 26.2145 22.8679 25.6391 22.3427C24.5384 21.3356 24.1661 20.0705 24.5475 18.641C24.9152 17.2617 25.824 16.3802 27.2376 16.0605C27.772 15.9394 28.2881 16.0011 28.7859 16.2363C30.1446 16.8826 31.2819 17.8326 32.3529 18.8693C33.2275 19.7188 34.0314 20.63 34.6091 21.749ZM34.0702 22.4158C34.0793 22.3861 34.0907 22.3564 34.0999 22.3267C33.0357 21.2078 31.903 20.171 30.526 19.4334C29.8295 19.0612 29.0805 19.1662 28.4913 19.7028C27.9798 20.1664 27.8062 20.7579 28.0209 21.3197C28.2607 21.9499 28.9161 22.4181 29.5555 22.4181C30.9371 22.4181 32.3187 22.4181 33.7003 22.4181C33.8258 22.4158 33.9492 22.4158 34.0702 22.4158ZM35.1184 22.3633C35.2166 22.3907 35.2577 22.4135 35.3011 22.4135C36.8197 22.4158 38.3383 22.4432 39.8569 22.4089C40.7681 22.3884 41.4988 21.3379 41.2727 20.4884C41.0284 19.5704 39.6559 18.8145 38.8064 19.3991C37.9752 19.97 37.1462 20.5501 36.3515 21.1689C35.9131 21.5069 35.5477 21.9408 35.1184 22.3633Z"
                fill="#EB5757"
              />
            </svg>
          </HistoryAnalyticsCard>
          <Link to="/products">
            <HistoryAnalyticsCard
              title="Total Product"
              numberOfCount={
                dashData?.totalNumberOfProduct
                  ? dashData?.totalNumberOfProduct
                  : 0
              }
              primaryColor="#27AE60"
              iconBg="#E9F7EF"
            >
              <img src={Product} alt="" width="30" height="30" />
            </HistoryAnalyticsCard>
          </Link>
          <HistoryAnalyticsCard
            title="Product Sell USD Amount"
            numberOfCount={Number(
              dashData.productTotalSaleUsdAmount[0]?.usdAmount
                ? dashData.productTotalSaleUsdAmount[0]?.usdAmount
                : 0
            ).toFixed(2)}
            // numberOfAnalyse={324.75}
            productCardValue={
              dashData?.totalNumberOfUsdSaleItem
                ? dashData?.totalNumberOfUsdSaleItem
                : 0
            }
            primaryColor="#27AE60"
            iconBg="#E9F7EF"
            productCard="SaleItem"
          >
            <img src={Dollar} alt="" width="30" height="30" />
          </HistoryAnalyticsCard>

          <HistoryAnalyticsCard
            title="Product Sell ETH Amount"
            numberOfCount={Number(
              dashData.productTotalSaleEthAmount[0]?.ethAmount
                ? dashData.productTotalSaleEthAmount[0]?.ethAmount
                : 0
            ).toFixed(6)}
            // numberOfAnalyse={324.75}
            productCardValue={dashData?.totalNumberOfEthSaleItem}
            productCard="SaleItem"
            primaryColor="#27AE60"
            iconBg="#E9F7EF"
          >
            <img src={Ethereum} alt="" width="30" height="30" />
          </HistoryAnalyticsCard>
          <Link to="/orders">
            <HistoryAnalyticsCard
              title="Order Processing"
              numberOfCount={
                dashData?.totalNumberOfIncompleteOrder
                  ? dashData?.totalNumberOfIncompleteOrder
                  : 0
              }
              productCardValue={
                dashData?.totalNumberOfEthSaleItem &&
                dashData?.totalNumberOfUsdSaleItem
                  ? dashData?.totalNumberOfEthSaleItem +
                    dashData?.totalNumberOfUsdSaleItem
                  : 0
              }
              productCard="CompletedOrder"
              primaryColor="#27AE60"
              iconBg="#E9F7EF"
            >
              <img src={Bidding} alt="" width="50" height="50" />
            </HistoryAnalyticsCard>
          </Link>
        </div>
      )}
    </>
  );
}
