/* eslint-disable no-underscore-dangle */
import { GridColDef } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import notfOund from "../../assets/images/not-found.svg";
import DataTable from "../../components/Helpers/DataGrid";
import LottieLoader from "../../components/Helpers/LottieLoader";
import Layout from "../../components/Partials/Layout";
import useRequestFun from "../../hooks/Axios/useRequestFun";

export default function Orders() {
  const [isLoader, setIsLoader] = useState(true);
  const [orderList, setOrderList] = useState();
  const navigate = useNavigate();
  // const [balance, setBalance] = useState("0");
  const { getRequest } = useRequestFun();
  const getRequests = useCallback(() => {
    getRequest("getallorders").then((res) => {
      if (res?.data?.data) {
        setOrderList(res?.data?.data);
        setIsLoader(false);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getRequests();
  }, [getRequests]);
  const columns: GridColDef[] = [
    {
      minWidth: 150,
      field: "_id",
      headerName: "Order Id",
      flex: 1,
      valueGetter: (params) => params.row._id,
      renderCell: (params) => (
        <Link
          to={`/orders/${params.row._id}`}
          rel="noreferrer"
          className="hover:text-blue-400"
        >
          {`#${params.row._id}`}
        </Link>
      ),
    },
    {
      minWidth: 150,
      field: "itemName",
      headerName: "Name",
      flex: 1,
      valueGetter: (params) =>
        params.row.itemName ? `${params.row.itemName}` : "N/A",
      renderCell: (params) => (
        <Link
          to={`/orders/${params.row._id}`}
          rel="noreferrer"
          className="hover:text-blue-400"
        >
          {params.row.itemName}
        </Link>
      ),
    },
    {
      minWidth: 150,
      field: "payment_gateway",
      headerName: "Payment Gateway",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.payment_gateway
          ? `${params.row.payment_gateway.toUpperCase()}`
          : "N/A",
    },
    {
      minWidth: 150,
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.status ? (
          <div className="flex justify-center items-center gap-4">
            {params.row.status === "Place Order" ? (
              <button
                type="submit"
                className="px-5 h-[46px] flex justify-center items-center bg-[#D4DCFF] text-base rounded-full text-[#163BDE]"
              >
                Placed Order
              </button>
            ) : params.row.status === "Approved" ? (
              <button
                type="submit"
                className="px-5 h-[46px] flex justify-center items-center bg-[#FAD6E6] text-base rounded-full text-[#C31162]"
              >
                Approved
              </button>
            ) : params.row.status === "Processing" ? (
              <button
                type="submit"
                className="px-5 h-[46px] flex justify-center items-center bg-[#E9DCFE] text-base rounded-full text-[#2C0270]"
              >
                Processing
              </button>
            ) : params.row.status === "Shipped" ? (
              <button
                type="submit"
                className="px-5 h-[46px] flex justify-center items-center bg-[#D2FFD4] text-base rounded-full text-[#0D9F16]"
              >
                Shipped
              </button>
            ) : params.row.status === "Delivered" ? (
              <button
                type="submit"
                className="px-5 h-[46px] flex justify-center items-center bg-[#D2FFD4] text-base rounded-full text-[#0D9F16]"
              >
                Delivered
              </button>
            ) : (
              params.row.status === "Cancelled" && (
                <button
                  type="button"
                  className="px-5 h-[46px] flex justify-center items-center text-base rounded-full text-[#F82814] bg-[#FFD4D0]"
                >
                  Cancelled
                </button>
              )
            )}
          </div>
        ) : (
          "N/A"
        ),
    },
    {
      minWidth: 150,
      field: "currency_type",
      headerName: "Currency Type",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.currency_type
          ? `${params.row.currency_type.toUpperCase()}`
          : "N/A",
    },
    {
      minWidth: 150,
      field: "thumbImage",
      headerName: "Thumbnail",
      flex: 1,
      renderCell: (params) =>
        params?.row?.thumbImage !== "" ? (
          <img
            src={params?.row?.thumbImage}
            alt="Blogs"
            width={60}
            height={60}
            loading="eager"
          />
        ) : (
          <img src={notfOund} alt="Collection" width={60} />
        ),
    },
    {
      minWidth: 150,
      field: "amount",
      headerName: "Amount",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.amount ? `${params.row.amount.toFixed(6)}` : "N/A",
    },
    {
      minWidth: 150,
      field: "createdAt",
      headerName: "Placed Order Date",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.createdAt ? `${new Date(params.row.createdAt)}` : "N/A",
    },
    {
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const edit = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          navigate(`/orders/${params.row._id}`, {
            state: { id: params.row._id },
          });
        };

        return (
          <>
            <button
              type="button"
              className="text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
              onClick={edit}
            >
              <AiOutlineEdit className="text-xl" />
            </button>
          </>
        );
      },
    },
  ];
  return (
    <Layout>
      {isLoader ? (
        <LottieLoader />
      ) : (
        <div
          className={`tableForUpdate w-full p-8 bg-white overflow-hidden rounded-2xl shadowSection relative min-h-[520px] `}
        >
          <div className="header w-full sm:flex justify-between items-center mb-5">
            <div className="flex space-x-2 items-center mb-2 sm:mb-0">
              <h1 className="text-xl font-bold text-dark-gray tracking-wide">
                Order List
              </h1>
            </div>
          </div>
          <div className="relative w-full overflow-x-auto sm:rounded-lg h-[750px]">
            {orderList && <DataTable rowList={orderList} columns={columns} />}
          </div>
        </div>
      )}
    </Layout>
  );
}
