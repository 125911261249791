/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-underscore-dangle */
import { GridColDef } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import DataTable from "../Helpers/DataGrid";
import LottieLoader from "../Helpers/LottieLoader";

export default function UserList() {
  const [isLoader, setIsLoader] = useState(true);
  const [userlist, setUserList] = useState();
  const [networkData, setNetworkData] = useState();
  const { getRequest } = useRequestFun();
  const getRequests = useCallback(() => {
    getRequest("users").then((res) => {
      setUserList(res.data.data);
    });
    getRequest(`getNetWorks`).then(async (res) => {
      if (res.data.data[0]) {
        setNetworkData(res.data.data[0]);
      }
      setIsLoader(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRequests();
  }, [getRequests]);

  const columns: GridColDef[] = [
    {
      minWidth: 150,
      field: "fullName",
      headerName: "Full Name",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.first_name
          ? `${params.row.first_name} ${params.row.last_name}`
          : "N/A",
    },
    {
      minWidth: 150,
      field: "email",
      headerName: "Email",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.email ? params.row.email : "N/A",
    },
    {
      minWidth: 150,
      field: "wallet_address",
      headerName: "Wallet",
      flex: 1,
      valueGetter: (params) => `${params.row.wallet_address}`,
      renderCell: (params) => (
        <a
          href={`${networkData?.explorer_url}address/${params.row.wallet_address}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {params.row.wallet_address}
        </a>
      ),
    },
    {
      minWidth: 150,
      field: "status",
      headerName: "Status",
      flex: 1,
      valueGetter: (params) => `${params.row.status}`,
      renderCell: (params) =>
        params.row.status === "active" ? (
          <div className="bg-[#D2FFD4] text-[#0D9F16] rounded-md py-1 px-3 uppercase">
            {params.row.status}
          </div>
        ) : params.row.status === "inactive" ? (
          <div className="bg-[#E9DCFE] text-[#2C0270] rounded-md py-1 px-3 uppercase">
            {params.row.status}
          </div>
        ) : (
          params.row.status === "suspend" && (
            <div className="bg-[#FFD4D0] text-[#FF1600] rounded-md py-1 px-3 uppercase">
              {params.row.status}
            </div>
          )
        ),
    },
  ];
  return (
    <>
      {isLoader ? (
        <LottieLoader />
      ) : (
        <div
          className={`tableForUpdate w-full p-8 bg-white overflow-hidden rounded-2xl shadowSection relative min-h-[520px] `}
        >
          <div className="header w-full sm:flex justify-between items-center mb-5">
            <div className="flex space-x-2 items-center mb-2 sm:mb-0">
              <h1 className="text-xl font-bold text-dark-gray tracking-wide">
                Admin User List
              </h1>
            </div>
          </div>
          <div className="relative w-full overflow-x-auto sm:rounded-lg h-[700px]">
            {userlist && <DataTable rowList={userlist} columns={columns} />}
          </div>
        </div>
      )}
    </>
  );
}
