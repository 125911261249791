/* eslint-disable no-underscore-dangle */
import { GridColDef } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import { MdDoneOutline } from "react-icons/md";
import { TbListDetails } from "react-icons/tb";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import Layout from "../Partials/Layout";

import DataTable from "../Helpers/DataGrid";
import LottieLoader from "../Helpers/LottieLoader";
import ConfirmModal from "../modal/ConfirmModal";

export default function Support() {
  const [isLoader, setIsLoader] = useState(true);
  const [list, setList] = useState();
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [updateStatusId, setUpdateStatusId] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const { getRequest, putRequest } = useRequestFun();

  const getRequests = useCallback(() => {
    getRequest("getallsupport").then((res) => {
      if (res?.data?.data) {
        setList(res?.data?.data);
        setIsLoader(false);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate]);

  useEffect(() => {
    getRequests();
  }, [getRequests]);

  const confirmHandler = async () => {
    putRequest("updatestatus", { id: updateStatusId, status: "solved" })
      .then(() => {
        setIsConfirmModal(!isConfirmModal);
        setIsUpdate(!isUpdate);
        toast.success("Updated successfully!");
      })
      .catch((error) => {
        setIsConfirmModal(!isConfirmModal);
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message
        );
      });
  };

  const columns: GridColDef[] = [
    {
      minWidth: 150,
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        // eslint-disable-next-line no-unused-expressions
        <Link
          to={`/support/${params.row._id}`}
          rel="noreferrer"
          className="hover:text-blue-400"
        >
          {params.row.name ? `${params.row.name}` : "N/A"}
        </Link>
      ),
    },

    {
      minWidth: 150,
      field: "email",
      headerName: "Email",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.email ? `${params.row.email}` : "N/A",
    },
    {
      minWidth: 150,
      field: "phone",
      headerName: "Mobile Number",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.phone ? `${params.row.phone}` : "N/A",
    },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.created_at
          ? `${new Date(params.row.created_at).toLocaleString()}`
          : "N/A",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      renderCell: (params) =>
        params.row.status === "active" ? (
          <div className="bg-[#D2FFD4] text-[#0D9F16] rounded-md py-1 px-3 uppercase">
            {params.row.status}
          </div>
        ) : params.row.status === "inactive" ? (
          <div className="bg-[#FFD4D0] text-[#FF1600] rounded-md py-1 px-3 uppercase">
            {params.row.status}
          </div>
        ) : params.row.status === "cancel" ? (
          <div className="bg-[#FFD4D0] text-[#FF1600] rounded-md py-1 px-3 uppercase">
            {params.row.status}
          </div>
        ) : params.row.status === "pending" ? (
          <div className="bg-[#E9DCFE] text-[#2C0270] rounded-md py-1 px-3 uppercase">
            {params.row.status}
          </div>
        ) : (
          params.row.status === "solved" && (
            <div className="bg-[#34aadc] text-[#000] rounded-md py-1 px-3 uppercase">
              {params.row.status}
            </div>
          )
        ),
    },
    {
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const updateStatus = () => {
          setUpdateStatusId(params.id);
          setIsConfirmModal(!isConfirmModal);
        };

        return (
          <>
            <>
              <div className="flex gap-1">
                <>
                  <button
                    title="Update Status"
                    type="button"
                    className="text-sm text-white bg-emerald-400 w-10 h-10 flex justify-center items-center rounded-full"
                    onClick={() => updateStatus()}
                  >
                    <MdDoneOutline className="text-xl" />
                  </button>
                  <Link
                    title="Set Header Feature"
                    className="text-sm text-white bg-teal-700 w-10 h-10 flex justify-center items-center rounded-full"
                    to={`/support/${params.id}`}
                  >
                    <TbListDetails className="text-xl" />
                  </Link>
                </>
              </div>
            </>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Layout>
        {isLoader ? (
          <LottieLoader />
        ) : (
          <div
            className={`tableForUpdate w-full p-8 bg-white overflow-hidden rounded-2xl shadowSection relative min-h-[520px] `}
          >
            <div className="header w-full sm:flex justify-between items-center mb-5">
              <div className="flex space-x-2 items-center mb-2 sm:mb-0">
                <h1 className="text-xl font-bold text-dark-gray tracking-wide">
                  Support List
                </h1>
              </div>
            </div>
            <div className="relative w-full overflow-x-auto sm:rounded-lg h-[800px]">
              {list && <DataTable rowList={list} columns={columns} />}
            </div>
          </div>
        )}
      </Layout>
      {isConfirmModal && (
        <ConfirmModal
          headMessage="Confirm"
          bodyMessage={`Are you sure solved this support ? `}
          btn1="Confirm"
          btn2="Not Now"
          isMoadl={() => setIsConfirmModal(!isConfirmModal)}
          actionCofirm={() => confirmHandler()}
        ></ConfirmModal>
      )}
    </>
  );
}
