import React from "react";

export default function UserAndMarketChartSkeleton() {
  return (
    <div className="flex gap-6">
      <div className="first bg-white p-5 rounded-xl w-1/2">
        <div className="flex justify-between">
          <div className="w-[70px] h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
          <div className="w-[70px] h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
        </div>
        <div className="flex flex-col gap-8 mt-5">
          <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
          <div className="w-full h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
          <div className="w-full h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
          <div className="w-full h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
          <div className="w-full h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
        </div>
      </div>
      <div className="first bg-white p-5 rounded-xl w-1/2">
        <div className="flex justify-between">
          <div className="w-[70px] h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
          <div className="w-[70px] h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
        </div>
        <div className="flex flex-col gap-8 mt-5">
          <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
          <div className="w-full h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
          <div className="w-full h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
          <div className="w-full h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
          <div className="w-full h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
        </div>
      </div>
    </div>
  );
}
