/* eslint-disable no-unreachable */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DefaultImg from "../../assets/images/auth-profile-picture.png";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import Layout from "../Partials/Layout";
import ConfirmModal from "../modal/ConfirmModal";

export default function DiscussionDetails() {
  const { getRequest, putRequest } = useRequestFun();
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [deleteCommentId, setDeleteCommentId] = useState();
  const [updateThis, setUpdateThis] = useState(false);
  const [deleteThisQuestion, setDeleteThisQuestion] = useState(false);

  const [discussion, setDiscussion] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // if (!location.state.id) {
    //   return;
    // }

    getRequest(`getAForum/${id}`).then((res) => {
      setDiscussion(res.data.data[0]);
    });
  }, [updateThis]);

  const deleteComment = (commentId) => {
    setDeleteCommentId(commentId);
    setIsConfirmModal(!isConfirmModal);
  };
  const deleteQuestion = () => {
    setDeleteThisQuestion(true);
    setIsConfirmModal(!isConfirmModal);
  };

  const handleConfirm = async () => {
    return toast.warning("Disabled For Demo Version!");
    if (deleteThisQuestion) {
      putRequest("deleteQuestion", {
        id,
      }).then((res) => {
        setUpdateThis(!updateThis);
        if (res.status === 200) {
          toast.success("Deleted success!");
          setIsConfirmModal(!isConfirmModal);
          navigate("/discussion-list");
        } else {
          toast.warning(res.message);
          setIsConfirmModal(!isConfirmModal);
        }
      });
    } else {
      return toast.warning("Disabled For Demo Version!");
      putRequest("deleteComment", {
        id,
        commentId: deleteCommentId,
      }).then((res) => {
        setUpdateThis(!updateThis);
        if (res.status === 200) {
          toast.success("Deleted success!");
          setIsConfirmModal(!isConfirmModal);
        } else {
          toast.warning(res.message);
          setIsConfirmModal(!isConfirmModal);
        }
      });
    }
  };

  return (
    <>
      <Layout>
        <div
          className={`tableForUpdate w-full p-8 bg-white overflow-hidden rounded-2xl shadowSection relative min-h-[520px] `}
        >
          <div className="header w-full sm:flex justify-between items-center mb-5">
            <div className="flex space-x-2 items-center mb-2 sm:mb-0">
              <h1 className="text-xl font-bold text-dark-gray tracking-wide">
                Discussion
              </h1>
            </div>
          </div>
          <div className="wrapper px-[14px] lg:px-[32px]  pt-14 m-auto pb-8">
            <div className="w-full p-5 bg-white rounded-xl shadow-2xl relative">
              <div className="flex justify-between items-center">
                <div className="flex gap-2 items-center">
                  <div className=" w-10 h-10">
                    {discussion?.userInfo[0]?.profile_image ? (
                      <img
                        src={discussion?.userInfo[0]?.profile_image}
                        alt=""
                        className="object-contain"
                      />
                    ) : (
                      <img src={DefaultImg} alt="" className="object-contain" />
                    )}
                  </div>

                  <a
                    target="_blank"
                    href={`${process.env.REACT_APP_FRONTEND_URL}/wallet/${discussion?.userInfo[0]?.wallet_address}`}
                    rel="noreferrer"
                  >
                    <p className="text-base text-textLight lineHeight150">
                      {discussion?.userInfo[0]?.first_name
                        ? `${discussion?.userInfo[0]?.first_name} ${discussion?.userInfo[0]?.last_name}`
                        : `${discussion?.userInfo[0]?.wallet_address.substring(
                            0,
                            3
                          )}..${discussion?.userInfo[0]?.wallet_address.slice(
                            -4
                          )}`}
                    </p>
                  </a>
                </div>
                {/* <p className="text-[#B3B4C5]">Asked:adad</p> */}
                <p className="text-[#B3B4C5]">
                  Asked:{" "}
                  {new Date(discussion?.createdAt).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })}
                </p>
              </div>

              <h3 className="text-base font-semibold lineHeight150 mt-2">
                {discussion?.question?.title}
              </h3>

              <p
                className="text-base font-medium lineHeight150 text-[#67698A] mt-2"
                dangerouslySetInnerHTML={{
                  __html: discussion?.question?.description,
                }}
              ></p>
              <div className="absolute bottom-4 right-4">
                <Button
                  onClick={() => {
                    deleteQuestion();
                  }}
                >
                  <AiFillDelete className="text-red-400 text-2xl" />
                </Button>
              </div>
            </div>

            <div className="mt-4">
              <p className=" text-textLight font-medium text-base lineHeight150 mt-2 mb-2">
                {discussion?.comments?.length > 0
                  ? discussion?.comments?.length
                  : 0}{" "}
                Comments
              </p>
              <div className="flex flex-col-reverse gap-4">
                {discussion?.comments?.length > 0 ? (
                  discussion?.comments.map((comment) => (
                    <div className="w-full p-5 bg-white rounded-xl shadow relative">
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="relative w-10 h-10">
                            {comment?.commenter[0]?.profile_image ? (
                              <img
                                src={comment?.commenter[0]?.profile_image}
                                alt=""
                                className="object-contain"
                              />
                            ) : (
                              <img
                                src={DefaultImg}
                                alt=""
                                className="object-contain"
                              />
                            )}
                          </div>

                          <a
                            target="_blank"
                            href={`${process.env.REACT_APP_FRONTEND_URL}/wallet/${comment?.commenter[0]?.wallet_address}`}
                            rel="noreferrer"
                          >
                            <p className="text-base text-textLight lineHeight150">
                              {comment?.commenter[0]?.first_name
                                ? `${comment?.commenter[0]?.first_name} ${comment?.commenter[0]?.last_name}`
                                : `${comment?.commenter[0]?.wallet_address.substring(
                                    0,
                                    3
                                  )}..${comment?.commenter[0]?.wallet_address.slice(
                                    -4
                                  )}`}
                            </p>
                          </a>
                        </div>
                        {/* <p className="text-[#B3B4C5]">Asked:adad</p> */}
                        <p className="text-[#B3B4C5]">
                          {new Date(comment?.commentAt).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            }
                          )}
                        </p>
                      </div>

                      {/* <h3 className="text-base font-semibold lineHeight150 mt-2">asdasdasd</h3> */}
                      <p
                        className="text-base font-medium lineHeight150 text-[#67698A] mt-2"
                        dangerouslySetInnerHTML={{ __html: comment?.com }}
                      >
                        {/* {comment?.com} */}
                      </p>
                      <div className="absolute bottom-4 right-4">
                        <Button
                          onClick={() => {
                            deleteComment(comment?.id);
                          }}
                        >
                          <AiFillDelete className="text-red-400 text-2xl" />
                        </Button>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Data not found</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
      {isConfirmModal && (
        <ConfirmModal
          headMessage="Confirm"
          bodyMessage={`Are you sure to delete the ${
            deleteThisQuestion ? `question` : `comment`
          } ?`}
          btn1="Confirm"
          btn2="Not Now"
          isMoadl={() => setIsConfirmModal(!isConfirmModal)}
          actionCofirm={() => handleConfirm()}
        ></ConfirmModal>
      )}
    </>
  );
}
