import React from "react";
import Lottie from "react-lottie";
import * as Loader from "../../../assets/lottie/loader.json";

export default function index() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="w-full h-screen flex items-center justify-center relative ">
      <div className="absolute w-full h-screen opacity-80 z-10"></div>
      <div className="w-2/12 z-20">
        <Lottie options={defaultOptions} className="object-cover " />
      </div>
    </div>
  );
}
