import { GridColDef } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import Layout from "../Partials/Layout";

import DataTable from "../Helpers/DataGrid";
import LottieLoader from "../Helpers/LottieLoader";
// import { logDOM } from "@testing-library/react";

export default function Earnings() {
  const [isLoader, setIsLoader] = useState(true);
  const [earninglist, setEarningList] = useState([]);
  const [networkData, setNetworkData] = useState();
  // const [balance, setBalance] = useState("0");
  const { getRequest } = useRequestFun();
  const getRequests = useCallback(() => {
    getRequest(`getNetWorks`).then(async (response) => {
      if (response.data.data[0]) {
        setNetworkData(response.data.data[0]);

        getRequest("getearnings").then((res) => {
          if (res?.data?.data) {
            setEarningList(res?.data?.data);
          }
        });
      }
      setIsLoader(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRequests();
  }, [getRequests]);

  const truncate = (str) =>
    // eslint-disable-next-line prefer-template
    str.length > 10 ? str.substring(0, 3) + "..." + str.slice(-3) : str;

  const columns: GridColDef[] = [
    {
      minWidth: 150,
      field: "txn_type",
      headerName: "Transaction Type",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.txn_type ? `${params.row.txn_type}` : "N/A",
    },

    {
      minWidth: 150,
      field: "contract_address",
      headerName: "Contract Address",
      flex: 1,
      renderCell: (params) => (
        <a
          href={`${networkData.explorer_url}address/${params.row.contract_address}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {truncate(params.row.contract_address)}
        </a>
      ),
    },
    {
      minWidth: 150,
      field: "token_id",
      headerName: "Token Id",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.token_id ? `${params.row.token_id}` : "N/A",
    },
    {
      minWidth: 150,
      field: "wallet_address",
      headerName: "From Address",
      flex: 1,
      renderCell: (params) => (
        <a
          href={`${networkData.explorer_url}address/${params?.row?.wallet_address}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {truncate(params?.row?.wallet_address)}
        </a>
      ),
    },
    {
      minWidth: 150,
      field: "amount",
      headerName: "Amount",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.amount
          ? `${Number(params.row.amount).toFixed(6)} ${networkData.symbol}`
          : `0.00 ${networkData.symbol}`,
    },
  ];

  return (
    <>
      <Layout>
        {isLoader ? (
          <LottieLoader />
        ) : (
          <div
            className={`tableForUpdate w-full p-8 bg-white overflow-hidden rounded-2xl shadowSection relative min-h-[520px] `}
          >
            <div className="header w-full sm:flex justify-between items-center mb-5">
              <div className="flex space-x-2 items-center mb-2 sm:mb-0">
                <h1 className="text-xl font-bold text-dark-gray tracking-wide">
                  Earning List
                </h1>
              </div>
            </div>
            <div className="relative w-full overflow-x-auto sm:rounded-lg h-[800px]">
              {earninglist && (
                <DataTable rowList={earninglist} columns={columns} />
              )}
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}
