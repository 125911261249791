/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../components/Partials/Layout";
// import useFileUpload from "../../hooks/useFileUpload";
import LottieLoader from "../../components/Helpers/LottieLoader";
import SubscriptionUpdateForm from "../../components/Subscription/SubscriptionUpdateForm";
import useRequestFun from "../../hooks/Axios/useRequestFun";

export default function UpdateSubscription() {
  const [isLoader, setIsLoader] = useState(true);
  const [name, setName] = useState(false);
  const [description, setDescription] = useState(false);
  const [price, setPrice] = useState(false);
  const [expiry, setExpiry] = useState(false);

  const location = useLocation();
  const { getRequest } = useRequestFun();

  useEffect(() => {
    getRequest(`getAPlan/${location.state.id}`).then((res) => {
      setName(res.data.data.name);
      setDescription(res.data.data?.description);
      setPrice(res.data.data?.price);
      setExpiry(res.data.data?.expiry);
      setIsLoader(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      {isLoader ? (
        <LottieLoader />
      ) : (
        <div className="settings-wrapper w-full relative mb-10">
          <div className="mainWrapper w-full">
            {/* heading */}
            <SubscriptionUpdateForm
              // addCategory={(e) => addCategory(e)}
              PlanName={name}
              PlanDescription={description}
              PlanPrice={price}
              expiry={expiry}
              planId={location.state.id}
              // metaDescription={metaDescription}
              // setName={(e) => setName(e.target.value)}
              // setDescription={(obj) => setDescription((prev) => [...prev, obj])}
              // setMetaDescription={(e) => setMetaDescription(e.target.value)}
              // errorMessage={errorMessage}
              // status={status}
              // setCateStatus={(value, id) => setCateStatus(value, id)}
              // cateStatus={cateStatus}
              // setImage={(img) => setLogo(img)}
              // mimeType={(mType) => setLogoMimeType(mType)}
              setIsLoader={(mType) => setIsLoader(mType)}
              pageTitle="Update Plan"
              cancelUrl="/subscription/list"
              categoryType="nftCategory"
            />
          </div>
        </div>
      )}
    </Layout>
  );
}
