import React from "react";

export default function TableSkeleton() {
  return (
    <div className="flex gap-6">
      <div className="bg-white p-5 rounded-xl w-full">
        {/* <div className="flex justify-between">
          <div className="w-[70px] h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
          <div className="w-[70px] h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
        </div> */}
        <table className="w-full">
          <tbody>
            <tr>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
            </tr>
            <tr>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
            </tr>
            <tr>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
            </tr>
            <tr>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
            </tr>
            <tr>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
            </tr>
            <tr>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
              <td className="p-5">
                <div className="w-1/2 h-[20px] flex justify-center items-center rounded-full animate-skeleton"></div>
              </td>
            </tr>
          </tbody>
        </table>
        {/* <div className="grid grid-cols-4 gap-8 mt-5">
          <div className="w-full h-[200px] flex justify-center items-center rounded-lg animate-skeleton"></div>
          <div className="w-full h-[200px] flex justify-center items-center rounded-lg animate-skeleton"></div>
          <div className="w-full h-[200px] flex justify-center items-center rounded-lg animate-skeleton"></div>
          <div className="w-full h-[200px] flex justify-center items-center rounded-lg animate-skeleton"></div>
        </div> */}
      </div>
    </div>
  );
}
