/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import useRequestFun from "../../hooks/Axios/useRequestFun";
import InputCom from "../Helpers/Inputs";

export default function SubscriptionServiceUpdateForm({
  //   addCategory,
  //   setName,
  //   setDescription,
  //   setMetaDescription,
  serviceName,
  serviceLabel,
  serviceId,
  //   metaDescription,
  //   errorMessage,
  //   status,
  //   setSubscriptionExpire,
  //   subscriptionExpire,
  //   setImage,
  //   viewImage,
  //   mimeType,
  setIsLoader,
  pageTitle = "Add Subscription",
  cancelUrl,
  categoryType,
}) {
  const [profileImg, setProfileImg] = useState();
  const [name, setName] = useState(serviceName);
  const [label, setLabel] = useState(serviceLabel);
  const [errorMessage, setErrorMessage] = useState({
    name: "",
    label: "",
  });
  const navigate = useNavigate();
  const { putRequest } = useRequestFun();
  // const [status] = useState([
  //   {
  //     _id: 1,
  //     name: "Daily",
  //     value: "daily",
  //   },
  //   {
  //     _id: 2,
  //     name: "Monthly",
  //     value: "monthly",
  //   },
  //   {
  //     _id: 3,
  //     name: "Yearly",
  //     value: "yearly",
  //   },
  // ]);
  // const [titleStatus, setTitleStatus] = useState([
  //   {
  //     _id: 1,
  //     name: "Active",
  //     value: true,
  //   },
  //   {
  //     _id: 2,
  //     name: "Inactive",
  //     value: false,
  //   },
  // ]);
  const updateSubscription = async (e) => {
    e.preventDefault();

    let isInvalid = false;
    if (label === "") {
      setErrorMessage({
        ...errorMessage,
        label: "Subscription Name is Required",
      });
      isInvalid = true;
    } else if (name === "") {
      setErrorMessage({
        ...errorMessage,
        name: "Subscription Price is Required",
      });
      isInvalid = true;
    } else {
      setErrorMessage({
        ...errorMessage,
        label: "",
        name: "",
      });
    }
    if (isInvalid) {
      return;
    }
    setIsLoader(true);

    // const statusLabel = status.find(
    //   (item) => item._id === subscriptionServiceStatus.id
    // );
    putRequest(`updateservice/${serviceId}`, {
      label,
      name,
      // status: statusLabel,
    })
      .then((res) => {
        toast.success(res.data.message);
        setIsLoader(false);
        navigate("/subscription/service/list");
      })
      .catch((error) => {
        console.log(error);
        toast.warning(error.response.data.message);
        setIsLoader(false);
      });
  };

  const [messages, setMessages] = useState({});
  const desArray = {
    title: "",
    status: true,
  };
  // const addDescription = () => {
  //   setDescription((prev) => [...prev, desArray]);
  // };
  // const deleteDescription = (index) => {
  //   if (description.length > 1) {
  //     const des = [...description];
  //     des.splice(index, 1);

  //     setDescription(des);
  //   }
  // };
  // const handleTypeInput = (typeName, value, index) => {
  //   const obj = [...description];
  //   obj[index][typeName] = value;
  //   setDescription(obj);
  // };
  return (
    <form onSubmit={updateSubscription}>
      <div className="content-container w-full rounded-2xl bg-white">
        <div className="content-body-items flex-1 p-10">
          <div className="heading w-full mb-6">
            <h1 className="text-26 font-bold text-dark-gray antialiased">
              {pageTitle}
            </h1>
          </div>
          <div className="personal-info-tab w-full flex flex-col justify-between">
            <div className="flex flex-col-reverse sm:flex-row">
              <div className="flex-1 sm:mr-10">
                <div className="fields w-full">
                  {/* first name and last name */}
                  <div className="xl:flex xl:space-x-7 mb-6">
                    <div className="field w-full mb-6 xl:mb-0">
                      <InputCom
                        label="Name"
                        type="text"
                        name="name"
                        inputHandler={(e) => setName(e.target.value)}
                        value={name}
                        placeholder="Name"
                        error={!!errorMessage.name}
                      />

                      <small className="text-red-400 ml-6 italic font-bold">
                        {errorMessage.name}
                      </small>
                    </div>
                    <div className="field w-full mb-6 xl:mb-0">
                      <InputCom
                        label="Label"
                        type="text"
                        name="label"
                        inputHandler={(e) => setLabel(e.target.value)}
                        value={label}
                        placeholder="Label"
                        error={!!errorMessage.label}
                      />

                      <small className="text-red-400 ml-6 italic font-bold">
                        {errorMessage.label}
                      </small>
                    </div>
                  </div>
                  {/* <div className="xl:flex xl:space-x-7 mb-6">
                    <div className="field w-full mb-6 xl:mb-0">
                      <div className="chart-heading mb-4 xl:flex justify-between items-center">
                        <SecondSelect
                          label="Expiry"
                          name="Status"
                          datas={status}
                          action={(value, id) =>
                            setSubscriptionExpire({ value, id })
                          }
                          selectedValue={subscriptionExpire.value}
                          error={!!errorMessage.status}
                        />
                      </div>
                      <small className="text-red-400 ml-6 italic font-bold">
                        {errorMessage.status}
                      </small>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="content-footer w-full">
              <div className="w-full h-[120px] border-t border-light-purple flex justify-end items-center">
                <div className="flex space-x-4 mr-9">
                  <Link to={cancelUrl}>
                    <button
                      type="button"
                      className="text-18 text-light-red tracking-wide "
                    >
                      <span className="border-b border-light-red">Cancel</span>
                    </button>
                  </Link>

                  <button
                    type="submit"
                    className="w-[152px] h-[46px] flex justify-center items-center gradientButton text-base rounded-full text-white"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
