/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import titleShape from "../../../assets/images/shape/title-shape-two.svg";
import { setForget } from "../../../store/user/forgetSlice";
import InputCom from "../../Helpers/Inputs";
import AuthLayout from "../AuthLayout";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forgotPassword = () => {
    return toast.warning("Disabled For Demo Version!");
    if (email !== "") {
      if (
        !email.match(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
        )
      ) {
        toast.warning("Email Address Not Valid");
        return;
      }
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/forgotpassword`, {
          email,
        })
        .then(() => {
          dispatch(setForget(email));
          toast.success("Verification Code Send Successfully");
          navigate("/verify-you", { replace: true });
          // dispatch(setUser(res.data));
          // setLoginLoading(true);
          // setTimeout(() => {
          //   toast.success("Login Successfully");
          //   navigate("/", { replace: true });
          //   setLoginLoading(false);
          // }, 0);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    } else {
      toast.warning("Email is Required");
    }
  };
  return (
    <>
      <AuthLayout
        slogan="Welcome to  Nftmax
Admin Panel"
      >
        <div className="wrapperContent xl:bg-white xl:px-[70px] w-full sm:w-auto   2xl:px-[100px] h-[818px] rounded-xl ">
          <div className="flex flex-col justify-center w-full h-full px-5">
            <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
              <h1 className="sm:text-5xl text-4xl font-bold sm:leading-[74px] text-dark-gray">
                Forget Password
              </h1>
              <div className="shape sm:w-[377px] w-[270px] -mt-1 ml-5">
                <img src={titleShape} alt="shape" />
              </div>
            </div>
            <div className="input-area">
              <div className="input-item mb-5">
                <InputCom
                  value={email}
                  inputHandler={(e) => setEmail(e.target.value)}
                  placeholder="example@quomodosoft.com"
                  label="Email Address"
                  name="email"
                  type="email"
                  iconName="message"
                />
              </div>
              <div className="signin-area mb-3.5">
                <button
                  onClick={forgotPassword}
                  type="button"
                  className="w-full rounded-[50px] mb-5 h-[58px] text-xl text-white font-bold flex justify-center bg-purple items-center"
                >
                  Send Code
                </button>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
