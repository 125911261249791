import { GridColDef } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import Layout from "../Partials/Layout";

import DataTable from "../Helpers/DataGrid";
import LottieLoader from "../Helpers/LottieLoader";
// import { logDOM } from "@testing-library/react";

export default function Subscribers() {
  const [isLoader, setIsLoader] = useState(true);
  const [earninglist, setEarningList] = useState([]);
  // const [networkData, setNetworkData] = useState();
  // const [balance, setBalance] = useState("0");
  const { getRequest } = useRequestFun();
  const getRequests = useCallback(() => {
    getRequest("getsubscribers").then((res) => {
      if (res?.data?.data) {
        setEarningList(res?.data?.data);
      }
      setIsLoader(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRequests();
  }, [getRequests]);

  const columns: GridColDef[] = [
    {
      minWidth: 150,
      field: "email",
      headerName: "Subscriber Email",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.email ? `${params.row.email}` : "N/A",
    },
    {
      minWidth: 150,
      field: "createdAt",
      headerName: "Subscribe At",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.createdAt ? `${new Date(params.row.createdAt)}` : "N/A",
    },
  ];

  return (
    <>
      <Layout>
        {isLoader ? (
          <LottieLoader />
        ) : (
          <div
            className={`tableForUpdate w-full p-8 bg-white overflow-hidden rounded-2xl shadowSection relative min-h-[520px] `}
          >
            <div className="header w-full sm:flex justify-between items-center mb-5">
              <div className="flex space-x-2 items-center mb-2 sm:mb-0">
                <h1 className="text-xl font-bold text-dark-gray tracking-wide">
                  Subscribers
                </h1>
              </div>
            </div>
            <div className="relative w-full overflow-x-auto sm:rounded-lg h-[800px]">
              {earninglist && (
                <DataTable rowList={earninglist} columns={columns} />
              )}
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}
