import React from "react";

export default function HomeTopCardSkeleton() {
  return (
    <>
      <div className="item w-full">
        <div className="bg-white rounded-xl w-full h-auto p-5 ">
          <div className="flex flex-col justify-between">
            <div className="flex space-x-[6px] items-center pb-2">
              <div className="w-[70px] h-[70px] flex justify-center items-center rounded-full animate-skeleton"></div>
              <div className="flex flex-col gap-3 justify-center w-full flex-1">
                <p className="text-26 w-full h-3 font-bold tracking-wide text-dark-gray animate-skeleton rounded-xl"></p>
                <p className="text-base w-full h-3 tracking-wide text-thin-light-gray animate-skeleton rounded-xl"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
