/* eslint-disable no-underscore-dangle */
import React from "react";
import { AiFillCopy } from "react-icons/ai";
// import Lottie from "react-lottie";
// import * as Loader from "../../assets/lottie/loader.json";
// import wrappedETH from "../../contracts/wrappedETH.json";

export default function ContractInfo({
  explorerUrl,
  txnHash,
  contractAddress,
  copyAddress,
  copyAdd,
}) {
  //   const [balanceData, setBalanceData] = useState();
  //   const [tokenBalanceData, setTokenBalanceData] = useState();
  //   const provider = new ethers.providers.JsonRpcProvider(networkData.rpc_url);
  //   const provider = new ethers.providers.JsonRpcProvider(networkData.rpc_url);
  //   const defaultOptions = {
  //     loop: true,
  //     autoplay: true,
  //     animationData: Loader,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice",
  //     },
  //   };

  //   const fetchBalance = useCallback(async () => {
  //     const contract = new ethers.Contract(
  //       tokenContractAddress,
  //       wrappedETH.abi,
  //       provider
  //     );
  //     contract.balanceOf(walletData.address).then((balance) => {
  //       setTokenBalanceData(ethers.utils.formatEther(balance));
  //     });

  //     provider.getBalance(walletData.address).then((balance) => {
  //       setBalanceData(ethers.utils.formatEther(balance));
  //     });
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);
  //   useEffect(() => {
  //     provider.on("block", fetchBalance);

  //     return () => {
  //       provider.removeAllListeners("block");
  //     };
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [fetchBalance]);

  const truncate = (str) =>
    // eslint-disable-next-line prefer-template
    str.length > 10 ? str.substring(0, 3) + "..." + str.slice(-3) : str;

  return (
    <>
      <div className="personal-info-tab w-full flex flex-col justify-between">
        <div className="md:w-6/12 content-footer shadow mt-3 rounded-md">
          <div className="w-full border-t border-light-purple flex justify-center items-center">
            <div className="w-full flex space-x-4 text-xl p-3 ">
              <ul className="list-inside divide-y divide-slate-200 w-full">
                <li className="flex p-1 justify-between items-center">
                  <div className="text-sm">Contract Address: </div>
                  <span
                    className={`text-sm font-bold flex items-center gap-3 ${
                      copyAdd === "Copied" ? " text-blue-400" : ""
                    }`}
                  >
                    <a
                      href={`${explorerUrl}address/${contractAddress}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {truncate(contractAddress)}
                    </a>
                    <button
                      type="button"
                      className={`w-[28px] h-[28px] flex justify-center items-center gradientButton text-base rounded-full text-white `}
                      onClick={() => copyAddress(contractAddress)}
                    >
                      <AiFillCopy className="text-1xl" />
                    </button>
                  </span>
                </li>
                <li className="flex p-1 justify-between items-center">
                  <div className="text-sm">Txn Hash: </div>
                  <span
                    className={`text-sm font-bold flex items-center gap-3 ${
                      copyAdd === "Copied" ? " text-blue-400" : ""
                    }`}
                  >
                    <a
                      href={`${explorerUrl}tx/${txnHash}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {truncate(txnHash)}
                    </a>
                    <button
                      type="button"
                      className={`w-[28px] h-[28px] flex justify-center items-center gradientButton text-base rounded-full text-white `}
                      onClick={() => copyAddress(txnHash)}
                    >
                      <AiFillCopy className="text-1xl" />
                    </button>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
