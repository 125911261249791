/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-expressions */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useRequestFun from "../../../hooks/Axios/useRequestFun";
import Icons from "../../Helpers/Icons";
import PasswordSvg from "../PasswordSvg";

export default function ChangePasswordTab() {
  const [oldPass, setOldPass] = useState("hide-password");
  const [newPass, setNewPass] = useState("hide-password");
  const [confirmPass, setConfirmPass] = useState("hide-password");
  const [matchError, setMatchError] = useState(false);
  const { putRequest } = useRequestFun();
  const navigate = useNavigate();
  const showPassword = (value) => {
    const password = document.getElementById(`${value}`);
    if (value && value === "old_password") {
      if (password.type === "password") {
        password.type = "text";
        setOldPass("show-password");
      } else {
        password.type = "password";
        setOldPass("hide-password");
      }
    }
    if (value && value === "new_password") {
      if (password.type === "password") {
        password.type = "text";
        setNewPass("show-password");
      } else {
        password.type = "password";
        setNewPass("hide-password");
      }
    }
    if (value && value === "confirm_password") {
      if (password.type === "password") {
        password.type = "text";
        setConfirmPass("show-password");
      } else {
        password.type = "password";
        setConfirmPass("hide-password");
      }
    }
  };
  const updatePassword = (e) => {
    e.preventDefault();
    return toast.warning("Disabled For Demo Version!");

    if (newPass !== confirmPass) {
      setMatchError(true);
      return;
      // eslint-disable-next-line no-else-return
    } else {
      setMatchError(false);
    }
    putRequest("changepassword", {
      newPassword: newPass,
      oldPassword: oldPass,
    })
      .then((res) => {
        toast.success(res.data.message);
        navigate("/");
      })
      .catch((err) => {
        toast.warning(err.response.data.message);
      });
  };
  return (
    <div className="changePasswordTab w-full">
      <div className="w-full flex xl:flex-row flex-col-reverse space-x-5 xl:items-center">
        <form onSubmit={updatePassword}>
          <div className="flex-1 mb-10">
            <div className="input-field mb-6">
              <label
                className="input-label text-dark-gray text-xl font-bold block mb-2.5"
                htmlFor="old_password"
              >
                Old Password
              </label>
              <div className="input-wrapper border border-light-purple w-full rounded-[50px] h-[58px] overflow-hidden relative ">
                <div className="absolute left-6 bottom-[17px] z-10">
                  <Icons name="password" />
                </div>
                <input
                  placeholder="● ● ● ● ● ●"
                  className="input-field placeholder:text-base text-bese px-12 text-dark-gray w-full h-full bg-[#FAFAFA] focus:ring-0 focus:outline-none"
                  type="password"
                  id="old_password"
                  onChange={(e) => setOldPass(e.target.value)}
                />
                <div
                  className="absolute right-6 bottom-[17px] z-10 cursor-pointer"
                  onClick={() => showPassword("old_password")}
                >
                  <Icons name={oldPass} />
                </div>
              </div>
            </div>
            <div className="input-field mb-6">
              <label
                className="input-label text-dark-gray text-xl font-bold block mb-2.5"
                htmlFor="new_password"
              >
                New Password
              </label>
              <div className="input-wrapper border border-light-purple w-full rounded-[50px] h-[58px] overflow-hidden relative ">
                <div className="absolute left-6 bottom-[17px] z-10">
                  <Icons name="password" />
                </div>
                <input
                  placeholder="● ● ● ● ● ●"
                  className="input-field placeholder:text-base text-bese px-12 text-dark-gray w-full h-full bg-[#FAFAFA] focus:ring-0 focus:outline-none"
                  type="password"
                  id="new_password"
                  onChange={(e) => setNewPass(e.target.value)}
                />
                <div
                  className="absolute right-6 bottom-[17px] z-10 cursor-pointer"
                  onClick={() => showPassword("new_password")}
                >
                  <Icons name={newPass} />
                </div>
              </div>
            </div>
            <div className="input-field mb-8">
              <label
                className="input-label text-dark-gray text-xl font-bold block mb-2.5"
                htmlFor="confirm_password"
              >
                Confirm Password
              </label>
              <div className="input-wrapper border border-light-purple w-full rounded-[50px] h-[58px] overflow-hidden relative ">
                <div className="absolute left-6 bottom-[17px] z-10">
                  <Icons name="password" />
                </div>
                <input
                  placeholder="● ● ● ● ● ●"
                  className="input-field placeholder:text-base text-bese px-12 text-dark-gray w-full h-full bg-[#FAFAFA] focus:ring-0 focus:outline-none"
                  type="password"
                  id="confirm_password"
                  onChange={(e) => setConfirmPass(e.target.value)}
                />
                <div
                  className="absolute right-6 bottom-[17px] z-10 cursor-pointer"
                  onClick={() => showPassword("confirm_password")}
                >
                  <Icons name={confirmPass} />
                </div>
              </div>
              {matchError && (
                <small className=" text-red-500">Password Didn't Match</small>
              )}
            </div>
            <div className="flex justify-center space-x-4 items-center">
              <button
                type="button"
                className="text-light-red text-18 tracking-wide border-b border-light-red "
                onClick={() => navigate("/")}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="gradientButton tracking-wide rounded-full w-[173px] h-[46px] flex justify-center items-center"
              >
                <span className="font-thin text-18 tracking-wide antialiased text-white">
                  Change Password
                </span>
              </button>
            </div>
          </div>
        </form>

        <div className="w-[440px] sm:flex hidden justify-end">
          <PasswordSvg />
        </div>
      </div>
    </div>
  );
}
