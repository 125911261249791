import React from "react";
import { FiCheck } from "react-icons/fi";

export default function StepOrderCheck() {
  return (
    <div className="w-5 h-5 flex justify-center items-center rounded-full text-white bg-gradient ">
      <FiCheck className="text-xs" />
    </div>
  );
}
