import React from "react";
import Layout from "../../components/Partials/Layout";
import AllRoleList from "../../components/Roles/AllRolesList";

export default function RoleList() {
  return (
    <>
      <Layout>
        <AllRoleList />
      </Layout>
    </>
  );
}
