import React from "react";
import StepOrderBlink from "./StepOrderBlink";
import StepOrderCancel from "./StepOrderCancel";
import StepOrderCheck from "./StepOrderCheck";
import StepOrderIcon from "./StepOrderIcon";

export default function StepOrderContent({ status, lastStep, title, time }) {
  return (
    <div className="flex flex-1 flex-col items-center relative">
      <div className="flex w-full absolute top-0 left-[45%] ">
        {status === "checked" ? (
          <StepOrderCheck />
        ) : status === "process" ? (
          <StepOrderBlink />
        ) :  status === "cancel" ? <StepOrderCancel /> : (
          <StepOrderIcon />
        )}

        {!lastStep && (
          // <div className="w-full h-[1px] bg-gradient m-auto flex-1"></div>
          <div className={`w-full h-[1px] ${status === "cancel" ? ` bg-red-400` :`bg-gradient`} m-auto flex-1`}></div>
        )}
      </div>
      <div className="flex flex-col items-center relative top-5">
        <p>{title}</p>
        <p className="text-sm font-medium lineHeight140 max-w-[120px] text-center">
          {time}
        </p>
      </div>
    </div>
  );
}
