import React from "react";
import AddAdmin from "../../components/Admin/AddAdmin";

export default function Admin() {
  return (
    <>
      <AddAdmin />
    </>
  );
}
