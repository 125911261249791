import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import drawerReducer from "./drawer";
import forgetReducer from "./user/forgetSlice";
import userReducer from "./user/UserSlice";

const reducers = combineReducers({
  drawer: drawerReducer,
  user: userReducer,
  forgetPassword: forgetReducer,
});
const persistConfig = {
  key: "root",
  storage,
  // blacklist: ["user"],
};
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
// export default configureStore({
//   reducer: {
//     drawer: drawerReducer,
//     user: userReducer,
//   },
// });
export default store;
