import React from "react";
import UpdatePassword from "../components/Auth/UpdatePassword";

export default function UpdatePasswordPages() {
  return (
    <>
      <UpdatePassword />
    </>
  );
}
