import React from "react";

export default function StepOrderBlink() {
  return (
    <div className="relative">
      <span className="flex h-5 w-5">
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-gradient opacity-75"></span>
        <span className="relative inline-flex rounded-full h-5 w-5 bg-gradient"></span>
      </span>
    </div>
  );
}
