/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from "react";

import { AiOutlineEye } from "react-icons/ai";
import { BiHeading } from "react-icons/bi";

import { ethers } from "ethers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import NotFound from "../../assets/images/not-found.svg";
import DataTable from "../../components/Helpers/DataGrid";
import LottieLoader from "../../components/Helpers/LottieLoader";
import Layout from "../../components/Partials/Layout";
import ConfirmModal from "../../components/modal/ConfirmModal";
import useRequestFun from "../../hooks/Axios/useRequestFun";

export default function NftList() {
  const [isLoader, setIsLoader] = useState(true);
  const [nftsList, setNftsList] = useState();
  const [allNfts, setAllNfts] = useState();
  const [networkData, setNetworkData] = useState();
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isHeaderFeaturedId, setIsHeaderFeaturedId] = useState();
  const [isFeaturedId, setIsFeaturedId] = useState();
  const { getRequest, putRequest } = useRequestFun();
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  // const navigate = useNavigate();
  const { role, permissions } = useSelector((store) => store.user);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const getRequests = useCallback(() => {
    getRequest("getallnfts").then((res) => {
      console.log("res.data", res.data.data);
      setNftsList(res.data.data);
      setAllNfts(res.data.data);

      setIsLoader(false);
    });
    getRequest(`getNetWorks`).then(async (res) => {
      if (res.data.data[0]) {
        setNetworkData(res.data.data[0]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const truncate = (str) =>
    // eslint-disable-next-line prefer-template
    str?.length > 10 ? str.substring(0, 6) + "..." + str.slice(-6) : str;
  useEffect(() => {
    getRequest("getallnfts").then((res) => {
      setNftsList(res.data.data);
      setIsLoader(false);
    });
    getRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionCol = !(
    role === "superadmin" ||
    permissions.updateAdminInfo ||
    permissions.deleteAdmin
  );
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      minWidth: 150,
      field: "name",
      headerName: "Name",
      flex: 1,
      valueGetter: (params) =>
        `${
          params.row?.data?.metadata?.name
            ? params.row?.data?.metadata?.name
            : "N/A"
        }`,
      renderCell: (params) => (
        <a
          href={
            params.row.data?.contract?.address
              ? `nfts/${params.row.data.contract.address}/${params.row.tokenId}`
              : "#"
          }
          rel="noreferrer"
          className="hover:text-blue-400"
        >
          {params.row?.data?.metadata?.name
            ? params.row?.data?.metadata?.name
            : "N/A"}
        </a>
      ),
    },
    {
      minWidth: 150,
      field: "id",
      headerName: "id",
      flex: 1,
      valueGetter: (params) => `${params.row?._id}`,
    },
    {
      minWidth: 150,
      field: "image",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => (
        <a
          href={
            params.row.data?.contract?.address
              ? `nfts/${params.row.data?.contract?.address}/${params.row.tokenId}`
              : "#"
          }
          rel="noreferrer"
          className="hover:text-blue-400"
        >
          {console.log("{ params.row.data}", params.row.fileType)}
          <img
            src={
              params.row.fileType === "video/mp4" ||
              params.row.fileType === "audio/mpeg"
                ? params.row.data?.metadata?.image
                : params.row.data?.media[0].gateway
                ? params.row.data?.media[0].gateway
                : NotFound
            }
            alt="Nft"
            width={60}
            height={60}
            loading="eager"
          />
        </a>
      ),
    },

    {
      minWidth: 150,
      field: "contract.address",
      headerName: "Contract",
      flex: 1,
      // valueGetter: (params) => `${params.row.data.contract.address}`,
      renderCell: (params) => (
        <a
          href={
            params.row?.data?.contract?.address
              ? `${networkData?.explorer_url}address/${params.row.data?.contract?.address}`
              : "#"
          }
          target="_blank"
          rel="noreferrer"
        >
          {params.row.data?.contract?.address
            ? truncate(params.row.data?.contract?.address)
            : "#"}
        </a>
      ),
    },

    {
      minWidth: 150,
      field: "token_id",
      headerName: "Token Id",
      flex: 1,
      valueGetter: (params) =>
        `${
          params.row.data?.id?.tokenId
            ? ethers.BigNumber.from(params.row.data?.id?.tokenId)
            : ""
        }`,
    },
    {
      minWidth: 150,
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) =>
        params.row.status === "active" ? (
          <div className="bg-[#D2FFD4] text-[#0D9F16] rounded-md py-1 px-3 uppercase">
            {params.row.status}
          </div>
        ) : params.row.status === "block" ? (
          <div className="bg-[#E9DCFE] text-[#2C0270] rounded-md py-1 px-3 uppercase">
            {params.row.status}
          </div>
        ) : params.row.status === "inactive" ? (
          <div className="bg-[#E9DCFE] text-[#2C0270] rounded-md py-1 px-3 uppercase">
            {params.row.status}
          </div>
        ) : (
          params.row.status === "suspend" && (
            <div className="bg-[#FFD4D0] text-[#FF1600] rounded-md py-1 px-3 uppercase">
              {params.row.status}
            </div>
          )
        ),
    },

    {
      headerName: "Actions",
      flex: 1,
      hide: actionCol,
      minWidth: 150,
      renderCell: (params) => {
        const setFeatured = (value) => {
          if (value === "feature") {
            // e.stopPropagation(); // don't select this row after clicking
            setIsFeaturedId(params.id);
            setIsHeaderFeaturedId("");
            setIsConfirmModal(!isConfirmModal);

            setAnchorEl(null);
          }
          if (value === "h-feature") {
            // e.stopPropagation(); // don't select this row after clicking
            setIsHeaderFeaturedId(params.id);
            setIsFeaturedId("");
            setIsConfirmModal(!isConfirmModal);
            setAnchorEl(null);
          }
        };
        // const setHeaderFeatured = (e) => {
        //   e.stopPropagation(); // don't select this row after clicking
        //   setIsHeaderFeaturedId(params.id);
        //   setIsConfirmModal(!isConfirmModal);
        // };

        return (
          <>
            {role === "superadmin" && (
              <>
                <div className="flex gap-1">
                  {role === "superadmin" && (
                    <>
                      <button
                        title="Set Feature"
                        type="button"
                        className="text-sm text-white bg-emerald-400 w-10 h-10 flex justify-center items-center rounded-full"
                        onClick={() => setFeatured("feature", params.id)}
                      >
                        {/* <AiOutlineCheck className="text-xl" /> */}
                        <p className="text-xl font-bold">F</p>
                      </button>
                      <button
                        title="Set Header Feature"
                        type="button"
                        className="text-sm text-white bg-teal-700 w-10 h-10 flex justify-center items-center rounded-full"
                        onClick={() => setFeatured("h-feature", params.id)}
                      >
                        <BiHeading className="text-xl" />
                      </button>
                    </>
                  )}
                  <a
                    title="View on NFTO"
                    target="blank"
                    href={
                      params.row?.data?.contract.address
                        ? `${process.env.REACT_APP_FRONTEND_URL}/item/${params.row.data.contract.address}/${params.row.tokenId}`
                        : "#"
                    }
                  >
                    <button
                      type="button"
                      className="text-sm text-white bg-purple w-10 h-10 flex justify-center items-center rounded-full"
                    >
                      <AiOutlineEye className="text-2xl" />
                    </button>
                  </a>
                </div>
              </>
            )}
          </>
        );
      },
    },
  ];

  const handalerConfirm = async () => {
    return toast.warning("Disabled For Demo Version!");
    const setUrl = isFeaturedId
      ? `isFeatured/${isFeaturedId}`
      : `headerFeature/${isHeaderFeaturedId}`;
    const setId = !isFeaturedId ? isHeaderFeaturedId : isFeaturedId;
    putRequest(setUrl, {
      id: setId,
    }).then((res) => {
      if (res.status === 200) {
        toast.success(
          `This NFT set ${isHeaderFeaturedId ? "header" : ""} featured!`
        );
        setIsConfirmModal(!isConfirmModal);
      } else {
        toast.warning("Please try again!");
        setIsConfirmModal(!isConfirmModal);
      }
    });
  };
  const findDataByDateRange = (update) => {
    let data = [];
    if (
      new Date(update[0]).toLocaleDateString() ===
      new Date(update[1]).toLocaleDateString()
    ) {
      data = allNfts.filter(
        (item) =>
          new Date(item.createdAt).toLocaleDateString() ===
          new Date(update[0]).toLocaleDateString()
      );
    } else {
      data = allNfts.filter(
        (item) =>
          new Date(item.createdAt).valueOf() >= new Date(update[0]).valueOf() &&
          new Date(item.createdAt).valueOf() <= new Date(update[1]).valueOf()
      );
    }

    setNftsList(data);
    if (update[0] === null && update[1] === null) {
      setNftsList(allNfts);
    }
  };
  return (
    <>
      <Layout>
        {isLoader ? (
          <LottieLoader />
        ) : (
          <div
            className={`tableForUpdate w-full p-8 bg-white overflow-hidden rounded-2xl shadowSection relative min-h-[520px] `}
          >
            <div className="header w-full sm:flex justify-between items-center mb-5">
              <div className="flex flex-col sm:flex-row space-x-2 items-start md:items-center justify-between mb-2 sm:mb-0 w-full">
                <h1 className="text-xl font-bold text-dark-gray tracking-wide">
                  Nft's List
                </h1>
                <div>
                  <p>Search by Date Range</p>
                  <DatePicker
                    selectsRange
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      setDateRange(update);
                      findDataByDateRange(update);
                    }}
                    isClearable
                    className="border-2 border-gray-300 rounded-lg min-w-[250px] p-2"
                    placeholderText="MM/DD/YYYY"
                  />
                </div>
              </div>
            </div>
            <div className="relative w-full overflow-x-auto sm:rounded-lg h-[700px]">
              {nftsList && <DataTable rowList={nftsList} columns={columns} />}
            </div>
          </div>
        )}
      </Layout>

      {isConfirmModal && (
        <ConfirmModal
          headMessage="Confirm"
          bodyMessage={`Are you sure set ${
            isHeaderFeaturedId ? "header" : ""
          } featured this NFT ?`}
          btn1="Confirm"
          btn2="Not Now"
          isMoadl={() => setIsConfirmModal(!isConfirmModal)}
          actionCofirm={() => handalerConfirm()}
        ></ConfirmModal>
      )}
    </>
  );
}
