/* eslint-disable no-unreachable */
/* eslint-disable no-underscore-dangle */
// import { Avatar, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { MdOutlineUpdate } from "react-icons/md";
import { toast } from "react-toastify";
import notfOund from "../../assets/images/not-found.svg";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import Layout from "../Partials/Layout";

import DataTable from "../Helpers/DataGrid";
import LottieLoader from "../Helpers/LottieLoader";
import ConfirmModal from "../modal/ConfirmModal";

export default function MyCollection() {
  const [isLoader, setIsLoader] = useState(true);
  const [updateAbleId, setUpdateAbleId] = useState();
  const [currentStatus, setCurrentStatus] = useState();
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [totalNft, setTotalNft] = useState(0);

  const [collectionlist, setCollectionList] = useState([]);
  // const [anchorEl, setAnchorEl] = useState();
  // const open = Boolean(anchorEl);
  const [networkData, setNetworkData] = useState();
  // const { role, permissions } = useSelector((store) => store.user);
  // const [balance, setBalance] = useState("0");
  const { getRequest, putRequest } = useRequestFun();
  const getRequests = useCallback(() => {
    getRequest(`getNetWorks`).then(async (response) => {
      if (response.data.data[0]) {
        setNetworkData(response.data.data[0]);

        getRequest("getallcollection").then((res) => {
          if (res?.data?.data) {
            setCollectionList(res?.data?.data);
          }
        });
      }
      setIsLoader(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated]);

  useEffect(() => {
    getRequests();
  }, [getRequests]);

  const confirmHandler = () => {
    putRequest("updatecollectionstatus", {
      status: currentStatus === "active" ? "inactive" : "active",
      contract: updateAbleId,
    })
      .then((result) => {
        setIsUpdated(!isUpdated);
        setIsConfirmModal(!isConfirmModal);
        toast.success(
          result?.data?.message
            ? result?.data?.message
            : "updated successfully!"
        );
      })
      .catch((err) => {
        toast.error(err?.message);
        setIsConfirmModal(!isConfirmModal);
      });
  };
  const truncate = (str) =>
    // eslint-disable-next-line prefer-template
    str.length > 10 ? str.substring(0, 6) + "..." + str.slice(-6) : str;

  const columns: GridColDef[] = [
    {
      minWidth: 150,
      field: "name",
      headerName: "Name",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.name ? `${params.row.name}` : "N/A",
    },
    {
      minWidth: 150,
      field: "image",
      headerName: "Image",
      flex: 1,
      renderCell: (params) =>
        params?.row?.image && params?.row?.image !== "" ? (
          <img
            src={params.row.image}
            alt="Collection"
            width={60}
            height={60}
            loading="eager"
          />
        ) : (
          <img src={notfOund} alt="Collection" width={60} />
        ),
    },
    {
      minWidth: 150,
      field: "symbol",
      headerName: "Symbol",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.symbol ? params.row.symbol : "N/A",
    },
    {
      minWidth: 150,
      field: "contract_address",
      headerName: "Contract",
      flex: 1,
      renderCell: (params) => (
        <a
          href={`${networkData.explorer_url}address/${params.row.contract_address}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {truncate(params.row.contract_address)}
        </a>
      ),
    },
    {
      minWidth: 150,
      field: "creator_address",
      headerName: "Creator",
      flex: 1,
      renderCell: (params) => (
        <a
          href={`${networkData.explorer_url}address/${params.row.creator_address}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {truncate(params.row.creator_address)}
        </a>
      ),
    },
    {
      field: "isLazyCollection",
      headerName: "Type",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row?.isLazyCollection ? "Lazy Collection" : "Onchain Collection",
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      renderCell: (params) =>
        params.row.status === "active" ? (
          <div className="bg-[#D2FFD4] text-[#0D9F16] rounded-md py-1 px-3 uppercase">
            {params.row.status}
          </div>
        ) : (
          params.row.status === "inactive" && (
            <div className="bg-[#FFD4D0] text-[#FF1600] rounded-md py-1 px-3 uppercase">
              {params.row.status}
            </div>
          )
        ),
    },

    {
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const updateStatus = () => {
          return toast.warning("Disabled For Demo Version!");
          setCurrentStatus(params?.row?.status);
          setUpdateAbleId(
            params?.row?.isLazyCollection
              ? params?.id
              : params?.row?.contract_address
          );
          getRequest(
            `getcountcolcisenft/${
              params?.row?.isLazyCollection
                ? params?.id
                : params?.row?.contract_address
            }`
          ).then((res) => {
            setTotalNft(res.data?.count);
          });
          setIsConfirmModal(!isConfirmModal);
        };

        return (
          <>
            <>
              <div className="flex gap-1">
                <>
                  <button
                    title="Update Status"
                    type="button"
                    className="text-sm text-white bg-emerald-400 px-2.5 py-1.5 rounded-full"
                    onClick={() => updateStatus()}
                  >
                    <MdOutlineUpdate className="text-xl" />
                  </button>
                  <a
                    title="View on NFTO"
                    target="blank"
                    href={`${process.env.REACT_APP_FRONTEND_URL}/collections/${
                      params.row.isLazyCollection
                        ? params?.row?._id
                        : params.row.contract_address
                    }`}
                  >
                    <button
                      type="button"
                      className="text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
                    >
                      <AiOutlineEye className="text-xl" />
                    </button>
                  </a>
                </>
              </div>
            </>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Layout>
        {isLoader ? (
          <LottieLoader />
        ) : (
          <div
            className={`tableForUpdate w-full p-8 bg-white overflow-hidden rounded-2xl shadowSection relative min-h-[520px] `}
          >
            <div className="header w-full sm:flex justify-between items-center mb-5">
              <div className="flex space-x-2 items-center mb-2 sm:mb-0">
                <h1 className="text-xl font-bold text-dark-gray tracking-wide">
                  Collection List
                </h1>
              </div>
            </div>
            <div className="relative w-full overflow-x-auto sm:rounded-lg h-[800px]">
              {collectionlist && (
                <DataTable rowList={collectionlist} columns={columns} />
              )}
            </div>
          </div>
        )}
      </Layout>
      {isConfirmModal && (
        <ConfirmModal
          headMessage="Confirm"
          bodyMessage={`Are you sure ${
            currentStatus === "active" ? "inactive" : "active"
          } this collection with ${
            totalNft > 1 ? `${totalNft} NFTs` : `${totalNft} NFT`
          }  ? `}
          btn1="Confirm"
          btn2="Not Now"
          isMoadl={() => setIsConfirmModal(!isConfirmModal)}
          actionCofirm={() => confirmHandler()}
        ></ConfirmModal>
      )}
    </>
  );
}
