/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import { AiFillCopy } from "react-icons/ai";
import Lottie from "react-lottie";
import { toast } from "react-toastify";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import InputCom from "../Helpers/Inputs";
// import { toast } from "react-toastify";
// import useRequestFun from "../../hooks/Axios/useRequestFun";
// import InputCom from "../Helpers/Inputs";
// import LottieLoader from "../Helpers/LottieLoader";
import * as Loader from "../../assets/lottie/loader.json";
// import LottieLoader from "../Helpers/LottieLoader";

export default function ExportPrivateKey({ setLoader }) {
  const [password, setPassword] = useState("");
  const [isWallet, setIsWallet] = useState(true);
  const [hasPrivateKey, setHasPrivateKey] = useState(false);
  const [isPrivateKey, setIsPrivateKey] = useState(false);
  const [privateKey, setPrivateKey] = useState("");
  const [messages, setMessages] = useState({});
  const [copy, setCopy] = useState("Copy");
  //   const tokenContractAddress = "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6";
  //   const [isWallet, setIsWallet] = useState(false);
  //   const [walletData, setWalletData] = useState();
  //   const [networkData, setNetworkData] = useState();

  const { postRequest, getRequest } = useRequestFun();

  useEffect(() => {
    getRequest(`getAdminWallet`).then(async (res) => {
      if (res.data.data) {
        setIsWallet(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handlerSubmit = (event) => {
    event.preventDefault();
    return toast.warning("Disabled For Demo Version!");
    let isValid = true;
    if (password === "") {
      setMessages({ password: "Password is required" });
      isValid = false;
    } else {
      setMessages("");
    }

    if (isValid) {
      //   setLoader(true);
      setHasPrivateKey(true);
      setIsPrivateKey(true);
      postRequest("exportprivatekey", {
        password,
      })
        .then((res) => {
          setPrivateKey(res.data.data.privateKey);
          //   setLoader(false);

          setHasPrivateKey(false);
          toast.success(`${res.data.message}`, {
            icon: `🙂`,
          });
        })
        .catch((err) => {
          setLoader(false);
          setHasPrivateKey(false);
          toast.error(`${err.response.data.message}`);
        });
    }
  };
  const copyKey = () => {
    navigator.clipboard.writeText(privateKey);
    setCopy("Copied");
  };
  const truncate = (str) =>
    // eslint-disable-next-line prefer-template
    str.length > 10 ? str.substring(0, 6) + "..." + str.slice(-6) : str;

  return (
    <>
      <div className="personal-info-tab w-full flex justify-between">
        <div className="create-wallet-details w-full md:w-8/12">
          <div className="flex flex-col-reverse sm:flex-row">
            <div className="flex-1">
              <div className="fields w-full">
                {/* first name and last name */}

                <form onSubmit={handlerSubmit}>
                  <div className="xl:flex mb-6 flex flex-col">
                    <div className="field w-full">
                      <InputCom
                        label="Admin Password"
                        type="password"
                        name="adminpassword"
                        placeholder="Admin Password"
                        value={password}
                        inputHandler={(e) => setPassword(e.target.value)}
                      />
                      <small className="text-red-400 ml-6 italic font-bold">
                        {messages.password}
                      </small>
                    </div>
                    <div className="field w-full">
                      <div className="flex ">
                        <button
                          type="submit"
                          className={`w-[152px] h-[46px] ml-auto flex justify-center items-center gradientButton text-base rounded-full text-white ${
                            isWallet === true ? "opacity-50" : ""
                          }`}
                          disabled={!!isWallet}
                        >
                          Export Private Key
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* border line */}
            </div>
          </div>
          {isPrivateKey && (
            <div className="personal-info-tab w-full flex flex-col justify-between w-50">
              <div className="w-full content-footer  mt-3 rounded-md">
                <div className="w-full border-t border-light-purple flex justify-start items-center">
                  <div className="flex space-x-4 text-xl shadow-lg mt-3 mb-6 rounded-full w-full">
                    <ul className="list-inside divide-y divide-slate-200 w-full">
                      {hasPrivateKey ? (
                        <div className="justify-center items-center w-[6%]">
                          <Lottie
                            options={defaultOptions}
                            className="object-cover "
                          />
                        </div>
                      ) : (
                        privateKey && (
                          <>
                            <li className="flex p-3 justify-between text-sm">
                              <div
                                className="flex items-center gap-3 w-full justify-between"
                                onClick={() => copyKey()}
                              >
                                Private Key:{" "}
                                <div className="flex">
                                  <span
                                    className={`text-xl font-bold mr-3 ${
                                      copy === "Copied" ? " text-blue-400" : ""
                                    }`}
                                  >
                                    {truncate(privateKey)}
                                  </span>
                                  <button
                                    type="button"
                                    className={`w-[28px] h-[28px] flex justify-center items-center gradientButton text-base rounded-full text-white `}
                                  >
                                    <AiFillCopy className="text-1xl" />
                                  </button>
                                </div>
                              </div>
                            </li>
                          </>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {privateKey && (
          <div className="qr-code w-4/12 flex justify-center align-top">
            <div>
              <img
                src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${privateKey}&choe=UTF-8`}
                alt="qrcode"
                width={200}
                height="200"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
