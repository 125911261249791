/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import InputCom from "../Helpers/Inputs";
import LottieLoader from "../Helpers/LottieLoader";
import Layout from "../Partials/Layout";
import ContractInfo from "./ContractInfo";
// import WalletInfo from "./WalletInfo";
// import LottieLoader from "../Helpers/LottieLoader";
// import * as Loader from "../../assets/lottie/loader.json";
// import wrappedETH from "../../contracts/wrappedETH.json";

export default function DeployContract() {
  const [name, setName] = useState("");
  //   const tokenContractAddress = "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6";
  const [isWallet, setIsWallet] = useState(false);
  //   const [walletData, setWalletData] = useState();
  const [isNetwork, setIsNetwork] = useState(false);
  const [isDeployed, setIsDeployed] = useState(false);
  //   const [privatekey, setPrivateKey] = useState("0x");
  const [symbol, setSymbol] = useState("");
  const [networkId, setNetworkId] = useState("");
  const [messages, setMessages] = useState({});
  const { postRequest, getRequest } = useRequestFun();
  const [isLoader, setIsLoader] = useState(true);
  const [contractAddress, setContractAddress] = useState("");
  const [txnHash, setTxnHash] = useState("");
  const [explorerUrl, setExplorerURL] = useState("");
  const [copy, setCopy] = useState("Copy");

  useEffect(() => {
    getRequest(`getAdminWallet`)
      .then(async (res) => {
        if (res.data.data) {
          setIsWallet(true);
          setIsLoader(false);
        }
      })
      .catch(() => {
        setIsLoader(false);
      });
    getRequest(`getNetWorks`)
      .then(async (res) => {
        if (res.data.data[0]) {
          setIsNetwork(true);
          setNetworkId(res.data.data[0]._id);
          setExplorerURL(res.data.data[0].explorer_url);
        }
        setIsLoader(false);
      })
      .catch(() => {
        setIsLoader(false);
      });
    getRequest(`getmarketcontract`)
      .then(async (res) => {
        if (res.data.data) {
          setIsDeployed(true);
          setName(res.data.data.name);
          setSymbol(res.data.data.symbol);
          setContractAddress(res.data.data.contract_address);
          setTxnHash(res.data.data.tx_hash);
          setIsLoader(false);
        }
      })
      .catch(() => {
        setIsLoader(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerSubmit = (event) => {
    event.preventDefault();
    let isValid = true;
    if (name === "") {
      setMessages({ name: "Contract Name is required" });
      isValid = false;
    } else if (symbol === "") {
      setMessages({ symbol: "Contract Symbol is required" });
      isValid = false;
    } else if (!isNetwork) {
      toast.error("Please Setup Network First");
      isValid = false;
    } else if (!isWallet) {
      toast.error("Please Setup Wallet First");
      isValid = false;
    } else {
      setMessages("");
    }

    if (isValid) {
      setIsLoader(true);
      postRequest("deploymarketcontract", {
        name,
        symbol,
        network_id: networkId,
      })
        .then((res) => {
          setIsLoader(false);
          setIsDeployed(true);
          setIsWallet(true);
          setName(res.data.data.name);
          setSymbol(res.data.data.symbol);
          setContractAddress(res.data.data.contract_address);
          setTxnHash(res.data.data.tx_hash);
          //   setWalletData(res.data.data);
          toast.success(`${res.data.message}`, {
            icon: `🙂`,
          });
        })
        .catch((err) => {
          setIsLoader(false);
          toast.error(`${err.response.data.message}`);
        });
    }
  };
  const copyKey = (privateKey) => {
    navigator.clipboard.writeText(privateKey);
    setCopy("Copied");
  };

  return (
    <>
      {isLoader ? (
        <LottieLoader />
      ) : (
        <Layout>
          <div className="settings-wrapper w-full relative mb-10">
            <div className="mainWrapper w-full">
              {/* heading */}

              <div className="content-container w-full rounded-2xl bg-white">
                <div className="content-body-items flex-1 p-10">
                  <div className="heading w-full mb-6">
                    <h1 className="text-26 font-bold text-dark-gray antialiased">
                      Market Contract
                    </h1>
                  </div>
                  <div className="personal-info-tab w-full flex flex-col justify-between">
                    <div className="flex flex-col-reverse sm:flex-row">
                      <div className="flex-1 sm:mr-10">
                        <div className="fields w-full">
                          {/* first name and last name */}

                          <form onSubmit={handlerSubmit}>
                            <div className="xl:flex xl:space-x-7 mb-6">
                              <div className="field md:w-1/2 mb-6 xl:mb-0">
                                <InputCom
                                  label="Name"
                                  type="text"
                                  name="name"
                                  placeholder="Contract Name"
                                  value={name}
                                  inputHandler={(e) => setName(e.target.value)}
                                />
                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.name}
                                </small>
                              </div>
                              <div className="field md:w-1/2 mb-6 xl:mb-0">
                                <InputCom
                                  label="Symbol"
                                  type="text"
                                  name="contract symbol"
                                  placeholder="Contract Symbol"
                                  value={symbol}
                                  inputHandler={(e) =>
                                    setSymbol(e.target.value)
                                  }
                                />
                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.symbol}
                                </small>
                              </div>
                              <div className="field w-full">
                                <div className="flex space-x-4 md:mt-[2.85rem] mr-9">
                                  <button
                                    type="submit"
                                    className={`w-[152px] h-[46px] flex justify-center items-center gradientButton text-base rounded-full text-white ${
                                      isDeployed === true ? "opacity-50" : ""
                                    }`}
                                    disabled={!!isDeployed}
                                  >
                                    Deploy Contract
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        {/* border line */}
                      </div>
                    </div>
                    {isDeployed && (
                      <ContractInfo
                        contractAddress={contractAddress}
                        txnHash={txnHash}
                        explorerUrl={explorerUrl}
                        copyAddress={copyKey}
                        copyAdd={copy}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}
