/* eslint-disable no-unreachable */
/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineCheck, AiOutlineClose, AiOutlineEdit } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "../../components/Helpers/DataGrid";
import LottieLoader from "../../components/Helpers/LottieLoader";
import Layout from "../../components/Partials/Layout";
import ConfirmModal from "../../components/modal/ConfirmModal";
import useRequestFun from "../../hooks/Axios/useRequestFun";

export default function SubscriptionList() {
  const [isLoader, setIsLoader] = useState(true);
  const [isUpdateStatus, setIsUpdateStatus] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [planList, setPlanList] = useState();
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isHeaderFeaturedId, setIsHeaderFeaturedId] = useState();
  const { putRequest, getRequest } = useRequestFun();
  const { role } = useSelector((store) => store.user);
  const navigate = useNavigate();
  const getRequests = useCallback(() => {
    getRequest("getAllPlan").then((res) => {
      setPlanList(res.data.data);
      setIsLoader(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getRequests();
  }, [getRequests, isUpdateStatus]);
  const columns = [
    {
      field: "name",
      headerName: "Plan Name",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => `${params.row.name}`,
    },
    {
      field: "price",
      headerName: "Plan Price",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => `${params.row.price}`,
    },
    {
      field: "expiry",
      headerName: "Plan Expiry",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => `${params.row.expiry}`,
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      renderCell: (params) =>
        params.row.status === "active" ? (
          <div className="bg-[#D2FFD4] text-[#0D9F16] rounded-md py-1 px-3 uppercase">
            Active
          </div>
        ) : (
          <div className="bg-[#FFD4D0] text-[#FF1600] rounded-md py-1 px-3 uppercase">
            Inactive
          </div>
        ),
    },
    {
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const edit = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          navigate("/subscription/update", {
            state: { id: params.row._id },
          });
        };
        const updateStatus = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          return toast.warning("Disabled For Demo Version!");
          setIsHeaderFeaturedId(params.row._id);
          setIsActive(params.row.status === "active" ? "inactive" : "active");
          setIsConfirmModal(true);
        };
        // const dlt = (e) => {
        //   e.stopPropagation(); // don't select this row after clicking
        //   deleteAdmin(params.row._id);
        // };
        return (
          <>
            {role === "superadmin" && (
              <>
                <button
                  title="Update"
                  type="button"
                  className="text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
                  onClick={edit}
                >
                  <AiOutlineEdit className="text-xl" />
                </button>
                <button
                  title={params.row.status === "active" ? "Inactive" : "Active"}
                  type="button"
                  className={`text-sm text-white ${
                    params.row.status === "active"
                      ? "bg-red-600"
                      : "bg-green-600"
                  } px-2.5 py-1.5 rounded-full ml-3`}
                  onClick={updateStatus}
                >
                  {params.row.status === "active" ? (
                    <AiOutlineClose className="text-xl" />
                  ) : (
                    <AiOutlineCheck className="text-xl" />
                  )}
                </button>
              </>
            )}
            {/* &nbsp;
            {(role === "superadmin" || permissions.deleteAdmin) && (
              <button
                type="button"
                className="bg-red-600 hover:bg-red-800 text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
                onClick={dlt}
              >
                <AiOutlineDelete className="text-xl" />
              </button>
            )} */}
          </>
        );
      },
    },
  ];
  const handalerConfirm = async () => {
    putRequest(`statusUpdate/${isHeaderFeaturedId}`, {
      status: isActive,
    }).then((res) => {
      if (res.status === 200) {
        toast.success(`This Plan set ${isActive}`);
        setIsConfirmModal(!isConfirmModal);
        setIsUpdateStatus(!isUpdateStatus);
      } else {
        toast.warning("Please try again!");
        setIsConfirmModal(!isConfirmModal);
      }
    });
  };
  return (
    <>
      <Layout>
        {isLoader ? (
          <LottieLoader />
        ) : (
          <div
            className={`tableForUpdate w-full p-8 bg-white overflow-hidden rounded-2xl shadowSection relative min-h-[520px] `}
          >
            <div className="header w-full sm:flex justify-between items-center mb-5">
              <div className="flex space-x-2 items-center mb-2 sm:mb-0">
                <h1 className="text-xl font-bold text-dark-gray tracking-wide">
                  Plan List
                </h1>
              </div>
            </div>
            <div className="relative w-full overflow-x-auto sm:rounded-lg h-[700px]">
              {planList && <DataTable rowList={planList} columns={columns} />}
            </div>
          </div>
        )}
      </Layout>
      {isConfirmModal && (
        <ConfirmModal
          headMessage="Confirm"
          bodyMessage={`Are you sure set ${isActive} this Plan ?`}
          btn1="Confirm"
          btn2="Not Now"
          isMoadl={() => setIsConfirmModal(!isConfirmModal)}
          actionCofirm={() => handalerConfirm()}
        ></ConfirmModal>
      )}
    </>
  );
}
