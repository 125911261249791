export default function useMonthWiseGraph() {
  const setupSellGraph = (data) => {
    const months = [
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december",
    ];
    const graphArray = [];
    months.forEach((gItem) => {
      const index = data.findIndex(
        (item) => item.month.toLowerCase() === gItem.toLowerCase()
      );
      if (index > -1) {
        graphArray.push(data[index].totalSell);
      } else {
        graphArray.push(0);
      }
    });

    return graphArray;
  };
  const setupUserGraph = (data) => {
    const months = [
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december",
    ];
    const graphArray = [];
    months.forEach((gItem) => {
      const index = data.findIndex(
        (item) => item.month.toLowerCase() === gItem.toLowerCase()
      );
      if (index > -1) {
        graphArray.push(data[index].totalUser);
      } else {
        graphArray.push(0);
      }
    });

    return graphArray;
  };
  return { setupSellGraph, setupUserGraph };
}
