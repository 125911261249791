/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
/* eslint-disable no-else-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useRequestFun from "../../../hooks/Axios/useRequestFun";
import InputCom from "../../Helpers/Inputs";
// import SelectBox from "../../Helpers/SelectBox";

export default function EmailSettingsTab({ setIsLoader }) {
  const [protocol, setProtocol] = useState("");
  const [host, setHost] = useState("");
  const [port, setPort] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { getRequest, putRequest } = useRequestFun();
  const [messages, setMessages] = useState("");
  const [settingId, setSettingId] = useState("");
  const [mailType, setMailType] = useState("");

  useEffect(() => {
    getRequest("getemail").then((res) => {
      setProtocol(res.data.data.service);
      setHost(res.data.data.host);
      setPort(res.data.data.port);
      setUsername(res.data.data.email);
      setPassword(res.data.data.appPassword);
      setSettingId(res.data.data._id);
      setMailType(res.data.data.type);
    });
  }, []);

  const emailSettingsUpdate = async (e) => {
    e.preventDefault();
    return toast.warning("Disabled For Demo Version!");
    let isValid = true;
    if (protocol === "") {
      setMessages({ protocol: "Help Line Number is required" });
      isValid = false;
    } else if (host === "") {
      setMessages({ host: "Email is required" });
      isValid = false;
    } else if (port === "") {
      setMessages({ port: "Port is required" });
      isValid = false;
    } else if (username === "") {
      setMessages({ username: "Username is required" });
      isValid = false;
    } else if (password === "") {
      setMessages({ password: "Password is required" });
      isValid = false;
    }
    if (!isValid) {
      return;
    }
    setIsLoader(true);
    putRequest(`updatedemailsetup/${settingId}`, {
      service: protocol,
      host,
      port,
      email: username,
      appPassword: password,
      type: mailType,
    })
      .then((res) => {
        toast.success(`${res.data.message}`, {
          icon: `🙂`,
        });
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        toast.error(`${error.response.data.message}`);
      });
  };
  return (
    <>
      <div className="settings-wrapper w-full relative mb-10">
        <div className="mainWrapper w-full">
          {/* heading */}

          <div className="content-container w-full rounded-2xl bg-white">
            <div className="content-body-items flex-1">
              <form onSubmit={emailSettingsUpdate}>
                <div className="personal-info-tab w-full flex flex-col justify-between">
                  <div className="flex flex-col-reverse sm:flex-row">
                    <div className="flex-1 sm:mr-10">
                      <div className="fields w-full">
                        {/* first name and last name */}
                        <div className="xl:flex xl:space-x-7 mb-6">
                          <div className="field w-full">
                            <InputCom
                              label="Protocol"
                              type="text"
                              name="protocol"
                              inputHandler={(e) => setProtocol(e.target.value)}
                              placeholder="Protocol"
                              value={protocol !== "" ? protocol : ""}
                            />

                            <div className="text-red-500">
                              {messages.protocol}
                            </div>
                          </div>
                          <div className="field w-full">
                            <InputCom
                              label="Host"
                              type="text"
                              name="host"
                              inputHandler={(e) => setHost(e.target.value)}
                              placeholder="Host"
                              value={host !== "" ? host : ""}
                            />
                            <div className="text-red-500">{messages.host}</div>
                          </div>
                        </div>

                        <div className="xl:flex xl:space-x-7 mb-6">
                          <div className="field w-full mb-6 xl:mb-0">
                            <InputCom
                              label="Port"
                              type="text"
                              name="port"
                              inputHandler={(e) => setPort(e.target.value)}
                              placeholder="Port"
                              value={port !== "" ? port : ""}
                            />

                            <div className="text-red-500">{messages.port}</div>
                          </div>

                          <div className="field w-full mb-6 xl:mb-0">
                            <InputCom
                              label="Username"
                              type="text"
                              name="username"
                              inputHandler={(e) => setUsername(e.target.value)}
                              placeholder="Username"
                              value={username !== "" ? username : ""}
                            />

                            <div className="text-red-500">
                              {messages.username}
                            </div>
                          </div>
                        </div>

                        <div className="xl:flex xl:space-x-7 mb-6">
                          <div className="field w-full mb-6 xl:mb-0">
                            <InputCom
                              label="Password"
                              type="text"
                              name="password"
                              inputHandler={(e) => setPassword(e.target.value)}
                              placeholder="Password "
                              value={password !== "" ? password : ""}
                            />
                            <div className="text-red-500">
                              {messages.password}
                            </div>
                          </div>
                          <div className="field w-full mb-6 xl:mb-0">
                            <InputCom
                              label="Type"
                              type="text"
                              name="mailtype"
                              readOnlyValue="true"
                              placeholder="Mail type"
                              value={mailType}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="content-footer w-full">
                    <div className="w-full h-[120px] border-t border-light-purple flex justify-end items-center">
                      <div className="flex space-x-4 mr-9">
                        <button
                          type="submit"
                          className="w-[152px] h-[46px] flex justify-center items-center gradientButton text-base rounded-full text-white"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
