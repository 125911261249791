/* eslint-disable no-underscore-dangle */
// import { Avatar, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
// AiOutlineEdit
import notfOund from "../../assets/images/not-found.svg";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import Layout from "../Partials/Layout";

import DataTable from "../Helpers/DataGrid";
import LottieLoader from "../Helpers/LottieLoader";
// import { logDOM } from "@testing-library/react";

export default function Promotion() {
  const [isLoader, setIsLoader] = useState(true);
  const [collectionlist, setCollectionList] = useState([]);
  // const [anchorEl, setAnchorEl] = useState();
  // const open = Boolean(anchorEl);
  const [networkData, setNetworkData] = useState();
  // const [balance, setBalance] = useState("0");
  const { getRequest } = useRequestFun();
  const getRequests = useCallback(() => {
    getRequest(`getNetWorks`).then(async (response) => {
      if (response.data.data[0]) {
        setNetworkData(response.data.data[0]);

        getRequest("getallpromotionrequest").then((res) => {
          if (res?.data?.data) {
            setCollectionList(res?.data?.data);
          }
        });
      }
      setIsLoader(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRequests();
  }, [getRequests]);

  // const actionCol = !(role === "superadmin" || permissions.promotion);
  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const truncate = (str) =>
    // eslint-disable-next-line prefer-template
    str.length > 10 ? str.substring(0, 6) + "..." + str.slice(-6) : str;

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.name ? `${params.row.name}` : "N/A",
    },
    {
      field: "image",
      headerName: "Image",
      flex: 1,
      renderCell: (params) =>
        params?.row?.image && params?.row?.image !== "" ? (
          <img
            src={params.row.image}
            alt="Collection"
            width={60}
            height={60}
            loading="eager"
          />
        ) : (
          <img src={notfOund} alt="Collection" width={60} />
        ),
    },
    {
      field: "symbol",
      headerName: "Symbol",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.symbol ? params.row.symbol : "N/A",
    },
    {
      field: "contract_address",
      headerName: "Contract",
      flex: 1,
      renderCell: (params) => (
        <a
          href={`${networkData.explorer_url}address/${params.row.contract_address}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {truncate(params.row.contract_address)}
        </a>
      ),
    },
    {
      field: "creator_address",
      headerName: "Creator",
      flex: 1,
      renderCell: (params) => (
        <a
          href={`${networkData.explorer_url}address/${params.row.creator_address}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {truncate(params.row.creator_address)}
        </a>
      ),
    },
    {
      field: "isLazyCollection",
      headerName: "Type",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row?.isLazyCollection ? "Lazy Collection" : "Onchain Collection",
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <>
          <>
            <a
              title="View on NFTO"
              target="blank"
              href={`${process.env.REACT_APP_FRONTEND_URL}/collections/${
                params.row.isLazyCollection
                  ? params?.row?._id
                  : params.row.contract_address
              }`}
            >
              <button
                type="button"
                className="text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
              >
                <AiOutlineEye className="text-xl" />
              </button>
            </a>
          </>
        </>
      ),
    },
  ];
  return (
    <>
      <Layout>
        {isLoader ? (
          <LottieLoader />
        ) : (
          <div
            className={`tableForUpdate w-full p-8 bg-white overflow-hidden rounded-2xl shadowSection relative min-h-[520px] `}
          >
            <div className="header w-full sm:flex justify-between items-center mb-5">
              <div className="flex space-x-2 items-center mb-2 sm:mb-0">
                <h1 className="text-xl font-bold text-dark-gray tracking-wide">
                  Promotion Request List
                </h1>
              </div>
            </div>
            <div className="relative w-full overflow-x-auto sm:rounded-lg h-[800px]">
              {collectionlist && (
                <DataTable rowList={collectionlist} columns={columns} />
              )}
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}
