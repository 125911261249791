/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from "react";
// import { HiInformationCircle } from "react-icons/hi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import InputCom from "../Helpers/Inputs";
import LottieLoader from "../Helpers/LottieLoader";
import RadioInput from "../Helpers/RadioButton";
import SelectBox from "../Helpers/SelectBox";
import Layout from "../Partials/Layout";

export default function UpdateAdmin() {
  const { state } = useLocation();
  //   const [admin, setAdmin] = useState([]);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState();
  const genderData = ["Select Gender", "Male", "Female", "Other's"];
  const [rolData, setRoledata] = useState(["Select Admin Role"]);

  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const { getRequest, putRequest } = useRequestFun();
  const [adminRole, setAdminrole] = useState("");
  const [messages, setMessages] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const roleId = state.id;
  const navigate = useNavigate();
  // const profileImgInput = useRef(null);

  useEffect(() => {
    const getallrole = () => {
      getRequest(`getallrole`).then(async (res) => {
        await Promise.all(
          res.data.data.map((role, index) => {
            setRoledata((prev) => [...prev, role.roleTitle]);
            return index;
          })
        );
      });
    };
    getallrole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const getAdmin = () => {
      getRequest(`getadmininfobyid/${roleId}`).then(async (res) => {
        setFname(res.data.data.first_name);
        setLname(res.data.data.last_name);
        setUserName(res.data.data.username);
        setEmail(res.data.data.email);
        setPhone(res.data.data.phone);
        setGender(res.data.data.gender);
        setAdminrole(res.data.data.role);
        setStatus(res.data.data.status);

        // await Promise.all(
        // //   res.data.data.map((role, index) => {
        // //     setAdmin((prev) => [...prev, role.roleTitle]);
        // //     return index;
        // //   })
        // );
      });
    };
    getAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const browseProfileImg = () => {
  //   profileImgInput.current.click();
  // };
  // const profileImgChangHandler = (e) => {
  //   if (e.target.value !== "") {
  //     const imgReader = new FileReader();
  //     imgReader.onload = (event) => {
  //       setProfileImg(event.target.result);
  //     };
  //     imgReader.readAsDataURL(e.target.files[0]);
  //   }
  // };
  // cover img
  const updateAdmin = async (e) => {
    // Success
    e.preventDefault();
    return toast.warning("Disabled For Demo Version!");

    let isValid = true;
    if (fname === "") {
      setMessages({ fname: "First name is required" });
      isValid = false;
    } else if (lname === "") {
      setMessages({ lname: "Last name is required" });
      isValid = false;
    } else if (gender === "" || gender === "Select Gender") {
      setMessages({ gender: "Gender is required" });
      isValid = false;
    } else if (adminRole === "" || adminRole === "Select Admin Role") {
      setMessages({ role: "Role is required" });
      isValid = false;
    } else if (phone === "") {
      setMessages({ phone: "Phone is required" });
      isValid = false;
    }

    if (!isValid) {
      return;
    }
    setIsLoader(true);
    putRequest(`updateadmininfo/${roleId}`, {
      first_name: fname,
      last_name: lname,
      gender,
      role: adminRole,
      phone,
      status,
    })
      .then((res) => {
        toast.success(`${res.data.message}`, {
          icon: `🙂`,
        });
        navigate("/admin/adminuserlist", { replace: true });
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`);
      });
  };
  return (
    <>
      {isLoader ? (
        <LottieLoader />
      ) : (
        <Layout>
          <div className="settings-wrapper w-full relative mb-10">
            <div className="mainWrapper w-full">
              {/* heading */}

              <div className="content-container w-full rounded-2xl bg-white">
                <div className="content-body-items flex-1 p-10">
                  <div className="heading w-full mb-6">
                    <h1 className="text-26 font-bold text-dark-gray antialiased">
                      Update Admin
                    </h1>
                  </div>
                  <form onSubmit={updateAdmin}>
                    <div className="personal-info-tab w-full flex flex-col justify-between">
                      <div className="flex flex-col-reverse sm:flex-row">
                        <div className="flex-1 sm:mr-10">
                          <div className="fields w-full">
                            {/* first name and last name */}
                            <div className="xl:flex xl:space-x-7 mb-6">
                              <div className="field w-full mb-6 xl:mb-0">
                                <InputCom
                                  label="First Name"
                                  type="text"
                                  name="firstName"
                                  inputHandler={(e) => setFname(e.target.value)}
                                  value={fname}
                                  placeholder="First Name Here"
                                  error={messages.fname ? true : false}
                                />
                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.fname}
                                </small>
                              </div>
                              <div className="field w-full">
                                <InputCom
                                  label="Last Name"
                                  type="text"
                                  name="lastName"
                                  inputHandler={(e) => setLname(e.target.value)}
                                  value={lname}
                                  placeholder="Last Name Here"
                                  error={messages.lname ? true : false}
                                />

                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.lname}
                                </small>
                              </div>
                            </div>
                            <div className="xl:flex xl:space-x-7 mb-6">
                              <div className="field w-full mb-6 xl:mb-0">
                                <InputCom
                                  label="User Name"
                                  type="text"
                                  name="userName"
                                  inputHandler={(e) =>
                                    setUserName(e.target.value)
                                  }
                                  value={userName}
                                  readOnlyValue="true"
                                  placeholder="@example"
                                  error={messages.userName ? true : false}
                                />
                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.userName}
                                </small>
                              </div>
                              <div className="field w-full">
                                <InputCom
                                  label="Email"
                                  type="email"
                                  name="email"
                                  inputHandler={(e) => setEmail(e.target.value)}
                                  value={email}
                                  readOnlyValue="true"
                                  placeholder="admin@example.com"
                                  error={messages.email ? true : false}
                                />
                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.email}
                                </small>
                              </div>
                            </div>
                            {/* User Name */}
                            <div className="xl:flex xl:space-x-7 mb-6">
                              <div className="field w-full mb-6 xl:mb-0">
                                <div className="chart-heading mb-4 xl:flex justify-between items-center">
                                  <SelectBox
                                    label="Gender"
                                    name="Gender"
                                    datas={genderData}
                                    action={(value) => setGender(value)}
                                    selectedValue={gender}
                                    error={messages.gender ? true : false}
                                  />
                                </div>
                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.gender}
                                </small>
                              </div>
                              <div className="field w-full">
                                <div className="chart-heading mb-4 xl:flex justify-between items-center">
                                  <SelectBox
                                    label="Role"
                                    name="Role"
                                    datas={rolData}
                                    action={(value) => setAdminrole(value)}
                                    selectedValue={adminRole}
                                    error={messages.role ? true : false}
                                  />
                                </div>
                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.role}
                                </small>
                              </div>
                            </div>
                            <div className="xl:flex xl:space-x-7 mb-6">
                              <div className="field w-full mb-6 xl:mb-0">
                                <InputCom
                                  label="Phone"
                                  type="text"
                                  name="phone"
                                  inputHandler={(e) => setPhone(e.target.value)}
                                  value={phone}
                                  placeholder="+880124365464"
                                  error={messages.phone ? true : false}
                                />
                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.phone}
                                </small>
                              </div>
                              <div className="field w-full mb-6 xl:mb-0">
                                <RadioInput
                                  title="Active"
                                  label="Status"
                                  name="Active"
                                  radioHandler={() => setStatus("active")}
                                  value={status === "active" ? true : false}
                                />
                                <RadioInput
                                  title="Inactive"
                                  name="Inactive"
                                  radioHandler={() => setStatus("inactive")}
                                  value={status === "inactive" ? true : false}
                                />
                                <RadioInput
                                  title="Suspend"
                                  name="suspend"
                                  radioHandler={() => setStatus("suspend")}
                                  value={status === "suspend" ? true : false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="content-footer w-full">
                        <div className="w-full h-[120px] border-t border-light-purple flex justify-end items-center">
                          <div className="flex space-x-4 mr-9">
                            <Link to="/admin/adminuserlist">
                              <button
                                type="button"
                                className="text-18 text-light-red tracking-wide "
                              >
                                <span className="border-b border-light-red">
                                  Cancel
                                </span>
                              </button>
                            </Link>

                            <button
                              type="submit"
                              className="w-[152px] h-[46px] flex justify-center items-center gradientButton text-base rounded-full text-white"
                            >
                              Update Admin
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}
