import { isExpired } from "react-jwt";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { reset } from "../store/user/UserSlice";

const AuthRoute = ({ redirectPath = "/login", children }) => {
  const { token } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const isExpiredToken = isExpired(token);
  if (isExpiredToken) {
    dispatch(reset());
  }

  const location = useLocation();
  const { isLogin, isNewAdmin } = useSelector((store) => store.user);

  if (isLogin) {
    if (isNewAdmin) {
      return <Navigate to="/update-password" replace />;
    }
    if (!isNewAdmin) {
      if (location.pathname === "/update-password") {
        return <Navigate to="/" replace />;
      }
      return children || <Outlet />;
    }
  }
  return <Navigate to={redirectPath} replace />;
  // return children || <Outlet />;
};

export default AuthRoute;
