/* eslint-disable no-underscore-dangle */
// import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { GridColDef } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import DataTable from "../Helpers/DataGrid";

export default function AllRoleList() {
  const [allRoleList, setAllRoleList] = useState();
  const { getRequest } = useRequestFun();
  const navigate = useNavigate();
  const getRequests = useCallback(() => {
    getRequest("getallrole").then((res) => {
      setAllRoleList(res.data.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getRequests();
  }, [getRequests]);

  const columns: GridColDef[] = [
    { minWidth: 150, field: "roleTitle", headerName: "Name", flex: 1 },
    {
      minWidth: 150,
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) =>
        params.row.status === "active" ? (
          <div className="bg-[#D2FFD4] text-[#0D9F16] rounded-md py-1 px-3 uppercase">
            {params.row.status}
          </div>
        ) : params.row.status === "inactive" ? (
          <div className="bg-[#E9DCFE] text-[#2C0270] rounded-md py-1 px-3 uppercase">
            {params.row.status}
          </div>
        ) : (
          params.row.status === "suspend" && (
            <div className="bg-[#FFD4D0] text-[#FF1600] rounded-md py-1 px-3 uppercase">
              {params.row.status}
            </div>
          )
        ),
    },
    {
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const edit = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          navigate("/update/role", {
            state: { id: params.row._id, title: params.row.roleTitle },
          });
        };

        return (
          <button
            type="button"
            className="text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
            onClick={edit}
          >
            <AiOutlineEdit className="text-xl" />
          </button>
        );
      },
    },
  ];
  return (
    <>
      <div
        className={`tableForUpdate w-full p-8 bg-white overflow-hidden rounded-2xl shadowSection relative min-h-[520px] `}
      >
        <div className="header w-full sm:flex justify-between items-center mb-5">
          <div className="flex space-x-2 items-center mb-2 sm:mb-0">
            <h1 className="text-xl font-bold text-dark-gray tracking-wide">
              Role List
            </h1>
          </div>
        </div>
        <div className="relative w-full overflow-x-auto sm:rounded-lg h-[500px]">
          {allRoleList && <DataTable rowList={allRoleList} columns={columns} />}
        </div>
      </div>
    </>
  );
}
