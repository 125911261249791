import React from "react";

export default function RadioInput({
  title,
  label,
  name,
  radioHandler,
  value,
  required = false,
}) {
  return (
    <>
      <div className="input-com">
        {label && (
          <label
            className="input-label text-dark-gray text-xl font-bold block mb-2.5"
            htmlFor={name}
          >
            {label}
            {required && <small className="text-red-400">*</small>}
          </label>
        )}
        <div className="flex items-center">
          <input
            type="radio"
            id={name}
            className="indeterminate:bg-gray-300 w-5 h-5 mr-3"
            onChange={() => radioHandler(name)}
            checked={value === true ?? false}
          />
          <label htmlFor={name} className="text-lg">
            {title}
          </label>
        </div>
      </div>
    </>
  );
}
