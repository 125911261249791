/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import Layout from "../Partials/Layout";

export default function SupportDetails() {
  const [data, setData] = useState();

  const { id } = useParams();
  const { getRequest } = useRequestFun();
  useEffect(() => {
    getRequest(`getsupportbyid/${id}`).then((res) => {
      setData(res.data.data);
    });
  }, []);

  return (
    <Layout>
      <div className="bg-white rounded-xl p-5 ">
        <div className="flex flex-col gap-6 w-6/12">
          <div className="text-base flex ">
            <div className="w-2/12 text-gray-500">Created At:</div>
            <div className="w-10/12 font-semibold">
              {new Date(data?.created_at).toLocaleString()}
            </div>
          </div>
          <div className="text-base flex ">
            <div className="w-2/12 text-gray-500">Name:</div>
            <div className="w-10/12 font-semibold">{data?.name}</div>
          </div>
          <div className="text-base flex ">
            <div className="w-2/12 text-gray-500">Email:</div>
            <div className="w-10/12 font-semibold">{data?.email}</div>
          </div>
          <div className="text-base flex ">
            <div className="w-2/12 text-gray-500">Phone:</div>
            <div className="w-10/12 font-semibold">{data?.phone}</div>
          </div>
        </div>
        <div className="py-5 text-base flex flex-col">
          <div className="text-gray-500">Message:</div>
          <div>{data?.details}</div>
        </div>
      </div>
    </Layout>
  );
}
