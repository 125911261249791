import { GridColDef } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import notfOund from "../../assets/images/not-found.svg";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import Layout from "../Partials/Layout";

import DataTable from "../Helpers/DataGrid";
import LottieLoader from "../Helpers/LottieLoader";
// import { logDOM } from "@testing-library/react";

export default function ActiveBids() {
  const [isLoader, setIsLoader] = useState(true);
  const [auctionlist, setAuctionList] = useState([]);
  const [networkData, setNetworkData] = useState();
  // const [balance, setBalance] = useState("0");
  const { getRequest } = useRequestFun();
  const getRequests = useCallback(() => {
    getRequest(`getNetWorks`).then(async (response) => {
      if (response.data.data[0]) {
        setNetworkData(response.data.data[0]);

        getRequest("getactivebid").then((res) => {
          if (res?.data?.data) {
            setAuctionList(res?.data?.data);
          }
        });
      }
      setIsLoader(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRequests();
  }, [getRequests]);

  const truncate = (str) =>
    // eslint-disable-next-line prefer-template
    str.length > 10 ? str.substring(0, 3) + "..." + str.slice(-3) : str;

  const columns: GridColDef[] = [
    {
      minWidth: 150,
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        // eslint-disable-next-line no-unused-expressions
        <a
          href={`nfts/${params.row.contract_address}/${params.row.token_id}`}
          rel="noreferrer"
          className="hover:text-blue-400"
        >
          {params.row.name ? `${params.row.name}` : "N/A"}
        </a>
      ),
    },
    {
      minWidth: 150,
      field: "image",
      headerName: "Image",
      flex: 1,
      renderCell: (params) =>
        params?.row?.image !== "" ? (
          <a
            href={`nfts/${params.row.contract_address}/${params.row.token_id}`}
            rel="noreferrer"
            className="hover:text-blue-400"
          >
            <img src={params?.row?.image} alt="Nft" width={100} />
          </a>
        ) : (
          <img src={notfOund} alt="Collection" width={100} />
        ),
    },
    {
      minWidth: 150,
      field: "contract_address",
      headerName: "Contract",
      flex: 1,
      renderCell: (params) => (
        <a
          href={`${networkData.explorer_url}address/${params.row.contract_address}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {truncate(params.row.contract_address)}
        </a>
      ),
    },
    {
      minWidth: 150,
      field: "creator_address",
      headerName: "Creator",
      flex: 1,
      renderCell: (params) =>
        params?.row?.creator_address ? (
          <a
            href={`${networkData.explorer_url}address/${params?.row?.creator_address}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {truncate(params?.row?.creator_address)}
          </a>
        ) : (
          "Unknown"
        ),
    },
    {
      minWidth: 150,
      field: "owner",
      headerName: "Owner",
      flex: 1,
      renderCell: (params) => (
        <a
          href={`${networkData.explorer_url}address/${params?.row?.owner.wallet_address}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {truncate(params?.row?.owner.wallet_address)}
        </a>
      ),
    },
    {
      minWidth: 150,
      field: "buyer",
      headerName: "Bidder",
      flex: 1,
      renderCell: (params) => (
        <a
          href={`${networkData.explorer_url}address/${params?.row?.buyer}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {truncate(params?.row?.buyer)}
        </a>
      ),
    },
    {
      minWidth: 150,
      field: "price",
      headerName: "Bidding Amount",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.price ? `${params.row.price} ${networkData.symbol}` : "N/A",
    },
    {
      minWidth: 150,
      field: "creator_fees",
      headerName: "Creator Fees",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.price
          ? `${Number(params.row.creator_fees).toFixed(5)} ${
              networkData.symbol
            }`
          : "N/A",
    },
    {
      minWidth: 150,
      field: "buyFees",
      headerName: "System Fees",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.price
          ? `${Number(params.row.buyFees).toFixed(5)} ${networkData.symbol}`
          : "N/A",
    },
    {
      minWidth: 150,
      field: "sellerEarnings",
      headerName: "Seller Earning",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.price
          ? `${Number(params.row.sellerEarnings).toFixed(5)} ${
              networkData.symbol
            }`
          : "N/A",
    },
  ];

  return (
    <>
      <Layout>
        {isLoader ? (
          <LottieLoader />
        ) : (
          <div
            className={`tableForUpdate w-full p-8 bg-white overflow-hidden rounded-2xl shadowSection relative min-h-[520px] `}
          >
            <div className="header w-full sm:flex justify-between items-center mb-5">
              <div className="flex space-x-2 items-center mb-2 sm:mb-0">
                <h1 className="text-xl font-bold text-dark-gray tracking-wide">
                  Active Bid List
                </h1>
              </div>
            </div>
            <div className="relative w-full overflow-x-auto sm:rounded-lg h-[800px]">
              {auctionlist && (
                <DataTable rowList={auctionlist} columns={columns} />
              )}
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}
