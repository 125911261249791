/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/extensions */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import "suneditor/dist/css/suneditor.min.css";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// Require Editor JS files.
import { toast } from "react-toastify";
import DefaultImg from "../../assets/images/auth-profile-picture.png";
import Layout from "../../components/Partials/Layout";

import useRequestFun from "../../hooks/Axios/useRequestFun";
import useFileUpload from "../../hooks/useFileUpload";

// Require Editor CSS files.

import Editor from "../../components/Editor/Editor";
import InputCom from "../../components/Helpers/Inputs";
import LottieLoader from "../../components/Helpers/LottieLoader";
import SecondSelect from "../../components/Helpers/SelectBox/SecondSelect";

export default function AddProduct() {
  const [isLoader, setIsLoader] = useState(false);
  const [title, setTitle] = useState("");
  //   const [metaTitle, setMetaTitle] = useState("");
  //   const [status, setStatus] = useState("active");
  //   const [metaDescription, setMetaDescription] = useState("");
  const [categories, setCategories] = useState([
    { name: "Select category", id: "" },
  ]);
  const [shortDescription, setShortDescription] = useState("");
  const [blogDescription, setBlogDescription] = useState("");
  const [bigImageFile1, setBigImageFile1] = useState("");
  const [bigImageFile2, setBigImageFile2] = useState("");
  const [bigImageFile3, setBigImageFile3] = useState("");
  const [bigImageFile4, setBigImageFile4] = useState("");
  const [thumb, setThumb] = useState("");
  const [mimeType, setMimeType] = useState("");
  const [mimeTypeBig1, setMimeTypeBig1] = useState("");
  const [mimeTypeBig2, setMimeTypeBig2] = useState("");
  const [mimeTypeBig3, setMimeTypeBig3] = useState("");
  const [mimeTypeBig4, setMimeTypeBig4] = useState("");
  const [quantity, setQuantity] = useState("");
  const [usdPrice, setUsdPrice] = useState("");
  const [discountUsdPrice, setDiscountUsdPrice] = useState("");
  const [ethPrice, setEthPrice] = useState("");
  const [discountEthPrice, setDiscountEthPrice] = useState("");

  const { postRequest, getRequest } = useRequestFun();
  const { fileUpload } = useFileUpload();
  const navigate = useNavigate();
  const [messages, setMessage] = useState({
    title: "",
    blogDescription: "",
    image: "",
    thumbnailImage: "",
    quantity: "",
    price: "",
  });
  const [catValue, setCateValue] = useState({
    name: "Select category",
    id: "",
  });
  // const [status] = useState([
  //   {
  //     _id: 1,
  //     name: "Active",
  //     value: "active",
  //   },
  //   {
  //     _id: 2,
  //     name: "Inactive",
  //     value: "inactive",
  //   },
  // ]);
  useEffect(() => {
    getRequest("getallactiveproductcategory").then((catResult) => {
      if (catResult.data.data.length > 0) {
        setCategories(catResult.data.data);
      }
    });
  }, []);

  const [bigImg1, setBigImg1] = useState();
  const [bigImg2, setBigImg2] = useState();
  const [bigImg3, setBigImg3] = useState();
  const [bigImg4, setBigImg4] = useState();
  const [thumbnailImg, setThumbnailImg] = useState();
  // const [image, setImage] = useState();
  const bigImgInput1 = useRef(null);
  const bigImgInput2 = useRef(null);
  const bigImgInput3 = useRef(null);
  const bigImgInput4 = useRef(null);
  const thumbnailImgInput = useRef(null);
  const imageMimeType = /image\/(png|jpg|jpeg)/i;

  const browseBigImg1 = () => {
    bigImgInput1.current.click();
  };
  const browseBigImg2 = () => {
    bigImgInput2.current.click();
  };
  const browseBigImg3 = () => {
    bigImgInput3.current.click();
  };
  const browseBigImg4 = () => {
    bigImgInput4.current.click();
  };
  const BigImgChangHandler1 = (e) => {
    if (e.target.value !== "") {
      const imgReader = new FileReader();
      if (e.target.files[0].size > 5000000) {
        setMessage({ image: "Image Size Not More Than 5MB" });
        return;
      }
      setMessage({ image: "" });

      if (!e.target.files[0].type.match(imageMimeType)) {
        setMessage({ image: "Image mime type is not valid" });
        return;
      }
      setMessage({ image: "" });
      setMimeTypeBig1(e.target?.files[0]?.type);
      setBigImageFile1(e.target.files[0]);
      imgReader.onload = (event) => {
        setBigImg1(event.target.result);
      };
      imgReader.readAsDataURL(e.target.files[0]);
    }
  };
  const BigImgChangHandler2 = (e) => {
    if (e.target.value !== "") {
      const imgReader = new FileReader();
      if (e.target.files[0].size > 5000000) {
        setMessage({ image: "Image Size Not More Than 5MB" });
        return;
      }
      setMessage({ image: "" });

      if (!e.target.files[0].type.match(imageMimeType)) {
        setMessage({ image: "Image mime type is not valid" });
        return;
      }
      setMessage({ image: "" });
      setMimeTypeBig2(e.target?.files[0]?.type);
      setBigImageFile2(e.target.files[0]);
      imgReader.onload = (event) => {
        setBigImg2(event.target.result);
      };
      imgReader.readAsDataURL(e.target.files[0]);
    }
  };
  const BigImgChangHandler3 = (e) => {
    if (e.target.value !== "") {
      const imgReader = new FileReader();
      if (e.target.files[0].size > 5000000) {
        setMessage({ image: "Image Size Not More Than 5MB" });
        return;
      }
      setMessage({ image: "" });

      if (!e.target.files[0].type.match(imageMimeType)) {
        setMessage({ image: "Image mime type is not valid" });
        return;
      }
      setMessage({ image: "" });
      setMimeTypeBig3(e.target?.files[0]?.type);
      setBigImageFile3(e.target.files[0]);
      imgReader.onload = (event) => {
        setBigImg3(event.target.result);
      };
      imgReader.readAsDataURL(e.target.files[0]);
    }
  };
  const BigImgChangHandler4 = (e) => {
    if (e.target.value !== "") {
      const imgReader = new FileReader();
      if (e.target.files[0].size > 5000000) {
        setMessage({ image: "Image Size Not More Than 5MB" });
        return;
      }
      setMessage({ image: "" });

      if (!e.target.files[0].type.match(imageMimeType)) {
        setMessage({ image: "Image mime type is not valid" });
        return;
      }
      setMessage({ image: "" });
      setMimeTypeBig4(e.target?.files[0]?.type);
      setBigImageFile4(e.target.files[0]);
      imgReader.onload = (event) => {
        setBigImg4(event.target.result);
      };
      imgReader.readAsDataURL(e.target.files[0]);
    }
  };

  const browsThumbnailImg = () => {
    thumbnailImgInput.current.click();
  };

  const thumbnailImgChangHandler = (e) => {
    if (e.target.value !== "") {
      const imgReader = new FileReader();
      if (e.target.files[0].size > 5000000) {
        setMessage({ image: "Image Size Not More Than 5MB" });
        return;
      }
      setMessage({ image: "" });

      if (!e.target.files[0].type.match(imageMimeType)) {
        setMessage({ image: "Image mime type is not valid" });
        return;
      }
      setMessage({ image: "" });
      setMimeType(e.target?.files[0]?.type);
      setThumb(e.target.files[0]);
      imgReader.onload = (event) => {
        setThumbnailImg(event.target.result);
      };
      imgReader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    return toast.warning("Disabled For Demo Version!");
    let isValid = true;
    if (title === "") {
      setMessage({ title: "Title is required" });
      isValid = false;
    } else if (catValue.id === "" || catValue.id === "undefined") {
      setMessage({ category: "Category is required" });
      isValid = false;
    } else if (quantity === "") {
      setMessage({ quantity: "Quantity is required" });
      isValid = false;
    } else if (usdPrice === "") {
      setMessage({ usdPrice: "USD Price is required" });
      isValid = false;
    } else if (discountUsdPrice === "") {
      setMessage({ discountUsdPrice: "Discount USD Price is required" });
      isValid = false;
    } else if (ethPrice === "") {
      setMessage({ ethPrice: "ETH Price is required" });
      isValid = false;
    } else if (discountEthPrice === "") {
      setMessage({ discountEthPrice: "Discount ETH Price is required" });
      isValid = false;
    } else if (shortDescription === "") {
      setMessage({ shortDescription: "Short Description is required" });
      isValid = false;
    } else if (blogDescription === "") {
      setMessage({ blogDescription: "Description is required" });
      isValid = false;
    } else if (bigImageFile1 === "") {
      setMessage({ image: "Big Image is required" });
      isValid = false;
    } else if (bigImageFile2 === "") {
      setMessage({ image: "Big Image is required" });
      isValid = false;
    } else if (bigImageFile3 === "") {
      setMessage({ image: "Big Image is required" });
      isValid = false;
    } else if (bigImageFile4 === "") {
      setMessage({ image: "Big Image is required" });
      isValid = false;
    } else if (thumb === "") {
      setMessage({ thumbnailImage: "Image is required" });
      isValid = false;
    } else {
      setMessage({});
    }
    let fileUrl1 = "";
    let fileUrl2 = "";
    let fileUrl3 = "";
    let fileUrl4 = "";
    if (bigImageFile1) {
      fileUrl1 = await fileUpload(bigImageFile1, "admin", mimeTypeBig1);
    }
    if (bigImageFile2) {
      fileUrl2 = await fileUpload(bigImageFile2, "admin", mimeTypeBig2);
    }
    if (bigImageFile3) {
      fileUrl3 = await fileUpload(bigImageFile3, "admin", mimeTypeBig3);
    }
    if (bigImageFile4) {
      fileUrl4 = await fileUpload(bigImageFile4, "admin", mimeTypeBig4);
    }
    let thumbUrl = "";
    if (thumb) {
      thumbUrl = await fileUpload(thumb, "admin", mimeType);
    }
    if (isValid) {
      setIsLoader(true);
      postRequest("addproduct", {
        name: title,
        productImage: {
          image_1: fileUrl1,
          image_2: fileUrl2,
          image_3: fileUrl3,
          image_4: fileUrl4,
        },
        thumbImage: thumbUrl,
        description: blogDescription.editorHtml,
        quantity,
        ethPrice,
        discountEthPrice,
        usdPrice,
        discountUsdPrice,
        catValue,
        shortDescription,
        // metaTitle,
        // metaDescription,
      })
        .then((res) => {
          toast.success(res.data.message);
          setIsLoader(false);
          navigate("/products");
        })
        .catch((error) => {
          toast.warning(error.response.data.message);
          setIsLoader(false);
        });
    }
  };
  return (
    <>
      <Layout>
        {isLoader ? (
          <LottieLoader />
        ) : (
          <div className="settings-wrapper w-full relative mb-10">
            <div className="mainWrapper w-full">
              <div className="content-container w-full rounded-2xl bg-white">
                <div className="content-body-items flex-1 p-10">
                  <div className="heading w-full mb-6">
                    <h1 className="text-26 font-bold text-dark-gray antialiased">
                      Add Product
                    </h1>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="personal-info-tab w-full flex flex-col justify-between">
                      <div className="flex flex-col-reverse sm:flex-row">
                        <div className="flex-1 sm:mr-10">
                          <div className="fields w-full">
                            {/* first name and last name */}
                            <div className="xl:flex flex-col gap-6 mb-6">
                              <div className="field w-full mb-6 xl:mb-0">
                                <InputCom
                                  label="Title"
                                  type="text"
                                  name="title"
                                  inputHandler={(e) => setTitle(e.target.value)}
                                  value={title}
                                  placeholder="Enter Blog Title Here"
                                  //   error={messages.name ? true : false}
                                />
                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.title}
                                </small>
                              </div>

                              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="field w-full mb-4 xl:mb-0">
                                  <SecondSelect
                                    label="Category"
                                    name="category"
                                    datas={categories}
                                    action={(name, id) =>
                                      setCateValue({ name, id })
                                    }
                                    selectedValue={catValue.name}
                                    // error={!!errorMessage.status}
                                  />
                                  <small className="text-red-400 ml-6 italic font-bold">
                                    {messages.category}
                                  </small>
                                </div>
                                <div className="field w-full mb-4 xl:mb-0">
                                  <InputCom
                                    label="Quantity"
                                    type="text"
                                    name="metaTitle"
                                    inputHandler={(e) =>
                                      setQuantity(e.target.value)
                                    }
                                    value={quantity}
                                    placeholder="Enter Quantity"
                                  />
                                  <small className="text-red-400 ml-6 italic font-bold">
                                    {messages.quantity}
                                  </small>
                                </div>
                                {/* <div className="field w-full mb-4 xl:mb-0">
                              <InputCom
                                label="Price"
                                type="text"
                                name="metaTitle"
                                inputHandler={(e) => setPrice(e.target.value)}
                                value={price}
                                notRequired
                                placeholder="Enter Price"
                                //   error={messages.name ? true : false}
                              />
                              <small className="text-red-400 ml-6 italic font-bold">
                                {messages.price}
                              </small>
                            </div> */}
                              </div>
                              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                                <div className="field w-full mb-4 xl:mb-0">
                                  <InputCom
                                    label="USD Price"
                                    type="text"
                                    name="usdPrice"
                                    inputHandler={(e) =>
                                      setUsdPrice(e.target.value)
                                    }
                                    value={usdPrice}
                                    placeholder="Enter Price"
                                  />
                                  <small className="text-red-400 ml-6 italic font-bold">
                                    {messages.usdPrice}
                                  </small>
                                </div>
                                <div className="field w-full mb-4 xl:mb-0">
                                  <InputCom
                                    label="Discount USD Price"
                                    type="text"
                                    name="discountUsdPrice"
                                    inputHandler={(e) =>
                                      setDiscountUsdPrice(e.target.value)
                                    }
                                    value={discountUsdPrice}
                                    placeholder="Enter Price"
                                    //   error={messages.name ? true : false}
                                  />
                                  <small className="text-red-400 ml-6 italic font-bold">
                                    {messages.discountUsdPrice}
                                  </small>
                                </div>
                                <div className="field w-full mb-4 xl:mb-0">
                                  <InputCom
                                    label="ETH Price"
                                    type="text"
                                    name="ethPrice"
                                    inputHandler={(e) =>
                                      setEthPrice(e.target.value)
                                    }
                                    value={ethPrice}
                                    placeholder="Enter Price"
                                  />
                                  <small className="text-red-400 ml-6 italic font-bold">
                                    {messages.ethPrice}
                                  </small>
                                </div>
                                <div className="field w-full mb-4 xl:mb-0">
                                  <InputCom
                                    label="Discount ETH Price"
                                    type="text"
                                    name="discountEthPrice"
                                    inputHandler={(e) =>
                                      setDiscountEthPrice(e.target.value)
                                    }
                                    value={discountEthPrice}
                                    placeholder="Enter Price"
                                    //   error={messages.name ? true : false}
                                  />
                                  <small className="text-red-400 ml-6 italic font-bold">
                                    {messages.discountEthPrice}
                                  </small>
                                </div>
                              </div>
                              <div className="field w-full mb-6 xl:mb-0">
                                <label
                                  className="input-label text-dark-gray text-xl font-bold block mb-2.5"
                                  htmlFor="metaDescription"
                                >
                                  Short Description
                                  <small className="text-red-400">*</small>
                                </label>
                                <textarea
                                  value={shortDescription}
                                  onChange={(e) => {
                                    setShortDescription(e.target.value);
                                  }}
                                  placeholder="Enter Short Description"
                                  rows="7"
                                  className="w-full h-full px-7 py-4  border border-light-purple rounded-[20px] text-dark-gray bg-[#FAFAFA] focus:ring-0 focus:outline-none"
                                />
                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.shortDescription}
                                </small>
                              </div>
                              <div className="field w-full">
                                <label
                                  className="input-label text-dark-gray text-xl font-bold block mb-2.5"
                                  htmlFor="metaDescription"
                                >
                                  Product Description
                                  <small className="text-red-400">*</small>
                                </label>
                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.blogDescription}
                                </small>
                                <Editor
                                  blogDescription={(e) => setBlogDescription(e)}
                                />
                              </div>
                            </div>
                            <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
                              <div className="update-profile w-full md:mb-9 p-3 col-span-2">
                                <h1 className="text-xl tracking-wide font-bold text-dark-gray flex items-center mb-2">
                                  Big Image
                                  <small className="text-red-400">*</small>
                                </h1>
                                <p className="text-base text-thin-light-gray mb-5">
                                  {/* Profile of at least Size
                          <span className="ml-1 text-dark-gray">300x300</span>. */}
                                  PNG, JPG or JPEG only
                                  <span className="ml-1 text-dark-gray">
                                    Max 5mb
                                  </span>
                                </p>
                                <div className="flex gap-4 justify-left">
                                  <div className="relative">
                                    {bigImg1 ? (
                                      <img
                                        src={bigImg1}
                                        alt=""
                                        className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] overflow-hidden object-contain profile_image"
                                      />
                                    ) : (
                                      <img
                                        src={DefaultImg}
                                        alt=""
                                        className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] overflow-hidden object-contain profile_image"
                                      />
                                    )}

                                    {/* <Svg
                            url={logo}
                            className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] rounded-full overflow-hidden object-cover profile_image"
                          /> */}
                                    <input
                                      ref={bigImgInput1}
                                      onChange={(e) => BigImgChangHandler1(e)}
                                      type="file"
                                      className="hidden"
                                    />
                                    <div
                                      onClick={browseBigImg1}
                                      className="w-[32px] h-[32px] absolute bottom-7 right-0 hover:bg-pink bg-dark-gray rounded-full cursor-pointer"
                                    >
                                      <svg
                                        width="32"
                                        height="32"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.5147 11.5C17.7284 12.7137 18.9234 13.9087 20.1296 15.115C19.9798 15.2611 19.8187 15.4109 19.6651 15.5683C17.4699 17.7635 15.271 19.9587 13.0758 22.1539C12.9334 22.2962 12.7948 22.4386 12.6524 22.5735C12.6187 22.6034 12.5663 22.6296 12.5213 22.6296C11.3788 22.6334 10.2362 22.6297 9.09365 22.6334C9.01498 22.6334 9 22.6034 9 22.536C9 21.4009 9 20.2621 9.00375 19.1271C9.00375 19.0746 9.02997 19.0109 9.06368 18.9772C10.4123 17.6249 11.7609 16.2763 13.1095 14.9277C14.2295 13.8076 15.3459 12.6913 16.466 11.5712C16.4884 11.5487 16.4997 11.5187 16.5147 11.5Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M20.9499 14.2904C19.7436 13.0842 18.5449 11.8854 17.3499 10.6904C17.5634 10.4694 17.7844 10.2446 18.0054 10.0199C18.2639 9.76139 18.5261 9.50291 18.7884 9.24443C19.118 8.91852 19.5713 8.91852 19.8972 9.24443C20.7251 10.0611 21.5492 10.8815 22.3771 11.6981C22.6993 12.0165 22.7105 12.4698 22.3996 12.792C21.9238 13.2865 21.4443 13.7772 20.9686 14.2717C20.9648 14.2792 20.9536 14.2867 20.9499 14.2904Z"
                                          fill="white"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="relative">
                                    {bigImg2 ? (
                                      <img
                                        src={bigImg2}
                                        alt=""
                                        className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] overflow-hidden object-contain profile_image"
                                      />
                                    ) : (
                                      <img
                                        src={DefaultImg}
                                        alt=""
                                        className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] overflow-hidden object-contain profile_image"
                                      />
                                    )}

                                    {/* <Svg
                            url={logo}
                            className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] rounded-full overflow-hidden object-cover profile_image"
                          /> */}
                                    <input
                                      ref={bigImgInput2}
                                      onChange={(e) => BigImgChangHandler2(e)}
                                      type="file"
                                      className="hidden"
                                    />
                                    <div
                                      onClick={browseBigImg2}
                                      className="w-[32px] h-[32px] absolute bottom-7 right-0 hover:bg-pink bg-dark-gray rounded-full cursor-pointer"
                                    >
                                      <svg
                                        width="32"
                                        height="32"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.5147 11.5C17.7284 12.7137 18.9234 13.9087 20.1296 15.115C19.9798 15.2611 19.8187 15.4109 19.6651 15.5683C17.4699 17.7635 15.271 19.9587 13.0758 22.1539C12.9334 22.2962 12.7948 22.4386 12.6524 22.5735C12.6187 22.6034 12.5663 22.6296 12.5213 22.6296C11.3788 22.6334 10.2362 22.6297 9.09365 22.6334C9.01498 22.6334 9 22.6034 9 22.536C9 21.4009 9 20.2621 9.00375 19.1271C9.00375 19.0746 9.02997 19.0109 9.06368 18.9772C10.4123 17.6249 11.7609 16.2763 13.1095 14.9277C14.2295 13.8076 15.3459 12.6913 16.466 11.5712C16.4884 11.5487 16.4997 11.5187 16.5147 11.5Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M20.9499 14.2904C19.7436 13.0842 18.5449 11.8854 17.3499 10.6904C17.5634 10.4694 17.7844 10.2446 18.0054 10.0199C18.2639 9.76139 18.5261 9.50291 18.7884 9.24443C19.118 8.91852 19.5713 8.91852 19.8972 9.24443C20.7251 10.0611 21.5492 10.8815 22.3771 11.6981C22.6993 12.0165 22.7105 12.4698 22.3996 12.792C21.9238 13.2865 21.4443 13.7772 20.9686 14.2717C20.9648 14.2792 20.9536 14.2867 20.9499 14.2904Z"
                                          fill="white"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="relative">
                                    {bigImg3 ? (
                                      <img
                                        src={bigImg3}
                                        alt=""
                                        className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] overflow-hidden object-contain profile_image"
                                      />
                                    ) : (
                                      <img
                                        src={DefaultImg}
                                        alt=""
                                        className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] overflow-hidden object-contain profile_image"
                                      />
                                    )}

                                    {/* <Svg
                            url={logo}
                            className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] rounded-full overflow-hidden object-cover profile_image"
                          /> */}
                                    <input
                                      ref={bigImgInput3}
                                      onChange={(e) => BigImgChangHandler3(e)}
                                      type="file"
                                      className="hidden"
                                    />
                                    <div
                                      onClick={browseBigImg3}
                                      className="w-[32px] h-[32px] absolute bottom-7 right-0 hover:bg-pink bg-dark-gray rounded-full cursor-pointer"
                                    >
                                      <svg
                                        width="32"
                                        height="32"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.5147 11.5C17.7284 12.7137 18.9234 13.9087 20.1296 15.115C19.9798 15.2611 19.8187 15.4109 19.6651 15.5683C17.4699 17.7635 15.271 19.9587 13.0758 22.1539C12.9334 22.2962 12.7948 22.4386 12.6524 22.5735C12.6187 22.6034 12.5663 22.6296 12.5213 22.6296C11.3788 22.6334 10.2362 22.6297 9.09365 22.6334C9.01498 22.6334 9 22.6034 9 22.536C9 21.4009 9 20.2621 9.00375 19.1271C9.00375 19.0746 9.02997 19.0109 9.06368 18.9772C10.4123 17.6249 11.7609 16.2763 13.1095 14.9277C14.2295 13.8076 15.3459 12.6913 16.466 11.5712C16.4884 11.5487 16.4997 11.5187 16.5147 11.5Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M20.9499 14.2904C19.7436 13.0842 18.5449 11.8854 17.3499 10.6904C17.5634 10.4694 17.7844 10.2446 18.0054 10.0199C18.2639 9.76139 18.5261 9.50291 18.7884 9.24443C19.118 8.91852 19.5713 8.91852 19.8972 9.24443C20.7251 10.0611 21.5492 10.8815 22.3771 11.6981C22.6993 12.0165 22.7105 12.4698 22.3996 12.792C21.9238 13.2865 21.4443 13.7772 20.9686 14.2717C20.9648 14.2792 20.9536 14.2867 20.9499 14.2904Z"
                                          fill="white"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="relative">
                                    {bigImg4 ? (
                                      <img
                                        src={bigImg4}
                                        alt=""
                                        className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] overflow-hidden object-contain profile_image"
                                      />
                                    ) : (
                                      <img
                                        src={DefaultImg}
                                        alt=""
                                        className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] overflow-hidden object-contain profile_image"
                                      />
                                    )}

                                    {/* <Svg
                            url={logo}
                            className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] rounded-full overflow-hidden object-cover profile_image"
                          /> */}
                                    <input
                                      ref={bigImgInput4}
                                      onChange={(e) => BigImgChangHandler4(e)}
                                      type="file"
                                      className="hidden"
                                    />
                                    <div
                                      onClick={browseBigImg4}
                                      className="w-[32px] h-[32px] absolute bottom-7 right-0 hover:bg-pink bg-dark-gray rounded-full cursor-pointer"
                                    >
                                      <svg
                                        width="32"
                                        height="32"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.5147 11.5C17.7284 12.7137 18.9234 13.9087 20.1296 15.115C19.9798 15.2611 19.8187 15.4109 19.6651 15.5683C17.4699 17.7635 15.271 19.9587 13.0758 22.1539C12.9334 22.2962 12.7948 22.4386 12.6524 22.5735C12.6187 22.6034 12.5663 22.6296 12.5213 22.6296C11.3788 22.6334 10.2362 22.6297 9.09365 22.6334C9.01498 22.6334 9 22.6034 9 22.536C9 21.4009 9 20.2621 9.00375 19.1271C9.00375 19.0746 9.02997 19.0109 9.06368 18.9772C10.4123 17.6249 11.7609 16.2763 13.1095 14.9277C14.2295 13.8076 15.3459 12.6913 16.466 11.5712C16.4884 11.5487 16.4997 11.5187 16.5147 11.5Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M20.9499 14.2904C19.7436 13.0842 18.5449 11.8854 17.3499 10.6904C17.5634 10.4694 17.7844 10.2446 18.0054 10.0199C18.2639 9.76139 18.5261 9.50291 18.7884 9.24443C19.118 8.91852 19.5713 8.91852 19.8972 9.24443C20.7251 10.0611 21.5492 10.8815 22.3771 11.6981C22.6993 12.0165 22.7105 12.4698 22.3996 12.792C21.9238 13.2865 21.4443 13.7772 20.9686 14.2717C20.9648 14.2792 20.9536 14.2867 20.9499 14.2904Z"
                                          fill="white"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                <div className="text-red-500">
                                  {messages.image}
                                </div>
                              </div>

                              <div className="update-profile w-full mb-9 col-span-1 p-3">
                                <h1 className="text-xl tracking-wide font-bold text-dark-gray flex items-center mb-2">
                                  Thumbnail Image
                                  <small className="text-red-400">*</small>
                                </h1>
                                <p className="text-base text-thin-light-gray mb-5">
                                  {/* Profile of at least Size
                          <span className="ml-1 text-dark-gray">300x300</span>. */}
                                  PNG, JPG or JPEG only
                                  <span className="ml-1 text-dark-gray">
                                    Max 5mb
                                  </span>
                                  .
                                </p>
                                <div className="flex justify-left">
                                  <div className="relative">
                                    {thumbnailImg ? (
                                      <img
                                        src={thumbnailImg}
                                        alt=""
                                        className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] overflow-hidden object-contain profile_image"
                                      />
                                    ) : (
                                      <img
                                        src={DefaultImg}
                                        alt=""
                                        className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] overflow-hidden object-contain profile_image"
                                      />
                                    )}

                                    {/* <Svg
                            url={logo}
                            className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] rounded-full overflow-hidden object-cover profile_image"
                          /> */}
                                    <input
                                      ref={thumbnailImgInput}
                                      onChange={(e) =>
                                        thumbnailImgChangHandler(e)
                                      }
                                      type="file"
                                      className="hidden"
                                    />
                                    <div
                                      onClick={browsThumbnailImg}
                                      className="w-[32px] h-[32px] absolute bottom-7 right-0 hover:bg-pink bg-dark-gray rounded-full cursor-pointer"
                                    >
                                      <svg
                                        width="32"
                                        height="32"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.5147 11.5C17.7284 12.7137 18.9234 13.9087 20.1296 15.115C19.9798 15.2611 19.8187 15.4109 19.6651 15.5683C17.4699 17.7635 15.271 19.9587 13.0758 22.1539C12.9334 22.2962 12.7948 22.4386 12.6524 22.5735C12.6187 22.6034 12.5663 22.6296 12.5213 22.6296C11.3788 22.6334 10.2362 22.6297 9.09365 22.6334C9.01498 22.6334 9 22.6034 9 22.536C9 21.4009 9 20.2621 9.00375 19.1271C9.00375 19.0746 9.02997 19.0109 9.06368 18.9772C10.4123 17.6249 11.7609 16.2763 13.1095 14.9277C14.2295 13.8076 15.3459 12.6913 16.466 11.5712C16.4884 11.5487 16.4997 11.5187 16.5147 11.5Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M20.9499 14.2904C19.7436 13.0842 18.5449 11.8854 17.3499 10.6904C17.5634 10.4694 17.7844 10.2446 18.0054 10.0199C18.2639 9.76139 18.5261 9.50291 18.7884 9.24443C19.118 8.91852 19.5713 8.91852 19.8972 9.24443C20.7251 10.0611 21.5492 10.8815 22.3771 11.6981C22.6993 12.0165 22.7105 12.4698 22.3996 12.792C21.9238 13.2865 21.4443 13.7772 20.9686 14.2717C20.9648 14.2792 20.9536 14.2867 20.9499 14.2904Z"
                                          fill="white"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                <div className="text-red-500">
                                  {messages.thumbImage}
                                </div>
                              </div>
                            </div>

                            {/* <div className="field w-full mb-6 xl:mb-0">
                          <RadioInput
                            title="Active"
                            label="Status"
                            name="Active"
                            radioHandler={() => setStatus("active")}
                            value={status === "active" ? true : false}
                            required
                          />
                          <RadioInput
                            title="Inactive"
                            name="Inactive"
                            radioHandler={() => setStatus("inactive")}
                            value={status === "inactive" ? true : false}
                          />
                        </div>
                        <small className="text-red-400 ml-6 italic font-bold">
                          {messages.status}
                        </small> */}
                          </div>
                        </div>
                      </div>
                      {/* <div className="xl:flex  mb-4">
                        <HiInformationCircle />
                        <span>
                          After deploying your smart contract, network setting
                          can't update.
                        </span>
                      </div> */}

                      <div className="content-footer w-full">
                        <div className="w-full h-[120px] border-t border-light-purple flex justify-end items-center">
                          <div className="flex space-x-4 mr-9">
                            <Link to="/products">
                              <button
                                type="button"
                                className="text-18 text-light-red tracking-wide "
                              >
                                <span className="border-b border-light-red">
                                  Cancel
                                </span>
                              </button>
                            </Link>

                            <button
                              type="submit"
                              className={`w-[152px] h-[46px] flex justify-center items-center gradientButton text-base rounded-full text-white `}
                              // disabled={isDeployed ? true : false}
                            >
                              Add Product
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}
