/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import CheckBox from "../Helpers/CheckBox";
import InputCom from "../Helpers/Inputs";
import LottieLoader from "../Helpers/LottieLoader";
import Layout from "../Partials/Layout";

export default function UpdateRole() {
  const { state } = useLocation();
  const roleId = state.id;
  const [roleTitle, setRoleTitle] = useState(state.title);
  const [allFeature, setAllFeature] = useState();
  const [userPermissions, setUserPermissions] = useState();
  const [titleError, setTitleError] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const { getRequest, putRequest } = useRequestFun();
  const navigate = useNavigate();

  // const setPermissionObj = (data) => {
  //   data.forEach((item) => {
  //     obj[item.feature] = false;
  //   });
  // };
  useEffect(() => {
    getRequest(`getpermissionbyid/${roleId}`)
      .then((res) => {
        setUserPermissions(res.data.data.permissions);
        // setPermissionObj(res.data.data);
        getRequest(`getallfeature`)
          .then((response) => {
            setAllFeature(response.data.data);
            setIsLoader(false);
            // setPermissionObj(res.data.data);
          })
          .catch(() => {
            setIsLoader(false);
          });
      })
      .catch(() => {
        setIsLoader(false);
      });
  }, []);

  const checkBoxHandler = (name) => {
    let per = _.clone(userPermissions);
    // eslint-disable-next-line no-unused-expressions

    if (name in per === false) {
      per[name] = true;
    } else if (per[name] === false) {
      per[name] = true;
    } else {
      per[name] = false;
    }
    setUserPermissions(per);
  };
  // const titleInputHandler = (event) => {
  //   setRoleTitle(event.target.value);
  // };
  const updateRoleWithPermissions = (e) => {
    e.preventDefault();
    return toast.warning("Disabled For Demo Version!");
    if (roleTitle === "") {
      setTitleError(true);
      return;
    }
    if (roleTitle !== "") {
      setTitleError(false);
    }
    setIsLoader(true);
    putRequest(`updaterolepermission/${roleId}`, {
      title: roleTitle,
      permissions: { ...userPermissions },
    })
      .then((res) => {
        setIsLoader(false);
        toast.success(`${res.data.message}`, {
          icon: `🙂`,
        });

        navigate("/roles");
      })
      .catch((err) => {
        setIsLoader(false);
        toast.success(err.response.data.message);
      });
  };
  return (
    <>
      {isLoader ? (
        <LottieLoader />
      ) : (
        <Layout>
          <div className="settings-wrapper w-full relative mb-10">
            <div className="mainWrapper w-full">
              {/* heading */}

              <div className="content-container w-full rounded-2xl bg-white">
                <div className="content-body-items flex-1 p-10">
                  <div className="heading w-full mb-6">
                    <h1 className="text-26 font-bold text-dark-gray antialiased">
                      Update Role
                    </h1>
                  </div>
                  <div className="personal-info-tab w-full flex flex-col justify-between">
                    <form onSubmit={updateRoleWithPermissions}>
                      <div className="flex flex-col-reverse sm:flex-row">
                        <div className="flex-1 sm:mr-10">
                          <div className="fields w-full">
                            {/* first name and last name */}
                            <div className="xl:flex xl:space-x-7 mb-6">
                              <div className="field w-1/2 mb-6 xl:mb-0">
                                <InputCom
                                  label="Title"
                                  type="text"
                                  name="title"
                                  placeholder="Role Title Here"
                                  inputHandler={(event) =>
                                    setRoleTitle(event.target.value)
                                  }
                                  value={roleTitle}
                                  error={titleError ? true : false}
                                />
                                {titleError && (
                                  <small className="text-red-400 ml-6">
                                    Role Title Required
                                  </small>
                                )}
                              </div>
                            </div>
                            <div>
                              <h1 className="text-26 font-bold text-dark-gray antialiased">
                                Permissions
                              </h1>
                              <div className="features my-5 w-full ">
                                <fieldset className="mb-3 flex border-2 p-4 rounded-lg items-center">
                                  <legend className="text-md italic">
                                    Administrator Permissions
                                  </legend>
                                  <div className="flex flex-wrap gap-3">
                                    {allFeature &&
                                      allFeature.map((item) => {
                                        let result = item.feature.replace(
                                          /([A-Z])/g,
                                          " $1"
                                        );
                                        const finalResult =
                                          result.charAt(0).toUpperCase() +
                                          result.slice(1);
                                        return (
                                          <>
                                            {(item.feature === "addNewAdmin" ||
                                              item.feature ===
                                                "adminUserList" ||
                                              item.feature === "deleteAdmin" ||
                                              item.feature ===
                                                "updateAdminInfo") && (
                                              <CheckBox
                                                name={item.feature}
                                                title={finalResult}
                                                key={item._id}
                                                checkBoxHandler={
                                                  checkBoxHandler
                                                }
                                                value={
                                                  userPermissions[item.feature]
                                                }
                                              />
                                            )}
                                          </>
                                        );
                                      })}
                                  </div>
                                </fieldset>
                                <div className="w-full flex flex-wrap gap-3">
                                  {allFeature &&
                                    allFeature.map((item) => {
                                      let result = item.feature.replace(
                                        /([A-Z])/g,
                                        " $1"
                                      );
                                      const finalResult =
                                        result.charAt(0).toUpperCase() +
                                        result.slice(1);
                                      return (
                                        item.feature !== "addNewAdmin" &&
                                        item.feature !== "adminUserList" &&
                                        item.feature !== "deleteAdmin" &&
                                        item.feature !== "updateAdminInfo" && (
                                          <CheckBox
                                            name={item.feature}
                                            title={finalResult}
                                            key={item._id}
                                            checkBoxHandler={checkBoxHandler}
                                            value={
                                              userPermissions[item.feature]
                                            }
                                          />
                                        )
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="content-footer w-full">
                        <div className="w-full h-[120px] border-t border-light-purple flex justify-end items-center">
                          <div className="flex space-x-4 mr-9">
                            <Link to="/roles">
                              <button
                                type="button"
                                className="text-18 text-light-red tracking-wide "
                              >
                                <span className="border-b border-light-red">
                                  Cancel
                                </span>
                              </button>
                            </Link>

                            <button
                              type="submit"
                              className="w-[152px] h-[46px] flex justify-center items-center gradientButton text-base rounded-full text-white"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}
