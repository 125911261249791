/* eslint-disable no-underscore-dangle */
import { ethers } from "ethers";
import React, { useCallback, useEffect, useState } from "react";
import { AiFillCopy } from "react-icons/ai";
import Lottie from "react-lottie";
import * as Loader from "../../assets/lottie/loader.json";
import wrappedETH from "../../contracts/wrappedETH.json";

export default function WalletInfo({
  networkData,
  walletData,
  copyAddress,
  tokenContractAddress,
  copyAdd,
}) {
  const [balanceData, setBalanceData] = useState();
  const [tokenBalanceData, setTokenBalanceData] = useState();
  const provider = new ethers.providers.JsonRpcProvider(networkData.rpc_url);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const fetchBalance = useCallback(async () => {
    const contract = new ethers.Contract(
      tokenContractAddress,
      wrappedETH.abi,
      provider
    );
    contract.balanceOf(walletData.address).then((balance) => {
      setTokenBalanceData(ethers.utils.formatEther(balance));
    });

    provider.getBalance(walletData.address).then((balance) => {
      setBalanceData(ethers.utils.formatEther(balance));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    provider.on("block", fetchBalance);

    return () => {
      provider.removeAllListeners("block");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchBalance]);

  const truncate = (str) =>
    // eslint-disable-next-line prefer-template
    str.length > 10 ? str.substring(0, 3) + "..." + str.slice(-3) : str;

  return (
    <>
      <div className="personal-info-tab w-full flex flex-col justify-between">
        <div className="w-12/12 content-footer shadow mt-3 rounded-xl">
          <div className="w-full flex justify-center items-center">
            <div className="w-full flex space-x-4 text-xl">
              <ul className="list-inside divide-y divide-slate-200 w-full">
                <li className="flex flex-col sm:flex-row p-3 justify-between">
                  <div className="text-sm">Wallet Address: </div>
                  <span
                    className={`text-sm font-bold flex items-center gap-3 ${
                      copyAdd === "Copied" ? " text-blue-400" : ""
                    }`}
                  >
                    <a
                      href={`${networkData.explorer_url}address/${walletData.address}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {truncate(walletData.address)}
                    </a>
                    <button
                      type="button"
                      className={`w-[28px] h-[28px] flex justify-center items-center gradientButton text-base rounded-full text-white `}
                      onClick={() => copyAddress(walletData.address)}
                    >
                      <AiFillCopy className="text-1xl" />
                    </button>
                  </span>
                </li>
                <li className="flex flex-col sm:flex-row p-3 justify-between">
                  <div className="text-sm">Wallet Balance: </div>
                  <span className="text-sm font-bold">
                    {balanceData ? (
                      JSON.parse(balanceData).toFixed(6)
                    ) : (
                      <div className="w-10 h-10">
                        <Lottie
                          options={defaultOptions}
                          className="object-cover "
                        />
                      </div>
                    )}
                  </span>
                </li>
                <li className="flex flex-col sm:flex-row p-3 justify-between">
                  <div className="text-sm">Wrapped ETH Balance: </div>

                  <span className="text-sm font-bold">
                    {tokenBalanceData ? (
                      JSON.parse(tokenBalanceData).toFixed(6)
                    ) : (
                      <div className="w-10 h-10">
                        <Lottie
                          options={defaultOptions}
                          className="object-cover "
                        />
                      </div>
                    )}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
