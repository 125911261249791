import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Tooltip,
} from "chart.js";
import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import useMonthWiseGraph from "../../hooks/useMonthWiseGraph";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

export default function SellHistoryAnalytic({ sellGraph }) {
  const { setupSellGraph } = useMonthWiseGraph();
  const [graphArray] = useState(setupSellGraph(sellGraph));
  const options = {
    responsive: true,
    barThickness: 7,
    scales: {
      x: {
        grid: {
          display: false,
        },
        gridLines: {
          zeroLineColor: "transparent",
        },
      },

      // y: {
      //   beginAtZero: true,
      //   grid: {
      //     // display: false,
      //     drawBorder: false,
      //     color: "#E5E5E5",
      //     borderDash: [5, 5],
      //     borderDashOffset: 2,
      //     borderWidth: 2,
      //   },
      //   gridLines: {
      //     zeroLineColor: "transparent",
      //   },
      //   ticks: {
      //     callback(value) {
      //       return `${value}% `;
      //     },
      //   },
      // },
    },
    plugins: {
      legend: {
        position: "top",
      },
      //   title: {
      //     display: true,
      //     text: "Chart.js Bar Chart",
      //   },
    },
  };

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Total Sell",
        data: graphArray,
        backgroundColor: "#5356FB",
        borderRadius: 10,
      },
    ],
  };
  return <Bar options={options} data={data} />;
}
