/* eslint-disable no-underscore-dangle */
import { GridColDef } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Partials/Layout";
import useRequestFun from "../../hooks/Axios/useRequestFun";

import DataTable from "../../components/Helpers/DataGrid";
import LottieLoader from "../../components/Helpers/LottieLoader";
// import { logDOM } from "@testing-library/react";

export default function FaqList() {
  const [isLoader, setIsLoader] = useState(true);
  const [blogList, setBlogList] = useState();
  const navigate = useNavigate();
  // const [balance, setBalance] = useState("0");
  const { getRequest } = useRequestFun();
  const getRequests = useCallback(() => {
    getRequest("getallfaq").then((res) => {
      if (res?.data?.data) {
        setBlogList(res?.data?.data);
        setIsLoader(false);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRequests();
  }, [getRequests]);

  const columns: GridColDef[] = [
    {
      minWidth: 150,
      field: "title",
      headerName: "Title",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions

        params.row.title ? `${params.row.title}` : "N/A",
    },
    {
      minWidth: 150,
      field: "status",
      headerName: "Status",
      flex: 1,
      maxWidth: 150,
      renderCell: (params) =>
        params.row.status === "active" ? (
          <div className="bg-[#D2FFD4] text-[#0D9F16] rounded-md py-1 px-3 uppercase">
            {params.row.status}
          </div>
        ) : (
          <div className="bg-[#FFD4D0] text-[#FF1600] rounded-md py-1 px-3 uppercase">
            {params.row.status}
          </div>
        ),
    },
    {
      minWidth: 150,
      field: "order",
      headerName: "Order",
      flex: 1,
      maxWidth: 150,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.order ? `${params.row.order}` : "N/A",
    },
    {
      headerName: "Actions",
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => {
        const edit = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          navigate("/update-faq", {
            state: { id: params.row._id },
          });
        };

        return (
          <>
            <button
              type="button"
              className="text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
              onClick={edit}
            >
              <AiOutlineEdit className="text-xl" />
            </button>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Layout>
        {isLoader ? (
          <LottieLoader />
        ) : (
          <div
            className={`tableForUpdate w-full p-8 bg-white overflow-hidden rounded-2xl shadowSection relative min-h-[520px] `}
          >
            <div className="header w-full sm:flex justify-between items-center mb-5">
              <div className="flex space-x-2 items-center mb-2 sm:mb-0">
                <h1 className="text-xl font-bold text-dark-gray tracking-wide">
                  FAQ List
                </h1>
              </div>
            </div>
            <div className="relative w-full overflow-x-auto sm:rounded-lg h-[800px]">
              {blogList && <DataTable rowList={blogList} columns={columns} />}
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}
