/* eslint-disable no-unused-vars */
import { GridColDef } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { useSelector } from "react-redux";
import notfOund from "../../assets/images/not-found.svg";
import Layout from "../../components/Partials/Layout";
import useRequestFun from "../../hooks/Axios/useRequestFun";

import DataTable from "../../components/Helpers/DataGrid";
import LottieLoader from "../../components/Helpers/LottieLoader";

export default function VoteNftList() {
  const [isLoader, setIsLoader] = useState(true);
  const [list, setList] = useState([]);
  const [networkData, setNetworkData] = useState();
  const { role, permissions } = useSelector((store) => store.user);
  const { getRequest } = useRequestFun();
  const getRequests = useCallback(() => {
    getRequest(`getNetWorks`).then(async (response) => {
      if (response.data.data[0]) {
        setNetworkData(response.data.data[0]);

        // getRequest("getalllistingnft").then((res) => {
        //   if (res?.data?.data) {
        //     setList(res?.data?.data);
        //   }
        // });

        getRequest("getAllVotedNft").then((res) => {
          console.log(res?.data?.data);
          if (res?.data?.data) {
            setList(res?.data?.data);
          }
        });
      }
      setIsLoader(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRequests();
  }, [getRequests, isLoader]);

  const actionCol = !(
    role === "superadmin" ||
    permissions.updateAdminInfo ||
    permissions.deleteAdmin
  );

  const truncate = (str) =>
    // eslint-disable-next-line prefer-template
    str.length > 10 ? str.substring(0, 6) + "..." + str.slice(-6) : str;

  const columns: GridColDef[] = [
    {
      minWidth: 150,
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        // eslint-disable-next-line no-unused-expressions

        <a
          href={`nfts/${params.row?.data[0]?.contractAddress}/${params.row?.data[0]?.tokenId}`}
          rel="noreferrer"
          className="hover:text-blue-400"
        >
          {params.row?.data[0]?.data?.metadata?.name
            ? `${params.row?.data[0]?.data?.metadata?.name}`
            : "N/A"}
        </a>
      ),
    },
    {
      minWidth: 150,
      field: "image",
      headerName: "Image",
      flex: 1,
      renderCell: (params) =>
        params.row?.data[0]?.data?.media[0]?.gateway !== "" ? (
          <a
            href={`nfts/${params.row?.data[0]?.contractAddress}/${params.row?.data[0]?.tokenId}`}
            rel="noreferrer"
            className="hover:text-blue-400"
          >
            <img
              src={params.row?.data[0]?.data?.media[0]?.gateway}
              alt="Nfts"
              width={60}
              height={60}
              loading="eager"
            />
          </a>
        ) : (
          <img src={notfOund} alt="Collection" width={60} />
        ),
    },
    {
      minWidth: 150,
      field: "contract_address",
      headerName: "Contract",
      flex: 1,
      renderCell: (params) => (
        <a
          href={`${networkData.explorer_url}address/${params.row?.data[0]?.contractAddress}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {truncate(params.row?.data[0]?.contractAddress)}
        </a>
      ),
    },
    {
      minWidth: 150,
      field: "num_vote",
      headerName: "Vote",
      flex: 1,
      renderCell: (params) =>
        params.row.num_vote ? `${params.row.num_vote}` : "N/A",
    },

    {
      headerName: "Actions",
      flex: 1,
      hide: actionCol,
      renderCell: (params) => (
        <>
          {role === "superadmin" && (
            <>
              <a
                title="View on NFTO"
                target="blank"
                href={`${process.env.REACT_APP_FRONTEND_URL}/item/${params.row?.data[0]?.contractAddress}/${params.row?.data[0]?.tokenId}`}
              >
                <button
                  type="button"
                  className="text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
                >
                  <AiOutlineEye className="text-xl" />
                </button>
              </a>
            </>
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <Layout>
        {isLoader ? (
          <LottieLoader />
        ) : (
          <div
            className={`tableForUpdate w-full p-8 bg-white overflow-hidden rounded-2xl shadowSection relative min-h-[520px] `}
          >
            <div className="header w-full sm:flex justify-between items-center mb-5">
              <div className="flex space-x-2 items-center mb-2 sm:mb-0">
                <h1 className="text-xl font-bold text-dark-gray tracking-wide">
                  Vote Nfts
                </h1>
              </div>
            </div>
            <div className="relative w-full overflow-x-auto sm:rounded-lg h-[800px]">
              {list && <DataTable rowList={list} columns={columns} />}
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}
