/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../components/Partials/Layout";
// import useFileUpload from "../../hooks/useFileUpload";
import LottieLoader from "../../components/Helpers/LottieLoader";
import SubscriptionServiceUpdateForm from "../../components/Subscription/SubscriptionServiceUpdateForm";
import useRequestFun from "../../hooks/Axios/useRequestFun";

export default function UpdateSubscription() {
  const [isLoader, setIsLoader] = useState(true);
  const [name, setName] = useState(false);
  const [label, setLabel] = useState(false);

  const location = useLocation();
  const { getRequest } = useRequestFun();

  useEffect(() => {
    getRequest(`getaservice/${location.state.id}`).then((res) => {
      setName(res.data.data.name);
      setLabel(res.data.data?.label);
      setIsLoader(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      {isLoader ? (
        <LottieLoader />
      ) : (
        <div className="settings-wrapper w-full relative mb-10">
          <div className="mainWrapper w-full">
            {/* heading */}
            <SubscriptionServiceUpdateForm
              // addCategory={(e) => addCategory(e)}
              serviceName={name}
              serviceLabel={label}
              serviceId={location.state.id}
              // metaDescription={metaDescription}
              // setName={(e) => setName(e.target.value)}
              // setDescription={(obj) => setDescription((prev) => [...prev, obj])}
              // setMetaDescription={(e) => setMetaDescription(e.target.value)}
              // errorMessage={errorMessage}
              // status={status}
              // setCateStatus={(value, id) => setCateStatus(value, id)}
              // cateStatus={cateStatus}
              // setImage={(img) => setLogo(img)}
              // mimeType={(mType) => setLogoMimeType(mType)}
              setIsLoader={(mType) => setIsLoader(mType)}
              pageTitle="Update Subscription Service"
              cancelUrl="/subscription/service/list"
              categoryType="nftCategory"
            />
          </div>
        </div>
      )}
    </Layout>
  );
}
