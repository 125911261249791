/* eslint-disable object-shorthand */
import React from "react";
import Lottie from "react-lottie";
import logo from "../../assets/images/logo-1.png";
import * as animationData from "../../assets/lottie/nft_arkade_loader.json";

export default function LoginLayout({ slogan, children }) {
  const checkScreenHeight = window.screen.height;
  let screen = "";
  if (checkScreenHeight <= 950) {
    screen = "h-screen";
    // screen = "h-[950px]";
  } else {
    screen = "h-screen";
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="layoutWrapper">
      <div className={`mainWrapper w-full ${screen}`}>
        <div className="flex w-full h-full">
          <div className="xl:flex hidden w-1/2 h-full p-[70px]  flex-col justify-between gradientPrimary">
            <div className="logo">
              <img src={logo} alt="logo" width={200} />
            </div>
            <div className="thumbnail flex justify-center w-4/5 m-auto">
              {/* <img src={loginThumb} alt="login-thumb" /> */}
              <Lottie options={defaultOptions} className="object-cover" />
            </div>
            <div className="article w-[695px]">
              <p className="text-[60px] font-bold leading-[72px] text-white">
                {slogan}
              </p>
            </div>
          </div>
          <div className="flex-1 flex justify-center items-center">
            {children && children}
          </div>
        </div>
      </div>
    </div>
  );
}
