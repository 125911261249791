/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState } from "react";
import { HiInformationCircle } from "react-icons/hi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// import socketIOClient from "socket.io-client";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import InputCom from "../Helpers/Inputs";
import LottieLoader from "../Helpers/LottieLoader";
import Layout from "../Partials/Layout";

// const socket = socketIOClient(process.env.REACT_APP_API_URL);

export default function NetworkSetup() {
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [rpc, setRpc] = useState("");
  const [chainId, setChainId] = useState("");
  const [isLoader, setIsLoader] = useState(true);
  const [isDeployed, setIsDeployed] = useState(false);
  const [isNetwork, setIsNetwork] = useState(false);

  const { postRequest, getRequest, putRequest } = useRequestFun();
  const [networkId, setNetworkId] = useState("");
  const [messages, setMessages] = useState({});
  const [explorerUrl, setExplorerURL] = useState("");

  useEffect(() => {
    getRequest(`getNetWorks`)
      .then(async (res) => {
        if (res.data.data[0]) {
          setIsNetwork(true);
          setName(res.data.data[0].name);
          setSymbol(res.data.data[0].symbol);
          setRpc(res.data.data[0].rpc_url);
          setChainId(res.data.data[0].chain_id);
          setNetworkId(res.data.data[0]._id);
          setExplorerURL(res.data.data[0].explorer_url);
        }
        setIsLoader(false);
      })
      .catch(() => {
        setIsLoader(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRequest(`getmarketcontract`).then(async (res) => {
      if (res.data.data) setIsDeployed(res.data.data.is_deployed);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const addNetWork = async (event) => {
    // After click Add Admin
    event.preventDefault();
    let isValid = true;
    if (name === "") {
      setMessages({ name: "Network name is required" });
      isValid = false;
    } else if (symbol === "") {
      setMessages({ symbol: "Symbol is required" });
      isValid = false;
    } else if (rpc === "") {
      setMessages({ rpc: "Rpc Url is required" });
      isValid = false;
    } else if (
      !rpc.match("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")
    ) {
      setMessages({ rpc: "Email is not valid" });
      isValid = false;
    } else if (chainId === "") {
      setMessages({ chainId: "Email is required" });
      isValid = false;
    } else if (explorerUrl === "") {
      setMessages({ explorerUrl: "Explorer is required" });
      isValid = false;
    } else {
      setMessages("");
    }

    if (isValid) {
      setIsLoader(true);
      postRequest("addnetwork", {
        name,
        symbol,
        chain_id: chainId,
        rpc_url: rpc,
        explorer_url: explorerUrl,
        status: "active",
      })
        .then((res) => {
          setIsLoader(false);
          setIsNetwork(true);
          toast.success(`${res.data.message}`, {
            icon: `🙂`,
          });
        })
        .catch((err) => {
          setIsLoader(false);
          toast.error(`${err.response.data.message}`);
        });
    }
  };

  const updateNetwork = (event) => {
    event.preventDefault();
    let isValid = true;
    if (name === "") {
      setMessages({ name: "Network name is required" });
      isValid = false;
    } else if (symbol === "") {
      setMessages({ symbol: "Symbol is required" });
      isValid = false;
    } else if (rpc === "") {
      setMessages({ rpc: "Rpc Url is required" });
      isValid = false;
    } else if (
      !rpc.match("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")
    ) {
      setMessages({ rpc: "Email is not valid" });
      isValid = false;
    } else if (chainId === "") {
      setMessages({ chainId: "Email is required" });
      isValid = false;
    } else if (explorerUrl === "") {
      setMessages({ explorerUrl: "Explorer is required" });
      isValid = false;
    } else {
      setMessages("");
    }

    if (isValid) {
      setIsLoader(true);
      putRequest(`updatenetworkbyid/${networkId}`, {
        name,
        symbol,
        chain_id: chainId,
        rpc_url: rpc,
        explorer_url: explorerUrl,
        status: "active",
      })
        .then((res) => {
          setIsLoader(false);
          toast.success(`${res.data.message}`, {
            icon: `🙂`,
          });
        })
        .catch((err) => {
          setIsLoader(false);
          toast.error(`${err.response.data.message}`);
        });
    }
  };
  return (
    <>
      {isLoader ? (
        <LottieLoader />
      ) : (
        <Layout>
          <div className="settings-wrapper w-full relative mb-10">
            <div className="mainWrapper w-full">
              <div className="content-container w-full rounded-2xl bg-white">
                <div className="content-body-items flex-1 p-10">
                  <div className="heading w-full mb-6">
                    <h1 className="text-26 font-bold text-dark-gray antialiased">
                      Network Setup
                    </h1>
                  </div>
                  <form onSubmit={isNetwork ? updateNetwork : addNetWork}>
                    <div className="personal-info-tab w-full flex flex-col justify-between">
                      <div className="flex flex-col-reverse sm:flex-row">
                        <div className="flex-1 sm:mr-10">
                          <div className="fields w-full">
                            {/* first name and last name */}
                            <div className="xl:flex xl:space-x-7 mb-6">
                              <div className="field w-full mb-6 xl:mb-0">
                                <InputCom
                                  label="Name"
                                  type="text"
                                  name="name"
                                  inputHandler={(e) => setName(e.target.value)}
                                  value={name}
                                  placeholder="Network Name Here"
                                  error={messages.name ? true : false}
                                />
                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.name}
                                </small>
                              </div>
                              <div className="field w-full">
                                <InputCom
                                  label="Symbol"
                                  type="text"
                                  name="symbol"
                                  inputHandler={(e) =>
                                    setSymbol(e.target.value)
                                  }
                                  value={symbol}
                                  placeholder="Symbol Here"
                                  error={messages.symbol ? true : false}
                                />

                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.symbol}
                                </small>
                              </div>
                            </div>
                            {/* user name and email */}
                            <div className="xl:flex xl:space-x-7 mb-6">
                              <div className="field w-full mb-6 xl:mb-0">
                                <InputCom
                                  label="RPC URL"
                                  type="text"
                                  name="rpc_url"
                                  inputHandler={(e) => setRpc(e.target.value)}
                                  value={rpc}
                                  placeholder="Set RPC URL"
                                  error={messages.rpc ? true : false}
                                />

                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.rpc}
                                </small>
                              </div>
                              <div className="field w-full xl:flex xl:space-x-7 mb-6">
                                <div className="field w-1/2">
                                  <InputCom
                                    label="Chain Id"
                                    type="text"
                                    name="chain_id"
                                    inputHandler={(e) =>
                                      setChainId(e.target.value)
                                    }
                                    value={chainId}
                                    placeholder="Network Chain Id"
                                    error={messages.chainId ? true : false}
                                  />
                                  <small className="text-red-400 ml-6 italic font-bold">
                                    {messages.chainId}
                                  </small>
                                </div>
                                <div className="field w-1/2">
                                  <InputCom
                                    label="Explorer URL"
                                    type="text"
                                    name="explorer url"
                                    inputHandler={(e) =>
                                      setExplorerURL(e.target.value)
                                    }
                                    value={explorerUrl}
                                    placeholder="https://goerli.etherscan.io/"
                                    error={messages.explorerUrl ? true : false}
                                  />
                                  <small className="text-red-400 ml-6 italic font-bold">
                                    {messages.explorerUrl}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="xl:flex  mb-4">
                        <HiInformationCircle />
                        <span>
                          After deploying your smart contract, network setting
                          can't update.
                        </span>
                      </div>

                      <div className="content-footer w-full">
                        <div className="w-full h-[120px] border-t border-light-purple flex justify-end items-center">
                          <div className="flex space-x-4 mr-9">
                            <Link to="/admin/adminuserlist">
                              <button
                                type="button"
                                className="text-18 text-light-red tracking-wide "
                              >
                                <span className="border-b border-light-red">
                                  Cancel
                                </span>
                              </button>
                            </Link>
                            {isNetwork ? (
                              <button
                                type="submit"
                                className={`w-[152px] h-[46px] flex justify-center items-center gradientButton text-base rounded-full text-white ${
                                  isDeployed === true ? "opacity-50" : ""
                                }`}
                                disabled={isDeployed ? true : false}
                              >
                                Update Admin
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="w-[152px] h-[46px] flex justify-center items-center gradientButton text-base rounded-full text-white"
                              >
                                Add Network
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}
