/* eslint-disable no-unreachable */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-underscore-dangle */
import { GridColDef } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import DataTable from "../Helpers/DataGrid";
import LottieLoader from "../Helpers/LottieLoader";

export default function AdminUserList() {
  const [isLoader, setIsLoader] = useState(true);
  const [alladminList, setAllAdminList] = useState();
  const { getRequest, putRequest } = useRequestFun();
  const { role, permissions } = useSelector((store) => store.user);
  const navigate = useNavigate();
  const getRequests = useCallback(() => {
    getRequest("adminlist").then((res) => {
      setAllAdminList(res.data.data);
      setIsLoader(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteAdmin = (id) => {
    return toast.warning("Disabled For Demo Version!");
    // eslint-disable-next-line no-restricted-globals, no-alert
    const result = confirm("Want to delete?");
    if (result) {
      putRequest(`deleteadmin/${id}`, {
        status: "deleted",
      })
        .then((res) => {
          toast.success(`${res.data.message}`, {
            icon: `🙂`,
          });
          setAllAdminList(res.data.data);
        })
        .catch((err) => {
          toast.error(`${err.response.data.message}`);
        });
    }
  };

  useEffect(() => {
    getRequests();
  }, [getRequests]);
  // const edit = (item) => {
  //   navigate("/admin/updateadmin", {
  //     state: { id: item._id },
  //   });
  // };
  // let count = 0;
  const actionCol =
    role === "superadmin" ||
    permissions.updateAdminInfo ||
    permissions.deleteAdmin
      ? false
      : true;

  const columns: GridColDef[] = [
    // {
    //   field: "sl",
    //   headerName: "SL",
    //   flex: 1,
    //   valueGetter: () => count++,
    // },
    {
      field: "fullName",
      headerName: "Full Name",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        `${params.row.first_name} ${params.row.last_name}`,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      renderCell: (params) =>
        params.row.status === "active" ? (
          <div className="bg-[#D2FFD4] text-[#0D9F16] rounded-md py-1 px-3 uppercase">
            {params.row.status}
          </div>
        ) : params.row.status === "inactive" ? (
          <div className="bg-[#E9DCFE] text-[#2C0270] rounded-md py-1 px-3 uppercase">
            {params.row.status}
          </div>
        ) : (
          params.row.status === "suspend" && (
            <div className="bg-[#FFD4D0] text-[#FF1600] rounded-md py-1 px-3 uppercase">
              {params.row.status}
            </div>
          )
        ),
    },
    {
      headerName: "Actions",
      flex: 1,
      hide: actionCol,
      minWidth: 150,
      renderCell: (params) => {
        const edit = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          navigate("/admin/updateadmin", {
            state: { id: params.row._id },
          });
        };
        const dlt = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          deleteAdmin(params.row._id);
        };
        return (
          <>
            {(role === "superadmin" || permissions.updateAdminInfo) && (
              <button
                type="button"
                className="text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
                onClick={edit}
              >
                <AiOutlineEdit className="text-xl" />
              </button>
            )}
            &nbsp;
            {(role === "superadmin" || permissions.deleteAdmin) && (
              <button
                type="button"
                className="bg-red-600 hover:bg-red-800 text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
                onClick={dlt}
              >
                <AiOutlineDelete className="text-xl" />
              </button>
            )}
          </>
        );
      },
    },
  ];
  return (
    <>
      {isLoader ? (
        <LottieLoader />
      ) : (
        <div
          className={`tableForUpdate w-full p-8 bg-white overflow-hidden rounded-2xl shadowSection relative min-h-[520px] `}
        >
          <div className="header w-full sm:flex justify-between items-center mb-5">
            <div className="flex space-x-2 items-center mb-2 sm:mb-0">
              <h1 className="text-xl font-bold text-dark-gray tracking-wide">
                Admin User List
              </h1>
            </div>
          </div>
          <div className="relative w-full overflow-x-auto sm:rounded-lg h-[700px]">
            {alladminList && (
              <DataTable rowList={alladminList} columns={columns} />
            )}
          </div>
        </div>
      )}
    </>
  );
}
