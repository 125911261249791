/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
// import React, { useState } from "react";
import { useEffect, useState } from "react";
import useRequestFun from "../../hooks/Axios/useRequestFun";

export default function UpdateTable({ className, dashData }) {
  // const filterCategories = ["All Categories", "Explore", "Featured"];
  // const [selectedCategory] = useState(filterCategories[0]);
  const [networkData, setNetworkData] = useState();
  // const [balance, setBalance] = useState("0");
  const { getRequest } = useRequestFun();
  const truncate = (str) =>
    // eslint-disable-next-line prefer-template
    str?.length > 10 ? str.substring(0, 3) + "..." + str.slice(-3) : str;
  console.log("dashData", dashData);
  useEffect(() => {
    getRequest(`getNetWorks`).then(async (response) => {
      if (response.data.data[0]) {
        setNetworkData(response.data.data[0]);
      }
    });
  }, []);
  return (
    <div
      className={`tableForUpdate w-full p-8 bg-white overflow-hidden rounded-2xl shadowSection relative min-h-[520px] ${
        className || ""
      }`}
    >
      {/* <div className="header w-full sm:flex justify-between items-center mb-5">
        <div className="flex space-x-2 items-center mb-2 sm:mb-0">
          <h1 className="text-xl font-bold text-dark-gray tracking-wide">
            All NFTS Update
          </h1>
          <span className="w-7 h-7 flex justify-center items-center bg-pink text-white text-xs rounded-full">
            435
          </span>
        </div>
        <SelectBox
          action={setCategory}
          datas={filterCategories}
          className="dropdownTableUpdate"
          contentBodyClasses="w-auto min-w-max"
        />
      </div> */}
      <div className="relative w-full overflow-x-auto sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <tbody>
            {/* table heading */}
            <tr className="text-base text-thin-light-gray whitespace-nowrap px-2 border-b borderBottomDefault ">
              <td className="py-4 w-[300px] block whitespace-nowrap">
                Top Voted NFTs
              </td>
              <td className="py-4 whitespace-nowrap  text-center">
                Total Vote
              </td>
              <td className="py-4 whitespace-nowrap text-center">Token Id</td>
              <td className="py-4 whitespace-nowrap text-center">
                Contract Address
              </td>
              <td className="py-4 whitespace-nowrap text-center">Network</td>
              <td className="py-4 whitespace-nowrap  text-center">
                Minted From
              </td>

              {/* <td className="py-4 whitespace-nowrap text-right">Status</td> */}
            </tr>
            {/* table heading end */}

            {dashData.topVotedNft.map((item) => (
              <>
                <tr
                  className="bg-white border-b hover:bg-gray-50"
                  key={item?._id}
                >
                  <td className=" py-4">
                    <div className="flex space-x-2 items-center">
                      <div className="w-[60px] h-[60px] rounded-full overflow-hidden flex justify-center items-center">
                        <img
                          src={item?.data?.[0]?.data?.media[0]?.gateway}
                          alt="data"
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div className="flex flex-col">
                        <h1 className="font-bold text-xl text-dark-gray">
                          {item?.data?.[0]?.data?.metadata?.name}
                        </h1>
                        <span className="text-sm text-thin-light-gray">
                          Owned by{" "}
                          <span className="text-purple">
                            <a
                              href={`${networkData?.explorer_url}address/${item?.data?.[0]?.owner[0]?.wallet_address}`}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              {truncate(
                                item?.data?.[0]?.owner[0]?.wallet_address
                              )}
                            </a>
                          </span>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center py-4">
                    <span className="text-base text-thin-light-gray whitespace-nowrap px-2">
                      {item?.num_vote}
                    </span>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-base text-dark-gray font-medium whitespace-nowrap">
                        {truncate(item?.data?.[0]?.tokenId)}
                      </span>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-base text-dark-gray font-medium">
                        <a
                          href={`${networkData?.explorer_url}address/${item?.data?.[0]?.contractAddress}`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {truncate(item?.data?.[0]?.contractAddress)}
                        </a>
                      </span>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <span className="text-base text-light-red whitespace-nowrap px-2 ">
                      {networkData?.name.toUpperCase()}
                    </span>
                  </td>
                  <td className="text-center py-4">
                    <span className="text-base text-dark-gray font-medium">
                      {item?.data?.[0]?.mintedFrom}
                    </span>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
