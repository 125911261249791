import React from "react";
import Layout from "../../components/Partials/Layout";
import UserList from "../../components/Users/UserList";

export default function Users() {
  return (
    <>
      <Layout>
        <UserList />
      </Layout>
    </>
  );
}
