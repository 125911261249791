import React, { useState } from "react";
import { AiOutlineImport } from "react-icons/ai";
import { FaWallet } from "react-icons/fa";
import { ImKey } from "react-icons/im";
import { useSelector } from "react-redux";
// import Icons from "../Helpers/Icons";
import LottieLoader from "../Helpers/LottieLoader";
import Layout from "../Partials/Layout";
import CreateWallet from "./CreateWallet";
import ExportPrivateKey from "./ExportPrivateKey";
import ImportWallet from "./ImportWallet";

export default function AdminWallet() {
  const [isLoader, setIsLoader] = useState(false);
  const { role } = useSelector((state) => state.user);
  const setLoader = (data) => {
    setIsLoader(data);
  };
  const tabs = [
    {
      id: 1,
      name: "create-wallet",
    },
    {
      id: 2,
      name: "import-wallet",
    },
    {
      id: 3,
      name: "export-private-key",
    },
  ];
  const [tab, setTab] = useState(tabs[0].name);
  const tabHandler = (value) => {
    setTab(value);
  };

  return (
    <>
      {isLoader ? (
        <LottieLoader />
      ) : (
        <Layout>
          <div className="settings-wrapper w-full relative mb-10">
            <div className="mainWrapper w-full">
              {/* heading */}

              <div className="content-container w-full pt-10 rounded-2xl bg-white">
                <div className="content-heading w-full mb-8 lg:px-10 px-4">
                  <h1 className="text-xl font-bold text-dark-gray antialiased">
                    Admin Wallet Management
                  </h1>
                </div>
                <div className="content-body w-full lg:flex lg:px-10 px-4">
                  <div className="content-tab-items lg:w-[230px] w-full mr-2">
                    <ul className="overflow-hidden mb-10 lg:mb-0">
                      <li
                        onClick={() => tabHandler("create-wallet")}
                        className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-none lg:float-none overflow-hidden ${
                          tab === "create-wallet"
                            ? "text-purple"
                            : " text-thin-light-gray"
                        }`}
                      >
                        <div>
                          <FaWallet className="text-xl" />
                        </div>
                        <div>
                          <p className="text-18 tracking-wide">Create Wallet</p>
                        </div>
                      </li>
                      <li
                        onClick={() => tabHandler("import-wallet")}
                        className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-none lg:float-none overflow-hidden ${
                          tab === "import-wallet"
                            ? "text-purple"
                            : " text-thin-light-gray"
                        }`}
                      >
                        <div>
                          <AiOutlineImport className="text-xl" />
                        </div>
                        <div>
                          <p className="text-18 tracking-wide">Import Wallet</p>
                        </div>
                      </li>
                      {role === "superadmin" && (
                        <li
                          onClick={() => tabHandler("export-private-key")}
                          className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-none lg:float-none overflow-hidden ${
                            tab === "export-private-key"
                              ? "text-purple"
                              : " text-thin-light-gray"
                          }`}
                        >
                          <div>
                            <ImKey className="text-xl" />
                          </div>
                          <div>
                            <p className="text-18 tracking-wide">
                              Export Private Key
                            </p>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="w-[1px] bg-[#E3E4FE] mr-10"></div>
                  <div className="content-body-items flex-1">
                    {tab === "create-wallet" && (
                      <div className="tab-item mb-5">
                        <CreateWallet setLoader={setLoader} />
                      </div>
                    )}
                    {tab === "import-wallet" && (
                      <div className="tab-item mb-5">
                        <ImportWallet setLoader={setLoader} />
                      </div>
                    )}

                    {tab === "export-private-key" && role === "superadmin" && (
                      <div className="tab-item mb-5">
                        <ExportPrivateKey setLoader={setLoader} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}
