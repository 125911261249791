import React, { useState } from "react";
import Icons from "../Icons";

export default function InputCom({
  label,
  type,
  name,
  placeholder,
  iconName,
  inputHandler,
  value,
  readOnlyValue,
  notRequired = false,
  error,
  defaultValue
}) {
  const [icon, setIcon] = useState(iconName);
  const [inputType, setInputType] = useState(type);
  function showPass() {
    if (icon === "password") {
      setIcon("show-password");
      setInputType("text");
    }
    if (icon === "show-password") {
      setIcon("password");
      setInputType("password");
    }
  }
  return (
    <div className="input-com">
      {label && (
        <label
          className="input-label text-dark-gray text-xl font-bold block mb-2.5"
          htmlFor={name}
        >
          {label}
          {!notRequired && <small className="text-red-400">*</small>}
        </label>
      )}
      <div
        className={`input-wrapper border border-light-purple w-full rounded-[50px] h-[58px] overflow-hidden relative ${
          error && "border-red-400"
        }`}
      >
        {readOnlyValue && (
          <input
            placeholder={placeholder}
            value={value ?? defaultValue}
            onChange={inputHandler}
            className="input-field placeholder:text-base text-bese px-6 text-dark-gray w-full h-full bg-[#d4d3d3] focus:ring-0 focus:outline-none"
            type={inputType}
            id={name}
            readOnly
          />
        )}
        {!readOnlyValue && (
          <input
            placeholder={placeholder}
            value={value ?? defaultValue}
            onChange={inputHandler}
            className="input-field placeholder:text-base text-bese px-6 text-dark-gray w-full h-full bg-[#FAFAFA] focus:ring-0 focus:outline-none"
            type={inputType}
            id={name}
          />
        )}
        {icon && (
          <div
            className="absolute right-6 bottom-[19px] z-10"
            onClick={showPass}
          >
            <Icons name={icon} />
          </div>
        )}
      </div>
    </div>
  );
}
