import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import AISubscribers from "./components/Aisubscribe";
import Auction from "./components/Auction";
import Bids from "./components/Bids";
import DirectBuy from "./components/DirectBuy";
import Discussions from "./components/Discussions";
import DiscussionDetails from "./components/Discussions/DiscussionDetails";
import Earnings from "./components/Earning";
import FourZeroFour from "./components/FourZeroFour";
import ScrollToTop from "./components/Helpers/ScrollToTop";
import ListedNfts from "./components/ListedNfts";
import MyCollection from "./components/MyCollection";
import Promotion from "./components/MyCollection/promotion";
import Notification from "./components/Notification";
import Subscribers from "./components/Subscribers";
import Support from "./components/Support";
import SupportDetails from "./components/Support/SupportDetaills";
import AuthRoute from "./middleware/AuthRoute";
import CheckNewPass from "./middleware/CheckNewPass";
import PublicRoute from "./middleware/PublicRoute";
import AcitveBidsPage from "./views/AcitveBidsPage";
import Admin from "./views/Admin/Admin";
import AdminList from "./views/Admin/AdminUserList";
import ForgetUpdatePasswordPages from "./views/Admin/ForgetUpdatePassword";
import Role from "./views/Admin/Role";
import RoleList from "./views/Admin/RoleList";
import RoleUpdate from "./views/Admin/RoleUpdate";
import UpdateAdminUser from "./views/Admin/UpdateAdminUser";
import Wallet from "./views/AdminWallet";
import AuthProfilePage from "./views/AuthProfilePage";
import AddBlog from "./views/Blog/AddBlog";
import AddFaq from "./views/Blog/AddFaq";
import BlogList from "./views/Blog/BlogList";
import FaqList from "./views/Blog/FaqList";
import UpdateBlog from "./views/Blog/UpdateBlog";
import UpdateFaq from "./views/Blog/UpdateFaq";
import AddCategory from "./views/Categories/AddCategory";
import CategoriesList from "./views/Categories/CategoriesList";
import UpdateCategory from "./views/Categories/UpdateCategory";
import CollectionItemPage from "./views/CollectionItemPage";
import ForgotPasswordPages from "./views/ForgotPasswordPages";
import HistoryPage from "./views/HistoryPage";
import Home from "./views/Home";
import AddLearn from "./views/Learn/AddLearn";
import LearnList from "./views/Learn/LearnList";
import UpdateLearn from "./views/Learn/UpdateLearn";
import Login from "./views/Login";
import MarketContract from "./views/MarketContract";
import MyWalletPage from "./views/MyWalletPage";
import Network from "./views/Network";
import NftDetails from "./views/Nfts/NftDetails";
import NftList from "./views/Nfts/NftList";
import AddProCategory from "./views/Product/AddProCategory";
import AddProduct from "./views/Product/AddProduct";
import Charges from "./views/Product/Charges";
import EditProCategory from "./views/Product/EditProCategory";
import EditProduct from "./views/Product/EditProduct";
import OrderDetails from "./views/Product/OrderDetails";
import Orders from "./views/Product/Orders";
import ProCategoryList from "./views/Product/ProCategoryList";
import ProductList from "./views/Product/ProductList";
import SavedPage from "./views/SavedPage";
import SellPage from "./views/SellPage";
import Settings from "./views/Settings";
import ShopDetailsPage from "./views/ShopDetailsPage";
import SignupPage from "./views/SignupPage";
import AddSubscriptionForm from "./views/Subscription/AddSubscriptionForm";
import AddSubscriptionService from "./views/Subscription/AddSubscriptionService";
import SubscriptionList from "./views/Subscription/SubscriptionList";
import SubscriptionServiceList from "./views/Subscription/SubscriptionServiceList";
import UpdateSubscription from "./views/Subscription/UpdateSubscription";
import UpdateSubscriptionService from "./views/Subscription/UpdateSubscriptionService";
import UpdatePassword from "./views/UpdatePassword";
import UploadProductPage from "./views/UploadProductPage";
import UserProfilePage from "./views/UserProfilePage";
import Users from "./views/Users/UserList";
import VerifyYouPages from "./views/VerifyYouPages";
import VoteNftList from "./views/Vote/VoteNftList";
import SliderList from "./views/Slider/SliderList";
import AddSliderForm from "./views/Slider/AddSliderForm";
import UpdateSliderForm from "./views/Slider/UpdateSliderForm";

export default function Routers() {
  const { email } = useSelector((state) => state.forgetPassword);
  const { permissions, role } = useSelector((state) => state.user);
  return (
    <ScrollToTop>
      <Routes>
        {/* guest routes */}
        <Route element={<PublicRoute />}>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<SignupPage />} />
          <Route
            exact
            path="/forgot-password"
            element={<ForgotPasswordPages />}
          />
        </Route>
        {email && (
          <>
            <Route exact path="/verify-you" element={<VerifyYouPages />} />
            <Route
              exact
              path="/forget/update-password"
              element={<ForgetUpdatePasswordPages />}
            />
          </>
        )}

        <Route element={<CheckNewPass />}>
          <Route exact path="/update-password" element={<UpdatePassword />} />
        </Route>
        {/* private route */}
        <Route element={<AuthRoute />}>
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/network-setup"
            element={
              role === "superadmin" || permissions.networkSetup ? (
                <Network />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/admin-wallet"
            element={
              role === "superadmin" || permissions.adminWallet ? (
                <Wallet />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/update/product/:productId"
            element={
              role === "superadmin" || permissions.product ? (
                <EditProduct />
              ) : (
                <Navigate to="/" />
              )
              // role === "superadmin" ? <EditProduct /> : <Navigate to="/" />
            }
          />
          <Route
            exact
            path="/add/product"
            element={
              role === "superadmin" || permissions.product ? (
                <AddProduct />
              ) : (
                <Navigate to="/" />
              )
              // role === "superadmin" ? <AddProduct /> : <Navigate to="/" />
            }
          />
          <Route
            exact
            path="/products"
            element={
              role === "superadmin" || permissions.product ? (
                <ProductList />
              ) : (
                <Navigate to="/" />
              )
              // role === "superadmin" ? <ProductList /> : <Navigate to="/" />
            }
          />
          <Route
            exact
            path="/orders"
            element={
              role === "superadmin" || permissions.product ? (
                <Orders />
              ) : (
                <Navigate to="/" />
              )
              // role === "superadmin" ? <Orders /> : <Navigate to="/" />
            }
          />
          <Route
            exact
            path="/orders/:id"
            element={
              role === "superadmin" || permissions.product ? (
                <OrderDetails />
              ) : (
                <Navigate to="/" />
              )
              // role === "superadmin" ? <OrderDetails /> : <Navigate to="/" />
            }
          />
          <Route
            exact
            path="/list/product-category"
            element={
              role === "superadmin" || permissions.product ? (
                <ProCategoryList />
              ) : (
                <Navigate to="/" />
              )
              // role === "superadmin" ? <ProCategoryList /> : <Navigate to="/" />
            }
          />
          <Route
            exact
            path="/charges/products"
            element={
              role === "superadmin" || permissions.product ? (
                <Charges />
              ) : (
                <Navigate to="/" />
              )
              // role === "superadmin" ? <Charges /> : <Navigate to="/" />
            }
          />
          <Route
            exact
            path="/add/product-category"
            element={
              role === "superadmin" || permissions.product ? (
                <AddProCategory />
              ) : (
                <Navigate to="/" />
              )
              // role === "superadmin" ? <AddProCategory /> : <Navigate to="/" />
            }
          />
          <Route
            exact
            path="/update/product-category"
            element={
              role === "superadmin" || permissions.product ? (
                <EditProCategory />
              ) : (
                <Navigate to="/" />
              )
              // role === "superadmin" ? <EditProCategory /> : <Navigate to="/" />
            }
          />
          <Route
            exact
            path="/active-bids"
            element={
              role === "superadmin" || permissions.activeBids ? (
                <AcitveBidsPage />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/bidding-list"
            element={
              role === "superadmin" || permissions.biddingList ? (
                <Bids />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/earning-list"
            element={
              role === "superadmin" || permissions.earningsList ? (
                <Earnings />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/subscribers"
            element={
              role === "superadmin" || permissions.subscribersList ? (
                <Subscribers />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/ai-subscribers"
            element={
              role === "superadmin" || permissions.aiSubscribersList ? (
                <AISubscribers />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/collections"
            element={
              role === "superadmin" || permissions.collection ? (
                <MyCollection />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/listed-nfts"
            element={
              role === "superadmin" || permissions.listedNft ? (
                <ListedNfts />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/direct-buy"
            element={
              role === "superadmin" || permissions.directBuy ? (
                <DirectBuy />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/auction"
            element={
              role === "superadmin" || permissions.auction ? (
                <Auction />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route exact path="/notification" element={<Notification />} />
          <Route
            exact
            path="/market-contract"
            element={
              role === "superadmin" || permissions.marketContract ? (
                <MarketContract />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          {/* // ? subscription routers */}
          <Route
            exact
            path="/subscription/add"
            element={
              role === "superadmin" || permissions.subscription ? (
                <AddSubscriptionForm />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/promotion-request"
            element={
              role === "superadmin" || permissions.promotion ? (
                <Promotion />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/subscription/list"
            element={
              role === "superadmin" || permissions.subscription ? (
                <SubscriptionList />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/subscription/update"
            element={
              role === "superadmin" || permissions.subscription ? (
                <UpdateSubscription />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/subscription/service/add"
            element={
              role === "superadmin" || permissions.subscription ? (
                <AddSubscriptionService />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/subscription/service/list"
            element={
              role === "superadmin" || permissions.subscription ? (
                <SubscriptionServiceList />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/subscription/service/update"
            element={
              role === "superadmin" || permissions.subscription ? (
                <UpdateSubscriptionService />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          {/* // ? subscription routers */}
          <Route exact path="/shop-details" element={<ShopDetailsPage />} />
          <Route exact path="/my-wallet" element={<MyWalletPage />} />
          <Route exact path="/collection" element={<MyCollection />} />

          <Route
            exact
            path="/my-collection/collection-item"
            element={<CollectionItemPage />}
          />
          <Route exact path="/sell" element={<SellPage />} />
          <Route exact path="/saved" element={<SavedPage />} />
          <Route exact path="/history" element={<HistoryPage />} />
          <Route exact path="/upload-product" element={<UploadProductPage />} />
          <Route exact path="/profile" element={<AuthProfilePage />} />
          <Route exact path="/user-profile" element={<UserProfilePage />} />
          <Route exact path="/settings" element={<Settings />} />
          <Route exact path="/add/admin" element={<Admin />} />
          <Route exact path="/admin/adminuserlist" element={<AdminList />} />
          <Route
            exact
            path="/admin/updateadmin"
            element={<UpdateAdminUser />}
          />
          {/* //Role Crud Routers */}
          <Route
            exact
            path="/add/role"
            element={role === "superadmin" ? <Role /> : <Navigate to="/" />}
          />
          <Route
            exact
            path="/roles"
            element={role === "superadmin" ? <RoleList /> : <Navigate to="/" />}
          />
          <Route
            exact
            path="/update/role"
            element={
              role === "superadmin" ? <RoleUpdate /> : <Navigate to="/" />
            }
          />

          {/* //Role Crud Routers */}
          {/* </Route> */}

          {/* user list route */}
          <Route
            exact
            path="/admin/userlist"
            element={
              role === "superadmin" || permissions.userList ? (
                <Users />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          {/* NFT's Routes */}

          <Route
            exact
            path="/nfts"
            element={role === "superadmin" ? <NftList /> : <Navigate to="/" />}
          />
          <Route
            exact
            path="/nfts/:contract/:id"
            element={
              role === "superadmin" || permissions.nftDetails ? (
                <NftDetails />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          {/* category Routes */}
          <Route
            exact
            path="/categories"
            element={
              role === "superadmin" ? <CategoriesList /> : <Navigate to="/" />
            }
          />
          <Route
            exact
            path="/add/category"
            element={
              role === "superadmin" ? <AddCategory /> : <Navigate to="/" />
            }
          />
          <Route
            exact
            path="/edit/category"
            element={
              role === "superadmin" ? <UpdateCategory /> : <Navigate to="/" />
            }
          />
          <Route
            exact
            path="/add/faq"
            element={
              role === "superadmin" || permissions.blogAndLearn ? (
                <AddFaq />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/add/blog"
            element={
              role === "superadmin" || permissions.blogAndLearn ? (
                <AddBlog />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/add/learn"
            element={
              role === "superadmin" || permissions.blogAndLearn ? (
                <AddLearn />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/learn-list"
            element={
              role === "superadmin" || permissions.blogAndLearn ? (
                <LearnList />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/faq-list"
            element={
              role === "superadmin" || permissions.blogAndLearn ? (
                <FaqList />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/update-learn"
            element={
              role === "superadmin" || permissions.blogAndLearn ? (
                <UpdateLearn />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/update-faq"
            element={
              role === "superadmin" || permissions.blogAndLearn ? (
                <UpdateFaq />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/blog-list"
            element={
              role === "superadmin" || permissions.blogAndLearn ? (
                <BlogList />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/update-blog"
            element={
              role === "superadmin" || permissions.blogAndLearn ? (
                <UpdateBlog />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/support-list"
            element={
              role === "superadmin" || permissions.support ? (
                <Support />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/discussion-list"
            element={
              role === "superadmin" || permissions.support ? (
                <Discussions />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/discussion-details/:id"
            element={
              role === "superadmin" || permissions.support ? (
                <DiscussionDetails />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/support/:id"
            element={
              role === "superadmin" || permissions.support ? (
                <SupportDetails />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          {/* category Routes */}
          <Route
            exact
            path="/vote-list"
            element={
              role === "superadmin" ? <VoteNftList /> : <Navigate to="/" />
            }
          />
          <Route
            exact
            path="/slider-list"
            element={
              role === "superadmin" ? <SliderList /> : <Navigate to="/" />
            }
          />
          <Route
            exact
            path="/add-slider"
            element={
              role === "superadmin" ? <AddSliderForm /> : <Navigate to="/" />
            }
          />
          <Route
            exact
            path="/update-slider"
            element={
              role === "superadmin" ? <UpdateSliderForm /> : <Navigate to="/" />
            }
          />
        </Route>

        <Route path="*" element={<FourZeroFour />} />
      </Routes>
    </ScrollToTop>
  );
}
