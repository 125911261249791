/* eslint-disable no-underscore-dangle */
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React from "react";

export default function DataTable({ rowList, columns }) {
  return (
    <DataGrid
      rows={rowList}
      columns={columns}
      rowHeight={80}
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      getRowId={(row) => row._id}
      components={{ Toolbar: GridToolbar }}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
    />
  );
}
