/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import titleShape from "../../../../assets/images/shape/title-shape-two.svg";
import { reset } from "../../../../store/user/UserSlice";
import InputCom from "../../../Helpers/Inputs";
import AuthLayout from "../../AuthLayout";

export default function UpdatePassword() {
  // const [updated, setValue] = useState(false);
  // const [message, setMessage] = useState(false);
  // const [oldPass, setOldPass] = useState("");
  const { email } = useSelector((state) => state.forgetPassword);
  const [newPass, setNewPass] = useState("");
  const [confirmMatch, setConfirmMatch] = useState(false);
  const [matchError, setMatchError] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const updatePassword = () => {
  //   setValue(!updated);
  //   setTimeout(() => {
  //     setMessage(!message);
  //   }, 100);
  // };
  function match(value) {
    if (newPass !== value) {
      setMatchError(true);
      setConfirmMatch(false);
      // eslint-disable-next-line no-else-return
    } else {
      setMatchError(false);
      setConfirmMatch(true);
    }
  }
  const updatePassword = (e) => {
    return toast.warning("Disabled For Demo Version!");
    e.preventDefault();
    if (!confirmMatch) {
      toast.success("Confirm Password Mismatch!");
      return;
    }
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/passresetforgotpass`, {
        newPassword: newPass,
        email,
      })
      .then((res) => {
        toast.success(res.data.message);
        navigate("/login");
        dispatch(reset());
      })
      .catch((err) => {
        toast.warning(err.response.data.message);
      });
  };

  return (
    <>
      <AuthLayout
        slogan="Welcome to  Nftmax
Admin Panel"
      >
        <div className="wrapperContent update-password-section xl:bg-white w-full 2xl:h-[818px] xl:h-[600px] sm:w-auto sm:px-[70px] px-5 2xl:px-[100px] rounded-xl flex flex-col justify-center">
          <div>
            <div className="title-area relative flex flex-col justify-center items-center mb-7">
              <h1 className="sm:text-5xl text-4xl font-bold leading-[74px] text-dark-gray">
                Update Password
              </h1>
              {/* w-[341px] absolute top-14 left-12 */}
              <div className="shape sm:w-[341px] w-[270px] -mt-5 sm:-mt-1 ml-5">
                <img src={titleShape} alt="shape" />
              </div>
            </div>
            <div className="input-area">
              {/* <div className="input-item mb-5">
                <InputCom
                  placeholder="*********"
                  label="Old Password"
                  name="password"
                  type="password"
                  iconName="password"
                  inputHandler={(e) => setOldPass(e.target.value)}
                />
              </div> */}
              <div className="input-item mb-5">
                <InputCom
                  placeholder="*********"
                  label="New Password"
                  name="password"
                  type="password"
                  iconName="password"
                  inputHandler={(e) => setNewPass(e.target.value)}
                />
              </div>
              <div className="input-item mb-5">
                <InputCom
                  placeholder="*********"
                  label="Re-enter Password"
                  name="password"
                  type="password"
                  iconName="password"
                  inputHandler={(e) => match(e.target.value)}
                />
                {matchError && (
                  <small className=" text-red-500">Password Didn't Match</small>
                )}
              </div>

              <div className="signin-area mb-3.5">
                <button
                  onClick={updatePassword}
                  type="button"
                  className="w-full rounded-[50px] mb-5 h-[58px] text-xl text-white font-bold flex justify-center bg-purple items-center"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
