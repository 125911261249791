import React from "react";
import UpdateRole from "../../components/Roles/UpdateRole";

export default function RoleUpdate() {
  return (
    <>
      <UpdateRole />
    </>
  );
}
