import React from "react";
import UpdateAdmin from "../../components/Admin/UpdateAdmin";

export default function RoleUpdate() {
  return (
    <>
      <UpdateAdmin />
    </>
  );
}
