/* eslint-disable no-unreachable */
/* eslint-disable no-underscore-dangle */
import { GridColDef } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
// import { AiOutlineEdit } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import { TbListDetails } from "react-icons/tb";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import Layout from "../Partials/Layout";

import DataTable from "../Helpers/DataGrid";
import LottieLoader from "../Helpers/LottieLoader";
import ConfirmModal from "../modal/ConfirmModal";

export default function Discussions() {
  const [isLoader, setIsLoader] = useState(true);
  const [list, setList] = useState();
  const [updateThis, setUpdateThis] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isDeletedId, setIsDeletedId] = useState();
  // const [balance, setBalance] = useState("0");
  const { getRequest, putRequest } = useRequestFun();
  // const navigate = useNavigate();
  const getRequests = useCallback(() => {
    getRequest("getAllForum").then((res) => {
      if (res?.data?.data) {
        setList(res?.data?.data);
        setIsLoader(false);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRequests();
  }, [getRequests, updateThis]);

  const handleConfirm = () => {
    return toast.warning("Disabled For Demo Version!");
    putRequest("deleteQuestion", {
      id: isDeletedId,
    }).then((res) => {
      setUpdateThis(!updateThis);
      if (res.status === 200) {
        toast.success("Deleted success!");
        setIsConfirmModal(!isConfirmModal);
        // navigate("/discussion-list");
      } else {
        toast.warning(res.message);
        setIsConfirmModal(!isConfirmModal);
      }
    });
  };

  const columns: GridColDef[] = [
    {
      minWidth: 150,
      field: "question",
      headerName: "Question",
      flex: 1,
      renderCell: (params) => (
        // eslint-disable-next-line no-unused-expressions
        <a
          href={`/discussion-details/${params.row._id}`}
          rel="noreferrer"
          className="hover:text-blue-400"
        >
          {params.row.question?.title ? `${params.row.question?.title}` : "N/A"}
        </a>
      ),
    },

    {
      minWidth: 150,
      field: "comment",
      headerName: "Total Comment",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row?.comment ? `${params.row?.comment.length}` : "N/A",
    },
    {
      minWidth: 150,
      field: "views",
      headerName: "Total View",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row?.views ? `${params.row?.views}` : "N/A",
    },
    {
      minWidth: 150,
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      valueGetter: (params) =>
        // eslint-disable-next-line no-unused-expressions
        params.row.createdAt ? `${new Date(params.row.createdAt)}` : "N/A",
    },
    {
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const deleteQuestion = (id) => {
          // e.stopPropagation(); // don't select this row after clicking
          setIsDeletedId(id);
          setIsConfirmModal(!isConfirmModal);
        };

        return (
          <>
            <Link to={`/discussion-details/${params.row._id}`}>
              <button
                type="button"
                className="text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
                // onClick={edit}
              >
                <TbListDetails className="text-xl" />
              </button>
            </Link>

            <button
              type="button"
              className="text-sm text-white bg-red-400 px-2.5 py-1.5 ml-2 rounded-full"
              onClick={() => {
                deleteQuestion(params.row._id);
              }}
            >
              <AiFillDelete className="text-xl" />
            </button>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Layout>
        {isLoader ? (
          <LottieLoader />
        ) : (
          <div
            className={`tableForUpdate w-full p-8 bg-white overflow-hidden rounded-2xl shadowSection relative min-h-[520px] `}
          >
            <div className="header w-full sm:flex justify-between items-center mb-5">
              <div className="flex space-x-2 items-center mb-2 sm:mb-0">
                <h1 className="text-xl font-bold text-dark-gray tracking-wide">
                  Discussion List
                </h1>
              </div>
            </div>
            <div className="relative w-full overflow-x-auto sm:rounded-lg h-[800px]">
              {list && <DataTable rowList={list} columns={columns} />}
            </div>
          </div>
        )}
      </Layout>
      {isConfirmModal && (
        <ConfirmModal
          headMessage="Confirm"
          bodyMessage="Are you sure to delete the question?"
          btn1="Confirm"
          btn2="Not Now"
          isMoadl={() => setIsConfirmModal(!isConfirmModal)}
          actionCofirm={() => handleConfirm()}
        ></ConfirmModal>
      )}
    </>
  );
}
