/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-danger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { AiFillCopy } from "react-icons/ai";
import { FaEthereum } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LottieLoader from "../../components/Helpers/LottieLoader";
import OrderStep from "../../components/Helpers/OrderStep/Step";
import StepOrderContent from "../../components/Helpers/OrderStep/StepOrderContent";
import Layout from "../../components/Partials/Layout";
import useRequestFun from "../../hooks/Axios/useRequestFun";

export default function OrderDetails() {
  const [createdAt, setCreatedAt] = useState();
  const [data, setData] = useState();
  const [updateStatus, setUpdateStatus] = useState(false);
  const [orderStatus, setOrderStatus] = useState(true);
  const [copy, setCopy] = useState("Copy");

  //   const [activities, setActivities] = useState();
  //   const [network, setNetwork] = useState();
  const { id } = useParams();
  const { getRequest, putRequest } = useRequestFun();
  const dateFormat = (date) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options).toString();
  };
  useEffect(() => {
    getRequest(`getorderbyid/${id}`).then((res) => {
      setData(res.data.data[0]);
      setCreatedAt(dateFormat(new Date(res.data.data[0].createdAt)));
      setOrderStatus(res.data.data[0].status);
    });
  }, [updateStatus]);
  const changeStatus = (value) => {
    return toast.warning("Disabled For Demo Version!");
    if (orderStatus === "Cancelled") {
      return;
    }
    setUpdateStatus(false);
    putRequest(`updateorderstatus/${id}`, { status: value })
      .then((res) => {
        toast.success(`${res.data.message}`);
        setUpdateStatus(true);
        setData(res.data.data);
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`);
      });
  };
  const copyKey = (privateKey) => {
    navigator.clipboard.writeText(privateKey);
    setCopy("Copied");
  };

  return (
    <Layout>
      {data ? (
        <div className="flex flex-wrap 2xl:flex-nowrap gap-3 lg:gap-6 ">
          <div className="wrapper px-[14px] sm:px-10 lg:px-[32px]  m-auto pb-8 bg-white rounded-[20px] shadow-2xl w-10/12">
            {/* <div className="flex flex-col items-center justify-center mb-9">
        <Image src={OrderDetails} alt="order icon" width={100} height={100} />
        <h1 className="text-[28px] font-bold lineHeight100 mb-2">
          Your order has been placed
        </h1>
        <h2 className="text-xl font-semibold lineHeight140 gradient-text">
          Thank you for choosing 
          
        </h2>
      </div> */}
            <div className="bg-white  p-5  ">
              <div className="mb-10">
                <p className="text-sm font-medium lineHeight100 text-text mb-5">
                  Order Timeline: {}
                </p>
                <div>
                  <OrderStep>
                    <StepOrderContent
                      title="Order Placed"
                      time={createdAt.toString()}
                      status={
                        orderStatus === "Cancelled" ? "cancel" : "checked"
                      }
                    />
                    <StepOrderContent
                      title="Accepted"
                      time={
                        data?.orderAcceptedDate &&
                        dateFormat(new Date(data?.orderAcceptedDate)).toString()
                      }
                      status={
                        orderStatus === "Accepted" ||
                        orderStatus === "Processing" ||
                        orderStatus === "Shipped" ||
                        orderStatus === "Delivered"
                          ? "checked"
                          : orderStatus === "Cancelled"
                          ? "cancel"
                          : "process"
                      }
                    />
                    <StepOrderContent
                      title="Processing"
                      time={
                        data?.orderProcessingDate &&
                        dateFormat(
                          new Date(data?.orderProcessingDate)
                        ).toString()
                      }
                      status={
                        orderStatus === "Processing" ||
                        orderStatus === "Shipped" ||
                        orderStatus === "Delivered"
                          ? "checked"
                          : orderStatus === "Cancelled"
                          ? "cancel"
                          : "process"
                      }
                    />
                    <StepOrderContent
                      title="Shipped"
                      time={
                        data?.orderShippingDate &&
                        dateFormat(new Date(data?.orderShippingDate)).toString()
                      }
                      status={
                        orderStatus === "Shipped" || orderStatus === "Delivered"
                          ? "checked"
                          : orderStatus === "Cancelled"
                          ? "cancel"
                          : "process"
                      }
                    />
                    {data?.status === "Cancelled" ? (
                      <StepOrderContent
                        title="Cancelled"
                        time={
                          data?.orderCancelledDate &&
                          dateFormat(
                            new Date(data?.orderCancelledDate)
                          ).toString()
                        }
                        status={orderStatus === "Cancelled" && "cancel"}
                        lastStep
                      />
                    ) : (
                      <StepOrderContent
                        title="Delivered"
                        time={
                          data?.orderDeliverdDate &&
                          dateFormat(
                            new Date(data?.orderDeliverdDate)
                          ).toString()
                        }
                        status={
                          orderStatus === "Delivered" ? "checked" : "process"
                        }
                        lastStep
                      />
                    )}
                  </OrderStep>
                </div>
              </div>
              <div className="divide-y-2 grid grid-cols-3 gap-4 ">
                <div className="col-span-2">
                  <div className="grid grid-cols-2 gap-3">
                    <div className="flex flex-col gap-2">
                      <p className="text-sm font-medium lineHeight100 text-text">
                        Order ID:
                      </p>
                      <div className="flex items-center gap-3">
                        <span
                          className={`text-sm font-bold flex items-center gap-3 ${
                            copy === "Copied" ? " text-blue-400" : ""
                          }`}
                        >
                          <p className="text-base font-semibold lineHeight140 gradient-text">
                            #{data._id}
                          </p>
                          <button
                            type="button"
                            className={`w-[28px] h-[28px] flex justify-center items-center gradientButton text-base rounded-full text-white `}
                            onClick={() => copyKey(data._id)}
                          >
                            <AiFillCopy className="text-1xl" />
                          </button>
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-sm font-medium lineHeight100 text-text">
                        Order Date:
                      </p>
                      <div className="bg-[#DFE5FF] px-[6px] py-1 rounded-md">
                        <p className="text-xs lineHeight120 gradient-text">
                          {createdAt}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-sm font-medium lineHeight100 text-text">
                        Delivery Timeline:
                      </p>
                      <div className="bg-[#DFE5FF] px-[6px] py-1 rounded-md">
                        <p className="text-xs font-medium lineHeight120 text-text">
                          24 to 72 hours
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="p-[10px] divide-y-2 border-2 border-[#DFE5FF] rounded-lg mt-3">
                      <p className="pb-2 text-base font-semibold lineHeight100 gradient-text ">
                        Shipping Address
                      </p>
                      <div className="pt-2 flex flex-col gap-2">
                        <ul className="w-full divide-y-2">
                          <li className="flex justify-between py-2">
                            <p className="text-sm font-medium text-[#81829E]">
                              Name
                            </p>
                            <p className="text-sm">
                              {data.shipping_address.name}
                            </p>
                          </li>
                          <li className="flex justify-between py-2">
                            <p className="text-sm font-medium text-[#81829E]">
                              Email
                            </p>
                            <p className="text-sm">
                              {data.shipping_address.email}
                            </p>
                          </li>
                          <li className="flex justify-between py-2">
                            <p className="text-sm font-medium text-[#81829E]">
                              Street
                            </p>
                            <p className="text-sm">
                              {data.shipping_address.streetAddress}
                            </p>
                          </li>
                          <li className="flex justify-between py-2">
                            <p className="text-sm font-medium text-[#81829E]">
                              City
                            </p>
                            <p className="text-sm">
                              {data.shipping_address.city}
                            </p>
                          </li>
                          <li className="flex justify-between py-2">
                            <p className="text-sm font-medium text-[#81829E]">
                              State
                            </p>
                            <p className="text-sm">
                              {data.shipping_address.state}
                            </p>
                          </li>
                          <li className="flex justify-between py-2">
                            <p className="text-sm font-medium text-[#81829E]">
                              Zip
                            </p>
                            <p className="text-sm">
                              {data.shipping_address.zip}
                            </p>
                          </li>
                          <li className="flex justify-between py-2">
                            <p className="text-sm font-medium text-[#81829E]">
                              Mobile
                            </p>
                            <p className="text-sm">
                              {data.shipping_address.mobile}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="p-[10px] divide-y-2 border-2 border-[#DFE5FF] rounded-lg mt-3">
                      <p className="pb-2 text-base font-semibold lineHeight100 gradient-text ">
                        Billing Address
                      </p>
                      <div className="pt-2 flex flex-col gap-2">
                        <ul className="w-full divide-y-2">
                          <li className="flex justify-between py-2">
                            <p className="text-sm font-medium text-[#81829E]">
                              Name
                            </p>
                            <p className="text-sm">
                              {data.shipping_address.name}
                            </p>
                          </li>
                          <li className="flex justify-between py-2">
                            <p className="text-sm font-medium text-[#81829E]">
                              Email
                            </p>
                            <p className="text-sm">
                              {data.shipping_address.email}
                            </p>
                          </li>
                          <li className="flex justify-between py-2">
                            <p className="text-sm font-medium text-[#81829E]">
                              Street
                            </p>
                            <p className="text-sm">
                              {data.shipping_address.streetAddress}
                            </p>
                          </li>
                          <li className="flex justify-between py-2">
                            <p className="text-sm font-medium text-[#81829E]">
                              City
                            </p>
                            <p className="text-sm">
                              {data.shipping_address.city}
                            </p>
                          </li>
                          <li className="flex justify-between py-2">
                            <p className="text-sm font-medium text-[#81829E]">
                              State
                            </p>
                            <p className="text-sm">
                              {data.shipping_address.state}
                            </p>
                          </li>
                          <li className="flex justify-between py-2">
                            <p className="text-sm font-medium text-[#81829E]">
                              Zip
                            </p>
                            <p className="text-sm">
                              {data.shipping_address.zip}
                            </p>
                          </li>
                          <li className="flex justify-between py-2">
                            <p className="text-sm font-medium text-[#81829E]">
                              Mobile
                            </p>
                            <p className="text-sm">
                              {data.shipping_address.mobile}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-2 border-[#F1F3FF] divide-y-1 px-4 py-3 rounded-lg shadow-2xl">
                  <h3 className="text-base font-medium lineHeight100 gradient-text pb-2">
                    Order summary
                  </h3>
                  <div className="pt-2">
                    <ul className="w-full flex flex-col gap-3 mb-3">
                      <li className="flex justify-between items-center">
                        <p className="text-sm font-medium lineHeight100 text-text">
                          Total Items
                        </p>
                        <p className="text-base font-semibold lineHeight100 text-[#67698A]">
                          {data?.quantity} Pieces
                        </p>
                      </li>
                      <li className="flex justify-between items-center">
                        <p className="text-sm font-medium lineHeight100 text-text">
                          Items Price
                        </p>
                        <p className="text-base font-semibold lineHeight100 text-[#67698A] flex items-center">
                          {data.currency_type === "USD" ? (
                            `$${data?.amount / data?.quantity}`
                          ) : (
                            <>
                              <FaEthereum className="text-lg" />
                              {(data?.amount / data?.quantity).toFixed(6)}
                            </>
                          )}
                        </p>
                      </li>
                      <li className="flex justify-between items-center">
                        <p className="text-sm font-medium lineHeight100 text-text">
                          Delivery Fee
                        </p>
                        <p className="text-base font-semibold lineHeight100 text-[#67698A] flex items-center">
                          {data.currency_type === "USD" ? (
                            `$${data?.shipping_charge}`
                          ) : (
                            <>
                              <FaEthereum className="text-lg" />
                              {data?.shipping_charge}
                            </>
                          )}
                        </p>
                      </li>
                      <li className="flex justify-between items-center">
                        <p className="text-sm font-medium lineHeight100 text-text">
                          Tax
                        </p>
                        <p className="text-base font-semibold lineHeight100 text-[#67698A] flex items-center">
                          {data.currency_type === "USD" ? (
                            `$${data?.tax_charge}`
                          ) : (
                            <>
                              <FaEthereum className="text-lg" />
                              {data?.tax_charge}
                            </>
                          )}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="pt-2">
                    <ul className="w-full flex flex-col gap-3 mb-3">
                      {/* <li className="flex justify-between items-center">
                <p className="text-sm font-medium lineHeight100 text-text">
                  Coupon%
                </p>
                <p className="text-base font-semibold lineHeight100 text-[#67698A]">
                  $3720,27
                </p>
              </li> */}
                      <li className="flex justify-between items-center">
                        <p className="text-sm font-medium lineHeight100 text-text">
                          Total
                        </p>
                        <p className="text-base font-semibold lineHeight100 text-[#67698A] flex items-center">
                          {data.currency_type === "USD" ? (
                            `$${data?.amount}`
                          ) : (
                            <>
                              <FaEthereum className="text-lg" />
                              {data?.amount.toFixed(6)}
                            </>
                          )}
                        </p>
                      </li>
                      <li className="flex justify-between items-center">
                        <p className="text-sm font-medium lineHeight100 text-text">
                          Payment
                        </p>
                        <p className="text-base font-semibold lineHeight100 text-[#67698A] flex items-center">
                          {data.currency_type === "USD" ? (
                            `$Paid`
                          ) : (
                            <>
                              <FaEthereum className="text-lg" />
                              Paid
                            </>
                          )}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <p className="text-base font-semibold lineHeight100 gradient-text mb-4 ">
                Product Details
              </p>
              <div className="shadow-2xl rounded-[20px] overflow-hidden">
                <table className="w-full bg-white rounded-[20px] overflow-hidden">
                  <thead className="bg-gradient ">
                    <tr>
                      <th className="font-medium text-white lineHeight100 text-sm lg:text-base p-3 lg:px-8 lg:py-4 text-left">
                        Product
                      </th>
                      {/* <th className="font-medium text-white lineHeight100 text-sm lg:text-base p-3 lg:px-8 lg:py-4 text-center">
                  Color
                </th> */}
                      <th className="font-medium text-white lineHeight100 text-sm lg:text-base p-3 lg:px-8 lg:py-4 text-center hidden md:table-cell">
                        Quantity
                      </th>
                      <th className="font-medium text-white lineHeight100 text-sm lg:text-base p-3 lg:px-8 lg:py-4 text-center">
                        Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-t-2 border-[#DFE5FF]">
                      <td className="text-text font-medium lineHeight100 text-sm lg:text-base p-3 lg:px-8 lg:py-4 text-left min-w-[180px] flex gap-4">
                        <img
                          src={data.thumbImage}
                          alt="product"
                          width={60}
                          height={60}
                          className="object-cover"
                        />
                        <p>{data.itemName}</p>
                      </td>
                      {/* <td className="text-text font-medium lineHeight100 text-sm lg:text-base p-3 lg:px-8 lg:py-4 text-left min-w-[180px]">
                  red
                </td> */}
                      <td className="text-text font-medium lineHeight100 text-sm lg:text-base p-3 lg:px-8 lg:py-4 text-left  min-w-[180px] text-center">
                        {data.quantity}
                      </td>
                      <td className="text-text font-medium lineHeight100 text-sm lg:text-base p-3 lg:px-8 lg:py-4 text-left  min-w-[180px]">
                        <div className="flex items-center justify-center">
                          {data.currency_type === "USD" ? (
                            `$${data?.amount}`
                          ) : (
                            <>
                              <FaEthereum className="text-lg flex items-center" />
                              {data?.amount.toFixed(6)}
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex justify-end gap-4 mt-8">
              {orderStatus !== "Delivered" && (
                <button
                  type="button"
                  className={`px-5 h-[46px] flex justify-center items-center text-base rounded-full text-[#F82814] bg-[#FFD4D0]`}
                  onClick={() => changeStatus("Cancelled")}
                >
                  Cancel
                </button>
              )}

              {orderStatus === "Place Order" ? (
                <button
                  type="submit"
                  className={`px-5 h-[46px] flex justify-center items-center bg-[#D4DCFF] text-base rounded-full text-[#163BDE]`}
                  onClick={() => changeStatus("Accepted")}
                >
                  Accept Order
                </button>
              ) : orderStatus === "Accepted" ? (
                <button
                  type="submit"
                  className={`px-5 h-[46px] flex justify-center items-center bg-[#FAD6E6] text-base rounded-full text-[#C31162]`}
                  onClick={() => changeStatus("Processing")}
                >
                  Make as Processing
                </button>
              ) : orderStatus === "Processing" ? (
                <button
                  type="submit"
                  className={`px-5 h-[46px] flex justify-center items-center bg-[#E9DCFE] text-base rounded-full text-[#2C0270]`}
                  onClick={() => changeStatus("Shipped")}
                >
                  Mark as Shipped
                </button>
              ) : orderStatus === "Shipped" ? (
                <button
                  type="submit"
                  className={`px-5 h-[46px] flex justify-center items-center bg-[#D2FFD4] text-base rounded-full text-[#0D9F16]`}
                  onClick={() => changeStatus("Delivered")}
                >
                  Mark as Delivered
                </button>
              ) : (
                orderStatus === "Delivered" && (
                  <button
                    type="submit"
                    className="px-5 h-[46px] flex justify-center items-center bg-[#D2FFD4] text-base rounded-full text-[#0D9F16]"
                  >
                    Delivered
                  </button>
                )
              )}
            </div>
          </div>
        </div>
      ) : (
        <LottieLoader />
      )}
    </Layout>
  );
}
