/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
};

const forgetSlice = createSlice({
  name: "forget",
  initialState,
  reducers: {
    setForget: (state, action) => {
      state.email = action.payload;
    },
    reset: () => initialState,
  },
});

export const { setForget, reset } = forgetSlice.actions;

export default forgetSlice.reducer;
