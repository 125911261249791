/* eslint-disable no-unreachable */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { MdBlock } from "react-icons/md";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import NotFound from "../../assets/images/not-found.svg";
import Icons from "../../components/Helpers/Icons";
import LottieLoader from "../../components/Helpers/LottieLoader";
import Layout from "../../components/Partials/Layout";
import useRequestFun from "../../hooks/Axios/useRequestFun";

export default function NftDetails() {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState(null);
  const [activities, setActivities] = useState();
  const [network, setNetwork] = useState();
  const { contract, id } = useParams();
  const { getRequestForUsers, getRequest, putRequest } = useRequestFun();
  useEffect(() => {
    getRequest(`getNftMetaData/${contract}/${id}`).then((res) => {
      setData(res.data.data);
      setStatus(res.data.data.status);
    });
    getRequestForUsers(`getTokenActivity/${contract}/${id}`).then((res) => {
      setActivities(res.data.data);
    });
    getRequest(`getNetWorks`).then(async (res) => {
      if (res.data.data[0]) {
        setNetwork(res.data.data[0]);
      }
    });
  }, []);
  const truncate = (str) =>
    // eslint-disable-next-line prefer-template
    str && str.length > 10 ? str.substring(0, 3) + "..." + str.slice(-3) : str;

  const changeNftStatus = (nftId, nftStatus) => {
    return toast.warning("Disabled For Demo Version!");
    putRequest(`changeNftStatus/${nftId}`, { status: nftStatus }).then(
      (res) => {
        toast.success("NFT Status Update Successful");
        setStatus(res.data.nft.status);
      }
    );
  };
  return (
    <Layout>
      {data ? (
        <div className="flex flex-wrap 2xl:flex-nowrap gap-3 lg:gap-6">
          <div className="flex flex-col gap-3 lg:gap-6 w-full 2xl:w-3/5 ">
            <div className="flex flex-wrap lg:flex-nowrap gap-6 bg-white p-5 rounded-xl shadow-lg">
              <img
                src={data?.media[0].gateway}
                alt="nft"
                className="rounded-lg shadow-xl w-full sm:w-6/12 lg:w-1/3"
              />
              <div className="flex flex-col flex-1">
                <div className="2xl:text-[28px] font-bold lineHeight100">
                  <div className="lineHeight150 text-text mb-2 text-xl lg:text-[28px] flex justify-between">
                    {data?.metadata?.name}
                    {status === "block" ? (
                      <button
                        type="submit"
                        className="w-[152px] h-[46px] flex justify-center items-center gap-2 text-white gradientButton text-base rounded-full"
                        onClick={() => {
                          changeNftStatus(data._id, "active");
                        }}
                      >
                        Active
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="w-[152px] h-[46px] flex justify-center items-center gap-2 bg-red-500  text-base rounded-full text-[#fff]"
                        onClick={() => {
                          changeNftStatus(data._id, "block");
                        }}
                      >
                        <MdBlock className="text-2xl" /> Block
                      </button>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-3 lg:gap-10">
                    <div className="flex gap-2">
                      <div className="relative w-10 h-10">
                        {data?.owner?.profile_image ? (
                          <a
                            href={`${network?.explorer_url}address/${data?.owner?.wallet_address}`}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <img
                              src={data?.owner?.profile_image}
                              alt="owner"
                              className="rounded-full object-cover absolute w-10 h-10"
                            />
                          </a>
                        ) : (
                          <img
                            src={NotFound}
                            alt="cover"
                            className="object-cover absolute rounded-full w-10 h-10"
                          />
                        )}
                        {/* <div className="absolute bottom-0 right-0 bg-gradient rounded-[50%] w-[10px] h-[10px] flex justify-center items-center lg:w-3 lg:h-3 lg:bottom-0 lg:right-0">
                      <FaCheck className="text-[6px] text-white lg:text-[8px]" />
                    </div> */}
                      </div>
                      <div>
                        <p className="text-sm lineHeight150 text-[#67698A] font-normal">
                          Owner
                        </p>

                        <p className="text-sm font-medium lineHeight150 text-text">
                          <a
                            href={`${network?.explorer_url}address/${data?.owner?.wallet_address}`}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {data?.owner?.first_name || data?.owner?.last_name
                              ? `${data?.owner?.first_name} ${data?.owner?.last_name}`
                              : truncate(data?.owner?.wallet_address)}
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="lineHeight150 text-text text-sm flex gap-2">
                      <div className="relative w-10 h-10">
                        {data?.collectionData?.image ? (
                          <a
                            href={`${network?.explorer_url}address/${data?.contract?.address}`}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {" "}
                            <img
                              // loader={() => data?.owner?.profile_image}
                              src={data?.collectionData?.image}
                              alt="owner "
                              className="rounded-full object-cover absolute w-10 h-10"
                            />
                          </a>
                        ) : (
                          <img
                            src={NotFound}
                            alt="cover "
                            className="object-cover rounded-full absolute w-10 h-10"
                          />
                        )}
                      </div>

                      <div>
                        <p className="text-[#67698A] font-normal">
                          Collection{" "}
                        </p>
                        <p>
                          <a
                            href={`${network?.explorer_url}address/${data?.contract?.address}`}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {data?.contractMetadata?.name
                              ? data?.contractMetadata?.name
                              : data?.collectionData.name}
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="text-[#4F6AF1] text-sm flex items-end gap-1 hover:text-[#9B33B5] cursor-pointer">
                      <a
                        href={data?.tokenUri?.raw}
                        target="_blank"
                        rel="noreferrer"
                        className=" flex gap-1"
                      >
                        <FaExternalLinkAlt />
                        View Matadata
                      </a>
                    </div>
                  </div>
                </div>
                <div className="py-5 text-base text-lighter-gray">
                  {data?.description}
                </div>
              </div>
            </div>

            {/* <div className="flex flex-col shadow-xl rounded-lg bg-white">
            <div className="px-3 py-5 text-2xl font-semibold bg-purple rounded-t-lg  text-white">
              <p>Description</p>
            </div>
            <div className="px-3 py-5">{data?.description}</div>
          </div> */}
            <div className="flex flex-col shadow-xl rounded-lg bg-white">
              <div className="px-3 py-3 lg:py-5 text-2xl font-semibold bg-purple rounded-t-lg text-white">
                <p>Details</p>
              </div>
              <div className="px-3 py-5">
                <ul className="list-none text-[#67698A]">
                  <li className="flex justify-between text-base font-medium lineHeight150 mb-2">
                    Contact Address{" "}
                    <span>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://mumbai.polygonscan.com/address/0xf856a1957e844d35e457994f2b49551a672f4dba"
                      >
                        {truncate(data?.contract.address)}
                      </a>
                    </span>
                  </li>
                  <li className="flex justify-between text-base font-medium lineHeight150 mb-2">
                    Token ID{" "}
                    <span>
                      {truncate(
                        ethers.BigNumber.from(data?.id?.tokenId).toString()
                      )}
                    </span>
                  </li>
                  <li className="flex justify-between text-base font-medium lineHeight150 mb-2">
                    Token Standard{" "}
                    <span>{data?.contractMetadata.tokenType}</span>
                  </li>
                  {/* <li className="flex justify-between text-base font-medium lineHeight150 mb-2">
                Chain <span>{data?.tokenMetadata.tokenType}</span>
              </li> */}
                  <li className="flex justify-between text-base font-medium lineHeight150 mb-2">
                    Creator Fee <span>{data?.metadata.creator_fees}%</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="w-full 2xl:w-2/5 flex">
            <div className="flex flex-col shadow-xl rounded-lg bg-white w-full">
              <div className="px-3 py-3 lg:py-5 text-2xl font-semibold bg-pink rounded-t-lg  text-white">
                <p>Item Activity</p>
              </div>
              <div className="max-h-[400px]  overflow-auto">
                <div className="flex flex-col divide-y-2 divide-gray-200 py-2 px-3 lg:py-5 lg:px-5 ">
                  {activities?.length > 0 ? (
                    activities?.map((item) => (
                      <div
                        className="md:py-4 flex items-center gap-3"
                        key={item._id}
                      >
                        <div className="max-w-[100px]">
                          <Icons
                            name={
                              item.activity_name === "Listed" ||
                              item.activity_name === "ListCanceled" ||
                              item.activity_name === "ListUpdate"
                                ? "listing"
                                : item.activity_name === "Mint"
                                ? "art"
                                : item.activity_name === "Transfer"
                                ? "transfer"
                                : "art"
                            }
                            width={30}
                          />
                        </div>
                        <div>
                          <div className="flex items-center gap-3">
                            <div className=" text-[#81829E] text-xs lg:text-sm font-medium lineHeight150">
                              {item.activity_name}
                            </div>
                            <p className="text-text text-base font-bold lineHeight150">
                              {item?.from_address
                                ? `${item.from_address.substring(
                                    0,
                                    3
                                  )}..${item.from_address.slice(-2)}`
                                : "0x0..00"}
                            </p>
                          </div>
                          <div className="flex flex-wrap md:mt-2 md:divide-x-2 md:divide-gray-700">
                            <div className="flex pr-3">
                              <div
                                className={`flex gap-3 items-center ${
                                  item?.to_address && "pr-3"
                                }`}
                              >
                                {item?.to_address && (
                                  <>
                                    <span className="text-xs lg:text-sm font-medium lineHeight150 text-[#81829E]">
                                      By
                                    </span>
                                    <p>
                                      {item?.to_address
                                        ? `${item.to_address.substring(
                                            0,
                                            5
                                          )}...${item.to_address.slice(-2)}`
                                        : ""}
                                    </p>
                                  </>
                                )}
                              </div>
                              <div>
                                <span className="text-xs lg:text-sm font-medium lineHeight150 text-[#81829E]">
                                  Price:{" "}
                                </span>
                                {`${item.price}  ${network?.symbol}`}
                              </div>
                            </div>
                            <div className="text-[#81829E] md:pl-3">
                              <span className="text-xs lg:text-sm font-medium lineHeight150 ">
                                Date:{" "}
                              </span>
                              {new Date(item.createdAt).toLocaleString("en-US")}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <span>Not Found</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LottieLoader />
      )}
    </Layout>
  );
}
