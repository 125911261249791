/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
/* eslint-disable no-else-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import AWS from "aws-sdk";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import profile from "../../../assets/images/Loading_icon.gif";
// import logo from "../../../assets/images/logo.svg";
import useRequestFun from "../../../hooks/Axios/useRequestFun";
import InputCom from "../../Helpers/Inputs";
// import SelectBox from "../../Helpers/SelectBox";

export default function GeneralSettingsTab({ setIsLoader }) {
  // const [profileImg, setProfileImg] = useState(profile);
  const [webImage, setWebImage] = useState(null);
  const [faviconImg, setFaviconImg] = useState(null);
  const [footerImage, setFooterImage] = useState(null);

  const [webImageFiles, setWebImageFiles] = useState(null);
  const [faviconImgFiles, setFaviconImgFiles] = useState(null);
  const [footerImageFiles, setFooterImageFiles] = useState(null);
  // const profileImgInput = useRef(null);
  const webImageInput = useRef(null);
  const faviconImgInput = useRef(null);
  const footerImageInput = useRef(null);
  const [email, setEmail] = useState("");
  const { getRequest, putRequest } = useRequestFun();
  const [messages, setMessages] = useState({});
  const imageMimeType = /image\/(png|jpg|jpeg|svg)/i;
  const [address, setAddress] = useState("");
  const [helplineNumber, setHelplineNumber] = useState("");
  const [latLong, setLatLong] = useState("");
  const [mimeType, setMimeType] = useState();
  // const [generalData, setGeneralData] = useState("");
  const [settingId, setSettingId] = useState("");

  useEffect(() => {
    getRequest("generalsetting").then((res) => {
      setEmail(res.data.data.email);
      setAddress(res.data.data.address);
      setHelplineNumber(res.data.data.helpline_number);
      setLatLong(res.data.data.latitude_longitude);
      setWebImage(res.data.data.web_logo);
      // imagesObj.web_logo = res.data.data.web_logo;
      // imagesObj.favicon = res.data.data.favicon;
      // imagesObj.footer_logo = res.data.data.footer_logo;
      setFaviconImg(res.data.data.favicon);
      setFooterImage(res.data.data.footer_logo);
      setSettingId(res.data.data._id);
    });
  }, []);
  const browseWebLogoImg = () => {
    webImageInput.current.click();
  };
  const browseFaviconImg = () => {
    faviconImgInput.current.click();
  };
  const browseFooterLogoImg = () => {
    footerImageInput.current.click();
  };
  const profileImgChangHandler = (e, type) => {
    e.preventDefault();
    return toast.warning("Disabled For Demo Version!");
    if (e.target.value !== "") {
      const imgReader = new FileReader();
      if (e.target.files[0].size > 5000000) {
        setMessages({ image: "Image Size Not More Than 5MB" });
        return;
      } else {
        setMessages({ image: "" });
      }
      setMimeType(e.target.files[0].type);
      if (!e.target.files[0].type.match(imageMimeType)) {
        setMessages({ image: "Image mime type is not valid" });
        return;
      } else {
        setMessages({ image: "" });
      }
      if (type === "webLogo") {
        setWebImageFiles(e.target.files[0]);
      }
      if (type === "favicon") {
        setFaviconImgFiles(e.target.files[0]);
      }
      if (type === "footerLogo") {
        setFooterImageFiles(e.target.files[0]);
      }
      imgReader.onload = (event) => {
        if (type === "webLogo") {
          setWebImage(event.target.result);
        }
        if (type === "favicon") {
          setFaviconImg(event.target.result);
        }
        if (type === "footerLogo") {
          setFooterImage(event.target.result);
        }
      };

      imgReader.readAsDataURL(e.target.files[0]);
    }
  };
  const generalSettingsAdd = async (e) => {
    e.preventDefault();
    return toast.warning("Disabled For Demo Version!");

    setIsLoader(true);
    let isValid = true;
    let webImageS3 = "";
    let faviconImgS3 = "";
    let footerImageS3 = "";
    if (address === "") {
      setMessages({ address: "Address is required" });
      isValid = false;
    } else if (helplineNumber === "") {
      setMessages({ helplineNumber: "Help Line Number is required" });
      isValid = false;
    } else if (email === "") {
      setMessages({ email: "Email is required" });
      isValid = false;
    } else if (latLong === "") {
      setMessages({ latLong: "Latitude Longitude is required" });
      isValid = false;
    } else if (webImage === null) {
      setMessages({ webImage: "Web Logo is required" });
      isValid = false;
    } else if (faviconImg === null) {
      setMessages({ faviconImg: "Favicon Image is required" });
      isValid = false;
    } else if (footerImage === null) {
      setMessages({ footerImage: "Footer Logo is required" });
      isValid = false;
    }

    if (!isValid) {
      setIsLoader(false);
      return;
    }
    AWS.config.update({
      region: process.env.REACT_APP_AWS_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    });
    let s3 = new AWS.S3({
      apiVersion: "2006-03-01",
    });

    if (webImageFiles) {
      const uploadParams = {
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        // eslint-disable-next-line dot-notation
        Key: `admin/${webImageFiles.name}`,
        Body: webImageFiles,
        Acl: "public-read",
        ContentType: mimeType,
      };
      const uploadedImg = await s3.upload(uploadParams).promise();
      webImageS3 = uploadedImg.Location;
      // s3.upload(uploadParams, (err, data) => {
      //   if (err) {
      //     toast.error(`${err}`);
      //   } else {
      //     webImageS3 = data.Location;
      //   }
      // });
    }
    if (faviconImgFiles) {
      const uploadParams = {
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        // eslint-disable-next-line dot-notation
        Key: `admin/${faviconImgFiles.name}`,
        Body: faviconImgFiles,
        Acl: "public-read",
        ContentType: mimeType,
      };
      const uploadedImg = await s3.upload(uploadParams).promise();
      faviconImgS3 = uploadedImg.Location;
    }
    if (footerImageFiles) {
      const uploadParams = {
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        // eslint-disable-next-line dot-notation
        Key: `admin/${footerImageFiles.name}`,
        Body: footerImageFiles,
        Acl: "public-read",
        ContentType: mimeType,
      };
      const uploadedImg = await s3.upload(uploadParams).promise();
      footerImageS3 = uploadedImg.Location;
    }

    putRequest(`updategeneralsetting/${settingId}`, {
      address,
      helpline_number: helplineNumber,
      email,
      web_logo: webImageS3 !== "" ? webImageS3 : webImage,
      favicon: faviconImgS3 !== "" ? faviconImgS3 : faviconImg,
      footer_logo: footerImageS3 !== "" ? footerImageS3 : footerImage,
      latitude_longitude: latLong,
    })
      .then((res) => {
        toast.success(`${res.data.message}`, {
          icon: `🙂`,
        });
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        toast.error(`${error.response.data.message}`);
      });
  };
  return (
    <>
      <div className="settings-wrapper w-full relative mb-10">
        <div className="mainWrapper w-full">
          {/* heading */}

          <div className="content-container w-full rounded-2xl bg-white">
            <div className="content-body-items flex-1">
              <form onSubmit={generalSettingsAdd}>
                <div className="personal-info-tab w-full flex flex-col justify-between">
                  <div className="flex flex-col-reverse sm:flex-row">
                    <div className="flex-1 sm:mr-10">
                      <div className="fields w-full">
                        {/* first name and last name */}
                        <div className="xl:flex xl:space-x-7 mb-6">
                          <div className="field w-full mb-6 xl:mb-0">
                            <InputCom
                              label="Address"
                              type="text"
                              name="address"
                              inputHandler={(e) => setAddress(e.target.value)}
                              placeholder="Address"
                              value={address !== "" ? address : ""}
                            />
                            <div className="text-red-500">
                              {messages.address}
                            </div>
                          </div>
                          <div className="field w-full">
                            <InputCom
                              label="Helpline Number"
                              type="text"
                              name="helplineNumber"
                              inputHandler={(e) =>
                                setHelplineNumber(e.target.value)
                              }
                              placeholder="Helpline Number"
                              value={
                                helplineNumber !== "" ? helplineNumber : ""
                              }
                            />

                            <div className="text-red-500">
                              {messages.helplineNumber}
                            </div>
                          </div>
                        </div>
                        <div className="xl:flex xl:space-x-7 mb-6">
                          <div className="field w-full">
                            <InputCom
                              label="Email"
                              type="email"
                              name="email"
                              inputHandler={(e) => setEmail(e.target.value)}
                              placeholder="admin@example.com"
                              value={email !== "" ? email : ""}
                            />
                            <div className="text-red-500">{messages.email}</div>
                          </div>
                          <div className="field w-full mb-6 xl:mb-0">
                            <InputCom
                              label="Latitude Longitude"
                              type="text"
                              name="latitude_longitude"
                              inputHandler={(e) => setLatLong(e.target.value)}
                              placeholder="Latitude Longitude"
                              value={latLong !== "" ? latLong : ""}
                            />

                            <div className="text-red-500">
                              {messages.latLong}
                            </div>
                          </div>
                        </div>

                        {/* User Name */}
                        {/* <div className="xl:flex xl:space-x-7 mb-6">
                        <div className="field w-full mb-6 xl:mb-0">
                          <div className="chart-heading mb-4 xl:flex justify-between items-center">
                            <SelectBox
                              label="Gender"
                              name="Gender"
                              datas={genderData}
                              action={(value) => setGender(value)}
                              selectedValue={gender}
                            />
                          </div>
                          <div className="text-red-500">{messages.gender}</div>
                        </div>
                        <div className="field w-full">
                          <InputCom
                            label="Role"
                            type="text"
                            name="Role"
                            value={adminRole}
                            readOnlyValue="true"
                            placeholder="Admin Role"
                          />
                        </div>
                      </div> */}
                        {/* <div className="xl:flex xl:space-x-7 mb-6">
                        <div className="field w-full mb-6 xl:mb-0">
                          <InputCom
                            label="Phone"
                            type="text"
                            name="phone"
                            inputHandler={(e) => setPhone(e.target.value)}
                            value={phone}
                            placeholder="+880124365464"
                          />
                          <div className="text-red-500">{messages.phone}</div>
                        </div>
                        <div className="field w-full mb-6 xl:mb-0">
                          {status && (
                            <>
                              <label className="input-label text-dark-gray text-xl font-bold block mb-2.5">
                                Status
                              </label>
                              <div className="flex">
                                <div className="bg-green-600 rounded-full px-5 py-2 text-white">
                                  Active
                                </div>
                              </div>
                            </>
                          )}

                          <div className="text-red-500">{messages.status}</div>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex flex-wrap mb-10">
                    <div className="update-profile md:w-4/12 mb-9 p-3">
                      <h1 className="text-xl tracking-wide font-bold text-dark-gray flex items-center mb-2">
                        Web Logo
                      </h1>
                      <p className="text-base text-thin-light-gray mb-5">
                        {/* Profile of at least Size
                          <span className="ml-1 text-dark-gray">300x300</span>. */}
                        PNG, JPG or JPEG only
                        <span className="ml-1 text-dark-gray">Max 5mb</span>.
                      </p>
                      <div className="flex justify-left">
                        <div className="relative">
                          <img
                            src={webImage !== null ? webImage : profile}
                            alt=""
                            className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] overflow-hidden object-contain profile_image"
                          />
                          {/* <Svg
                            url={logo}
                            className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] rounded-full overflow-hidden object-cover profile_image"
                          /> */}
                          <input
                            ref={webImageInput}
                            onChange={(e) =>
                              profileImgChangHandler(e, "webLogo")
                            }
                            type="file"
                            className="hidden"
                          />
                          <div
                            onClick={browseWebLogoImg}
                            className="w-[32px] h-[32px] absolute bottom-7 right-0 hover:bg-pink bg-dark-gray rounded-full cursor-pointer"
                          >
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.5147 11.5C17.7284 12.7137 18.9234 13.9087 20.1296 15.115C19.9798 15.2611 19.8187 15.4109 19.6651 15.5683C17.4699 17.7635 15.271 19.9587 13.0758 22.1539C12.9334 22.2962 12.7948 22.4386 12.6524 22.5735C12.6187 22.6034 12.5663 22.6296 12.5213 22.6296C11.3788 22.6334 10.2362 22.6297 9.09365 22.6334C9.01498 22.6334 9 22.6034 9 22.536C9 21.4009 9 20.2621 9.00375 19.1271C9.00375 19.0746 9.02997 19.0109 9.06368 18.9772C10.4123 17.6249 11.7609 16.2763 13.1095 14.9277C14.2295 13.8076 15.3459 12.6913 16.466 11.5712C16.4884 11.5487 16.4997 11.5187 16.5147 11.5Z"
                                fill="white"
                              />
                              <path
                                d="M20.9499 14.2904C19.7436 13.0842 18.5449 11.8854 17.3499 10.6904C17.5634 10.4694 17.7844 10.2446 18.0054 10.0199C18.2639 9.76139 18.5261 9.50291 18.7884 9.24443C19.118 8.91852 19.5713 8.91852 19.8972 9.24443C20.7251 10.0611 21.5492 10.8815 22.3771 11.6981C22.6993 12.0165 22.7105 12.4698 22.3996 12.792C21.9238 13.2865 21.4443 13.7772 20.9686 14.2717C20.9648 14.2792 20.9536 14.2867 20.9499 14.2904Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="text-red-500">{messages.webImage}</div>
                    </div>
                    <div className="update-profile md:w-4/12 mb-9 p-3">
                      <h1 className="text-xl tracking-wide font-bold text-dark-gray flex items-center mb-2">
                        Favicon
                      </h1>
                      <p className="text-base text-thin-light-gray mb-5">
                        {/* Profile of at least Size
                          <span className="ml-1 text-dark-gray">300x300</span>. */}
                        PNG, JPG or JPEG only
                        <span className="ml-1 text-dark-gray">Max 5mb</span>.
                      </p>
                      <div className="flex justify-left">
                        <div className="relative">
                          <img
                            src={faviconImg !== null ? faviconImg : profile}
                            alt=""
                            className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] overflow-hidden object-contain profile_image"
                          />
                          <input
                            ref={faviconImgInput}
                            onChange={(e) =>
                              profileImgChangHandler(e, "favicon")
                            }
                            type="file"
                            className="hidden"
                          />
                          <div
                            onClick={browseFaviconImg}
                            className="w-[32px] h-[32px] absolute bottom-7 right-0 hover:bg-pink bg-dark-gray rounded-full cursor-pointer"
                          >
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.5147 11.5C17.7284 12.7137 18.9234 13.9087 20.1296 15.115C19.9798 15.2611 19.8187 15.4109 19.6651 15.5683C17.4699 17.7635 15.271 19.9587 13.0758 22.1539C12.9334 22.2962 12.7948 22.4386 12.6524 22.5735C12.6187 22.6034 12.5663 22.6296 12.5213 22.6296C11.3788 22.6334 10.2362 22.6297 9.09365 22.6334C9.01498 22.6334 9 22.6034 9 22.536C9 21.4009 9 20.2621 9.00375 19.1271C9.00375 19.0746 9.02997 19.0109 9.06368 18.9772C10.4123 17.6249 11.7609 16.2763 13.1095 14.9277C14.2295 13.8076 15.3459 12.6913 16.466 11.5712C16.4884 11.5487 16.4997 11.5187 16.5147 11.5Z"
                                fill="white"
                              />
                              <path
                                d="M20.9499 14.2904C19.7436 13.0842 18.5449 11.8854 17.3499 10.6904C17.5634 10.4694 17.7844 10.2446 18.0054 10.0199C18.2639 9.76139 18.5261 9.50291 18.7884 9.24443C19.118 8.91852 19.5713 8.91852 19.8972 9.24443C20.7251 10.0611 21.5492 10.8815 22.3771 11.6981C22.6993 12.0165 22.7105 12.4698 22.3996 12.792C21.9238 13.2865 21.4443 13.7772 20.9686 14.2717C20.9648 14.2792 20.9536 14.2867 20.9499 14.2904Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="text-red-500">{messages.faviconImg}</div>
                    </div>
                    <div className="update-profile md:w-4/12 mb-9 p-3">
                      <h1 className="text-xl tracking-wide font-bold text-dark-gray flex items-center mb-2">
                        Footer Logo
                      </h1>
                      <p className="text-base text-thin-light-gray mb-5">
                        {/* Profile of at least Size
                          <span className="ml-1 text-dark-gray">300x300</span>. */}
                        PNG, JPG or JPEG only
                        <span className="ml-1 text-dark-gray">Max 5mb</span>.
                      </p>
                      <div className="flex justify-left">
                        <div className="relative">
                          <img
                            src={footerImage !== null ? footerImage : profile}
                            alt=""
                            className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] overflow-hidden object-contain profile_image"
                          />
                          <input
                            ref={footerImageInput}
                            onChange={(e) =>
                              profileImgChangHandler(e, "footerLogo")
                            }
                            type="file"
                            className="hidden"
                          />
                          <div
                            onClick={browseFooterLogoImg}
                            className="w-[32px] h-[32px] absolute bottom-7 right-0 hover:bg-pink bg-dark-gray rounded-full cursor-pointer"
                          >
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.5147 11.5C17.7284 12.7137 18.9234 13.9087 20.1296 15.115C19.9798 15.2611 19.8187 15.4109 19.6651 15.5683C17.4699 17.7635 15.271 19.9587 13.0758 22.1539C12.9334 22.2962 12.7948 22.4386 12.6524 22.5735C12.6187 22.6034 12.5663 22.6296 12.5213 22.6296C11.3788 22.6334 10.2362 22.6297 9.09365 22.6334C9.01498 22.6334 9 22.6034 9 22.536C9 21.4009 9 20.2621 9.00375 19.1271C9.00375 19.0746 9.02997 19.0109 9.06368 18.9772C10.4123 17.6249 11.7609 16.2763 13.1095 14.9277C14.2295 13.8076 15.3459 12.6913 16.466 11.5712C16.4884 11.5487 16.4997 11.5187 16.5147 11.5Z"
                                fill="white"
                              />
                              <path
                                d="M20.9499 14.2904C19.7436 13.0842 18.5449 11.8854 17.3499 10.6904C17.5634 10.4694 17.7844 10.2446 18.0054 10.0199C18.2639 9.76139 18.5261 9.50291 18.7884 9.24443C19.118 8.91852 19.5713 8.91852 19.8972 9.24443C20.7251 10.0611 21.5492 10.8815 22.3771 11.6981C22.6993 12.0165 22.7105 12.4698 22.3996 12.792C21.9238 13.2865 21.4443 13.7772 20.9686 14.2717C20.9648 14.2792 20.9536 14.2867 20.9499 14.2904Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="text-red-500">{messages.footerImage}</div>
                    </div>
                  </div>
                  <div className="content-footer w-full">
                    <div className="w-full h-[120px] border-t border-light-purple flex justify-end items-center">
                      <div className="flex space-x-4 mr-9">
                        <button
                          type="submit"
                          // onClick={generalSettingsAdd}
                          className="w-[152px] h-[46px] flex justify-center items-center gradientButton text-base rounded-full text-white"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
