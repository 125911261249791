import React from "react";
import ForgotPassword from "../components/Auth/ForgotPassword";

export default function ForgotPasswordPages() {
  return (
    <>
      <ForgotPassword />
    </>
  );
}
