import React from "react";
import AdminUserList from "../../components/Admin/AdminUserList";
import Layout from "../../components/Partials/Layout";

export default function AdminList() {
  return (
    <>
      <Layout>
        <AdminUserList />
      </Layout>
    </>
  );
}
