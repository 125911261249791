import React from "react";
// import QuickCounterChart from "../Charts/QuickCounterChart";

export default function HistoryAnalyticsCard({
  title,
  numberOfCount,
  // numberOfAnalyse,
  numberOfCollectables,
  numberOfCreated,
  numberOfFixed,
  numberOfActive,
  numberOfActiveAuction,
  numberOfActiveBid,
  numberOfListingFee,
  numberOfBuyFee,
  numberOfMintOnSystem,
  numberOfTotalSell,
  children,
  numberOfAccept,
  productCard,
  productCardValue,
  numberOfOnChainCollection,
  numberOfLazyChainCollection,
  // primaryColor,
  iconBg,
}) {
  return (
    <div className="item w-full">
      <div className="bg-white rounded-xl w-full h-auto p-5 hover:shadow-lg transition">
        <div className="flex flex-col justify-between">
          <div className="flex flex-col gap-4 space-x-[6px] justify-center pb-2">
            <div className="flex gap-4">
              <div
                className="w-[50px] h-[50px] flex justify-center items-center rounded-full"
                style={{ background: `${iconBg}` }}
              >
                {children && children}
              </div>
              <div className="flex flex-col justify-center">
                <p className="text-26 font-bold tracking-wide text-dark-gray">
                  {numberOfCount}
                </p>
                <p className="text-base tracking-wide text-thin-light-gray whitespace-nowrap">
                  {title}
                </p>
              </div>
            </div>

            <div>
              <p className="text-sm tracking-wide min-h-[18px] flex gap-4">
                {numberOfTotalSell && (
                  <>
                    <span>Total Sell: {numberOfTotalSell}</span>
                  </>
                )}
                {numberOfMintOnSystem && (
                  <>
                    <span>Minted on System: {numberOfMintOnSystem}</span>
                  </>
                )}

                {numberOfCollectables && (
                  <div className="flex gap-3 divide-x-2">
                    <div className="flex gap-2">
                      <span>Col: {numberOfCollectables}</span>
                      <span>Cre: {numberOfCreated}</span>
                    </div>
                    <div className="flex gap-2 pl-2">
                      <span>Chain: {numberOfOnChainCollection}</span>
                      <span>Lazy: {numberOfLazyChainCollection}</span>
                    </div>
                  </div>
                )}
                {numberOfFixed && (
                  <>
                    <span>Fixed: {numberOfFixed}</span>
                    <span>Active: {numberOfActive}</span>
                  </>
                )}
                {numberOfActiveAuction && (
                  <>
                    <span>Active: {numberOfActiveAuction}</span>
                    <span>Bid: {numberOfActiveBid}</span>
                  </>
                )}
                {numberOfListingFee < !0 && (
                  <>
                    <span>L: {numberOfListingFee.toFixed(4)}</span>
                    <span>B: {numberOfBuyFee.toFixed(4)}</span>
                  </>
                )}
                {productCard === "SaleItem" && (
                  <>
                    <span>Sale Item: {productCardValue}</span>
                  </>
                )}
                {productCard === "CompletedOrder" && (
                  <>
                    <span>Completed Order: {productCardValue}</span>
                  </>
                )}
                {numberOfAccept && <span>Accepted: {numberOfAccept}</span>}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
