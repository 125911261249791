import React from "react";
import AdminWallet from "../components/AdminWallet/AdminWallet";

export default function Wallet() {
  return (
    <>
      <AdminWallet />
    </>
  );
}
