import React from "react";
import Home from "../components/Home";

export default function HomePages() {
  return (
    <>
      <Home />
    </>
  );
}
