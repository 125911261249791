/* eslint-disable no-unreachable */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/extensions */
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DefaultImg from "../../assets/images/auth-profile-picture.png";
// import "suneditor/dist/css/suneditor.min.css";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// Require Editor JS files.
import LottieLoader from "../../components/Helpers/LottieLoader";
import Layout from "../../components/Partials/Layout";

import useRequestFun from "../../hooks/Axios/useRequestFun";
import useFileUpload from "../../hooks/useFileUpload";

// Require Editor CSS files.

import Editor from "../../components/Editor/Editor";
import InputCom from "../../components/Helpers/Inputs";
import RadioInput from "../../components/Helpers/RadioButton";

export default function UpdateBlog() {
  const [isLoader, setIsLoader] = useState(true);
  const [title, setTitle] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [status, setStatus] = useState("active");
  const [metaDescription, setMetaDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [blogDescription, setBlogDescription] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [profileImg, setProfileImg] = useState();
  const [thumbnailImg, setThumbnailImg] = useState();
  // const [image, setImage] = useState();
  const profileImgInput = useRef(null);
  const thumbnailImgInput = useRef(null);
  const imageMimeType = /image\/(png|jpg|jpeg)/i;
  const [image, setImage] = useState("");
  const [thumb, setThumb] = useState("");
  const [newImage, setNewImage] = useState(false);
  const [newThumbImage, setNewThumbImage] = useState(false);
  const [mimeType, setMimeType] = useState("");
  const { putRequest, getRequest } = useRequestFun();
  const { fileUpload } = useFileUpload();
  const location = useLocation();
  const navigate = useNavigate();
  const [messages, setMessage] = useState({
    title: "",
    blogDescription: "",
    image: "",
    shortDescription: "",
    thumbnailImage: "",
  });
  useEffect(() => {
    getRequest(`getblogbyid/${location.state.id}`).then((res) => {
      setTitle(res.data.data.title);
      setImage(res.data.data.headerImage);
      setThumb(res.data.data.thumbImage);
      setProfileImg(res.data.data.headerImage);
      setThumbnailImg(res.data.data.thumbImage);
      setBlogDescription(res.data.data.description);
      setMetaTitle(res.data.data.metaTitle);
      setMetaDescription(res.data.data.metaDescription);
      setShortDescription(res.data.data.shortDescription);
      setStatus(res.data.data.status);
      setOrderNumber(res.data.data.order);
      setIsLoader(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const browseProfileImg = () => {
    profileImgInput.current.click();
  };
  const profileImgChangHandler = (e) => {
    if (e.target.value !== "") {
      const imgReader = new FileReader();
      if (e.target.files[0].size > 5000000) {
        setMessage({ image: "Image Size Not More Than 5MB" });
        return;
      }
      setMessage({ image: "" });

      if (!e.target.files[0].type.match(imageMimeType)) {
        setMessage({ image: "Image mime type is not valid" });
        return;
      }
      setMessage({ image: "" });
      setMimeType(e.target?.files[0]?.type);
      setImage(e.target.files[0]);
      setNewImage(true);
      imgReader.onload = (event) => {
        setProfileImg(event.target.result);
      };
      imgReader.readAsDataURL(e.target.files[0]);
    }
  };

  const browsThumbnailImg = () => {
    thumbnailImgInput.current.click();
  };

  const thumbnailImgChangHandler = (e) => {
    if (e.target.value !== "") {
      const imgReader = new FileReader();
      if (e.target.files[0].size > 5000000) {
        setMessage({ image: "Image Size Not More Than 5MB" });
        return;
      }
      setMessage({ image: "" });

      if (!e.target.files[0].type.match(imageMimeType)) {
        setMessage({ image: "Image mime type is not valid" });
        return;
      }
      setMessage({ image: "" });
      setMimeType(e.target?.files[0]?.type);
      setThumb(e.target.files[0]);
      setNewThumbImage(true);
      imgReader.onload = (event) => {
        setThumbnailImg(event.target.result);
      };
      imgReader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    return toast.warning("Disabled For Demo Version!");
    let isValid = true;
    if (title === "") {
      setMessage({ title: "Title is required" });
      isValid = false;
    } else if (shortDescription === "") {
      setMessage({ shortDescription: "Short description is required" });
      isValid = false;
    } else if (blogDescription === "") {
      setMessage({ blogDescription: "Blog description is required" });
      isValid = false;
    } else if (status === "") {
      setMessage({ status: "Status is required" });
      isValid = false;
    } else if (image === "") {
      setMessage({ image: "Image is required" });
      isValid = false;
    } else if (thumb === "") {
      setMessage({ thumbnailImage: "Image is required" });
      isValid = false;
    } else {
      setMessage({});
    }
    let fileUrl = "";
    if (image && newImage) {
      fileUrl = await fileUpload(image, "admin", mimeType);
    }

    let thumbUrl = "";
    if (thumb && newThumbImage) {
      thumbUrl = await fileUpload(thumb, "admin", mimeType);
    }

    if (isValid) {
      setIsLoader(true);
      putRequest(`updateblogbyid/${location.state.id}`, {
        title,
        headerImage: newImage ? fileUrl : image,
        thumbImage: newThumbImage ? thumbUrl : thumb,
        status,
        description: blogDescription.editorHtml,
        metaTitle,
        metaDescription,
        shortDescription,
        orderNumber,
      })
        .then((res) => {
          toast.success(res.data.message);
          setIsLoader(false);
          navigate("../blog-list");
        })
        .catch((error) => {
          toast.warning(error.response.data.message);
          setIsLoader(false);
        });
    }
  };
  return (
    <Layout>
      {isLoader ? (
        <LottieLoader />
      ) : (
        <div className="settings-wrapper w-full relative mb-10">
          <div className="mainWrapper w-full">
            <div className="content-container w-full rounded-2xl bg-white">
              <div className="content-body-items flex-1 p-10">
                <div className="heading w-full mb-6">
                  <h1 className="text-26 font-bold text-dark-gray antialiased">
                    Update Blog
                  </h1>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="personal-info-tab w-full flex flex-col justify-between">
                    <div className="flex flex-col-reverse sm:flex-row">
                      <div className="flex-1 sm:mr-10">
                        <div className="fields w-full">
                          {/* first name and last name */}
                          <div className="xl:flex flex-col gap-6 mb-6">
                            <div className="field w-full mb-6 xl:mb-0">
                              <InputCom
                                label="Title"
                                type="text"
                                name="title"
                                inputHandler={(e) => setTitle(e.target.value)}
                                value={title}
                                placeholder="Enter Blog Title Here"
                                //   error={messages.name ? true : false}
                              />
                              <small className="text-red-400 ml-6 italic font-bold">
                                {messages.title}
                              </small>
                            </div>
                            <div className="field w-full mb-6 xl:mb-0">
                              <InputCom
                                label="Meta Title"
                                type="text"
                                name="metaTitle"
                                inputHandler={(e) =>
                                  setMetaTitle(e.target.value)
                                }
                                value={metaTitle}
                                notRequired
                                placeholder="Enter Meta Title Here"
                                //   error={messages.name ? true : false}
                              />
                              {/* <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.name}
                                </small> */}
                            </div>
                            <div className="field w-full mb-6 xl:mb-0">
                              <label
                                className="input-label text-dark-gray text-xl font-bold block mb-2.5"
                                htmlFor="metaDescription"
                              >
                                Meta Description
                              </label>
                              <textarea
                                value={metaDescription}
                                onChange={(e) => {
                                  setMetaDescription(e.target.value);
                                }}
                                placeholder="Enter Short Description"
                                rows="7"
                                className="w-full h-full px-7 py-4  border border-light-purple rounded-[20px] text-dark-gray bg-[#FAFAFA] focus:ring-0 focus:outline-none"
                              />
                              {/* <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.name}
                                </small> */}
                            </div>
                            <div className="field w-full mb-6 xl:mb-0">
                              <label
                                className="input-label text-dark-gray text-xl font-bold block mb-2.5"
                                htmlFor="metaDescription"
                              >
                                Short Description
                                <small className="text-red-400">*</small>
                              </label>
                              <textarea
                                value={shortDescription}
                                onChange={(e) => {
                                  setShortDescription(e.target.value);
                                }}
                                placeholder="Enter Meta Description"
                                rows="7"
                                className="w-full h-full px-7 py-4  border border-light-purple rounded-[20px] text-dark-gray bg-[#FAFAFA] focus:ring-0 focus:outline-none"
                              />
                              <small className="text-red-400 ml-6 italic font-bold">
                                {messages.shortDescription}
                              </small>
                            </div>
                            <div className="field w-full">
                              <label
                                className="input-label text-dark-gray text-xl font-bold block mb-2.5"
                                htmlFor="metaDescription"
                              >
                                Blog Description
                                <small className="text-red-400">*</small>
                              </label>
                              <small className="text-red-400 ml-6 italic font-bold">
                                {messages.blogDescription}
                              </small>
                              <Editor
                                blogDescription={(e) => setBlogDescription(e)}
                                description={blogDescription}
                              />
                            </div>
                          </div>
                          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                            <div className="update-profile w-full lg:w-4/12 mb-9 p-3">
                              <h1 className="text-xl tracking-wide font-bold text-dark-gray flex items-center mb-2">
                                Blog Image
                                <small className="text-red-400">*</small>
                              </h1>
                              <p className="text-base text-thin-light-gray mb-5">
                                {/* Profile of at least Size
                          <span className="ml-1 text-dark-gray">300x300</span>. */}
                                PNG, JPG or JPEG only
                                <span className="ml-1 text-dark-gray">
                                  Max 5mb
                                </span>
                                .
                              </p>
                              <div className="flex justify-left">
                                <div className="relative">
                                  {profileImg ? (
                                    <img
                                      src={profileImg}
                                      alt=""
                                      className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] overflow-hidden object-contain profile_image"
                                    />
                                  ) : (
                                    <img
                                      src={DefaultImg}
                                      alt=""
                                      className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] overflow-hidden object-contain profile_image"
                                    />
                                  )}

                                  {/* <Svg
                            url={logo}
                            className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] rounded-full overflow-hidden object-cover profile_image"
                          /> */}
                                  <input
                                    ref={profileImgInput}
                                    onChange={(e) => profileImgChangHandler(e)}
                                    type="file"
                                    className="hidden"
                                  />
                                  <div
                                    onClick={browseProfileImg}
                                    className="w-[32px] h-[32px] absolute bottom-7 right-0 hover:bg-pink bg-dark-gray rounded-full cursor-pointer"
                                  >
                                    <svg
                                      width="32"
                                      height="32"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M16.5147 11.5C17.7284 12.7137 18.9234 13.9087 20.1296 15.115C19.9798 15.2611 19.8187 15.4109 19.6651 15.5683C17.4699 17.7635 15.271 19.9587 13.0758 22.1539C12.9334 22.2962 12.7948 22.4386 12.6524 22.5735C12.6187 22.6034 12.5663 22.6296 12.5213 22.6296C11.3788 22.6334 10.2362 22.6297 9.09365 22.6334C9.01498 22.6334 9 22.6034 9 22.536C9 21.4009 9 20.2621 9.00375 19.1271C9.00375 19.0746 9.02997 19.0109 9.06368 18.9772C10.4123 17.6249 11.7609 16.2763 13.1095 14.9277C14.2295 13.8076 15.3459 12.6913 16.466 11.5712C16.4884 11.5487 16.4997 11.5187 16.5147 11.5Z"
                                        fill="white"
                                      />
                                      <path
                                        d="M20.9499 14.2904C19.7436 13.0842 18.5449 11.8854 17.3499 10.6904C17.5634 10.4694 17.7844 10.2446 18.0054 10.0199C18.2639 9.76139 18.5261 9.50291 18.7884 9.24443C19.118 8.91852 19.5713 8.91852 19.8972 9.24443C20.7251 10.0611 21.5492 10.8815 22.3771 11.6981C22.6993 12.0165 22.7105 12.4698 22.3996 12.792C21.9238 13.2865 21.4443 13.7772 20.9686 14.2717C20.9648 14.2792 20.9536 14.2867 20.9499 14.2904Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div className="text-red-500">
                                {messages.image}
                              </div>
                            </div>

                            <div className="update-profile w-full lg:w-4/12 mb-9 p-3">
                              <h1 className="text-xl tracking-wide font-bold text-dark-gray flex items-center mb-2">
                                Thumbnail Image
                                <small className="text-red-400">*</small>
                              </h1>
                              <p className="text-base text-thin-light-gray mb-5">
                                {/* Profile of at least Size
                          <span className="ml-1 text-dark-gray">300x300</span>. */}
                                PNG, JPG or JPEG only
                                <span className="ml-1 text-dark-gray">
                                  Max 5mb
                                </span>
                                .
                              </p>
                              <div className="flex justify-left">
                                <div className="relative">
                                  {thumbnailImg ? (
                                    <img
                                      src={thumbnailImg}
                                      alt=""
                                      className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] overflow-hidden object-contain profile_image"
                                    />
                                  ) : (
                                    <img
                                      src={DefaultImg}
                                      alt=""
                                      className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] overflow-hidden object-contain profile_image"
                                    />
                                  )}

                                  {/* <Svg
                            url={logo}
                            className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] rounded-full overflow-hidden object-cover profile_image"
                          /> */}
                                  <input
                                    ref={thumbnailImgInput}
                                    onChange={(e) =>
                                      thumbnailImgChangHandler(e)
                                    }
                                    type="file"
                                    className="hidden"
                                  />
                                  <div
                                    onClick={browsThumbnailImg}
                                    className="w-[32px] h-[32px] absolute bottom-7 right-0 hover:bg-pink bg-dark-gray rounded-full cursor-pointer"
                                  >
                                    <svg
                                      width="32"
                                      height="32"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M16.5147 11.5C17.7284 12.7137 18.9234 13.9087 20.1296 15.115C19.9798 15.2611 19.8187 15.4109 19.6651 15.5683C17.4699 17.7635 15.271 19.9587 13.0758 22.1539C12.9334 22.2962 12.7948 22.4386 12.6524 22.5735C12.6187 22.6034 12.5663 22.6296 12.5213 22.6296C11.3788 22.6334 10.2362 22.6297 9.09365 22.6334C9.01498 22.6334 9 22.6034 9 22.536C9 21.4009 9 20.2621 9.00375 19.1271C9.00375 19.0746 9.02997 19.0109 9.06368 18.9772C10.4123 17.6249 11.7609 16.2763 13.1095 14.9277C14.2295 13.8076 15.3459 12.6913 16.466 11.5712C16.4884 11.5487 16.4997 11.5187 16.5147 11.5Z"
                                        fill="white"
                                      />
                                      <path
                                        d="M20.9499 14.2904C19.7436 13.0842 18.5449 11.8854 17.3499 10.6904C17.5634 10.4694 17.7844 10.2446 18.0054 10.0199C18.2639 9.76139 18.5261 9.50291 18.7884 9.24443C19.118 8.91852 19.5713 8.91852 19.8972 9.24443C20.7251 10.0611 21.5492 10.8815 22.3771 11.6981C22.6993 12.0165 22.7105 12.4698 22.3996 12.792C21.9238 13.2865 21.4443 13.7772 20.9686 14.2717C20.9648 14.2792 20.9536 14.2867 20.9499 14.2904Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div className="text-red-500">
                                {messages.thumbImage}
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-2 gap-4">
                            <div className="field w-full mb-6 xl:mb-0">
                              <RadioInput
                                title="Active"
                                label="Status"
                                name="Active"
                                radioHandler={() => setStatus("active")}
                                value={status === "active" ? true : false}
                                required
                              />
                              <RadioInput
                                title="Inactive"
                                name="Inactive"
                                radioHandler={() => setStatus("inactive")}
                                value={status === "inactive" ? true : false}
                              />

                              <small className="text-red-400 ml-6 italic font-bold">
                                {messages.status}
                              </small>
                            </div>

                            <div className="field w-full mb-6 xl:mb-0">
                              <InputCom
                                label="Order"
                                type="text"
                                name="order"
                                inputHandler={(e) =>
                                  setOrderNumber(e.target.value)
                                }
                                value={orderNumber}
                                placeholder="Enter Blog Order Number Here"
                                notRequired
                              />
                              <small className="text-red-400 ml-6 italic font-bold">
                                {messages.orderNumber}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="xl:flex  mb-4">
                        <HiInformationCircle />
                        <span>
                          After deploying your smart contract, network setting
                          can't update.
                        </span>
                      </div> */}

                    <div className="content-footer w-full">
                      <div className="w-full h-[120px] border-t border-light-purple flex justify-end items-center">
                        <div className="flex space-x-4 mr-9">
                          <Link to="/admin/adminuserlist">
                            <button
                              type="button"
                              className="text-18 text-light-red tracking-wide "
                            >
                              <span className="border-b border-light-red">
                                Cancel
                              </span>
                            </button>
                          </Link>

                          <button
                            type="submit"
                            className={`w-[152px] h-[46px] flex justify-center items-center gradientButton text-base rounded-full text-white `}
                            // disabled={isDeployed ? true : false}
                          >
                            Update Blog
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
