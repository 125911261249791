import { useSelector } from "react-redux";

export default function useHeader() {
  const { token, id } = useSelector((store) => store.user);
  const headersGeneral = {
    authorization: `Bearer ${token}`,
    "content-type": "application/json",
    "created-by": id,
  };
  const headersForUpdate = {
    authorization: `Bearer ${token}`,
    "content-type": "application/json",
    "updated-by": id,
  };
  return {
    headersGeneral,
    headersForUpdate,
  };
}
