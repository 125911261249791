/* eslint-disable no-unreachable */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import LottieLoader from "../../components/Helpers/LottieLoader";
import Layout from "../../components/Partials/Layout";

import useRequestFun from "../../hooks/Axios/useRequestFun";

// Require Editor CSS files.

import Editor from "../../components/Editor/Editor";
import InputCom from "../../components/Helpers/Inputs";
import RadioInput from "../../components/Helpers/RadioButton";

export default function UpdateFaq() {
  const [isLoader, setIsLoader] = useState(true);
  const [question, setQuestion] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [status, setStatus] = useState("active");
  const location = useLocation();

  const [answer, setAnswer] = useState("");

  const { getRequest, putRequest } = useRequestFun();
  const navigate = useNavigate();
  const [messages, setMessage] = useState({
    question: "",
    answer: "",
    status: "",
  });

  useEffect(() => {
    getRequest(`getfaqbyid/${location.state.id}`).then((res) => {
      setQuestion(res.data.data.title);
      setAnswer(res.data.data.description);
      setStatus(res.data.data.status);
      setOrderNumber(res.data.data.order);
      setIsLoader(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    return toast.warning("Disabled For Demo Version!");
    let isValid = true;
    if (question === "") {
      setMessage({ question: "Question is required" });
      isValid = false;
    } else if (answer === "") {
      setMessage({ answer: "Answer is required" });
      isValid = false;
    } else if (status === "") {
      setMessage({ status: "Status is required" });
      isValid = false;
    } else {
      setMessage({});
    }

    if (isValid) {
      setIsLoader(true);
      putRequest(`updatefaq/${location.state.id}`, {
        title: question,
        description: answer.editorHtml ? answer.editorHtml : answer,
        status,
        order: orderNumber,
      })
        .then((res) => {
          toast.success(res.data.message);
          setIsLoader(false);
          navigate("/faq-list");
        })
        .catch((error) => {
          toast.warning(error.response.data.message);
          setIsLoader(false);
        });
    }
  };

  return (
    <Layout>
      {isLoader ? (
        <LottieLoader />
      ) : (
        <div className="settings-wrapper w-full relative mb-10">
          <div className="mainWrapper w-full">
            <div className="content-container w-full rounded-2xl bg-white">
              <div className="content-body-items flex-1 p-10">
                <div className="heading w-full mb-6">
                  <h1 className="text-26 font-bold text-dark-gray antialiased">
                    Add FAQ
                  </h1>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="personal-info-tab w-full flex flex-col justify-between">
                    <div className="flex flex-col-reverse sm:flex-row">
                      <div className="flex-1 sm:mr-10">
                        <div className="fields w-full">
                          {/* first name and last name */}
                          <div className="xl:flex flex-col gap-6 mb-6">
                            <div className="field w-full mb-6 xl:mb-0">
                              <InputCom
                                label="Question"
                                type="text"
                                name="title"
                                inputHandler={(e) =>
                                  setQuestion(e.target.value)
                                }
                                value={question}
                                placeholder="Enter Blog Title Here"
                                //   error={messages.name ? true : false}
                              />
                              <small className="text-red-400 ml-6 italic font-bold">
                                {messages.question}
                              </small>
                            </div>

                            <div className="field w-full">
                              <label
                                className="input-label text-dark-gray text-xl font-bold block mb-2.5"
                                htmlFor="metaDescription"
                              >
                                Answer
                                <small className="text-red-400">*</small>
                              </label>
                              <small className="text-red-400 ml-6 italic font-bold">
                                {messages.answer}
                              </small>
                              <Editor
                                blogDescription={(e) => setAnswer(e)}
                                description={answer}
                              />
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                              <div className="field w-full mb-6 xl:mb-0">
                                <RadioInput
                                  title="Active"
                                  label="Status"
                                  name="Active"
                                  radioHandler={() => setStatus("active")}
                                  value={status === "active" ? true : false}
                                  required
                                />
                                <RadioInput
                                  title="Inactive"
                                  name="Inactive"
                                  radioHandler={() => setStatus("inactive")}
                                  value={status === "inactive" ? true : false}
                                />
                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.status}
                                </small>
                              </div>

                              <div className="field w-full mb-6 xl:mb-0">
                                <InputCom
                                  label="Order"
                                  type="text"
                                  name="order"
                                  inputHandler={(e) =>
                                    setOrderNumber(e.target.value)
                                  }
                                  value={orderNumber}
                                  placeholder="Enter Order Number Here"
                                  notRequired
                                />
                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages?.order}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="xl:flex  mb-4">
                        <HiInformationCircle />
                        <span>
                          After deploying your smart contract, network setting
                          can't update.
                        </span>
                      </div> */}

                    <div className="content-footer w-full">
                      <div className="w-full h-[120px] border-t border-light-purple flex justify-end items-center">
                        <div className="flex space-x-4 mr-9">
                          <Link to="/admin/adminuserlist">
                            <button
                              type="button"
                              className="text-18 text-light-red tracking-wide "
                            >
                              <span className="border-b border-light-red">
                                Cancel
                              </span>
                            </button>
                          </Link>

                          <button
                            type="submit"
                            className={`w-[152px] h-[46px] flex justify-center items-center gradientButton text-base rounded-full text-white `}
                            // disabled={isDeployed ? true : false}
                          >
                            Update Faq
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
