/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import InputCom from "../Helpers/Inputs";
import WalletInfo from "./WalletInfo";
// import LottieLoader from "../Helpers/LottieLoader";
// import * as Loader from "../../assets/lottie/loader.json";
// import wrappedETH from "../../contracts/wrappedETH.json";

export default function ImportWallet({ setLoader }) {
  const [name, setName] = useState("");
  const tokenContractAddress = process.env.REACT_APP_WETH_CONTRACT;
  const [isWallet, setIsWallet] = useState(false);
  const [walletData, setWalletData] = useState();
  const [networkData, setNetworkData] = useState();
  const [privatekey, setPrivateKey] = useState("0x");
  const [messages, setMessages] = useState({});
  const [copy, setCopy] = useState("Copy");
  const { postRequest, getRequest } = useRequestFun();

  useEffect(() => {
    getRequest(`getAdminWallet`).then(async (res) => {
      if (res.data.data) {
        setIsWallet(true);
        setName(res.data.data.name);
        setWalletData(res.data.data);
      }
    });
    getRequest(`getNetWorks`).then(async (res) => {
      if (res.data.data[0]) {
        setNetworkData(res.data.data[0]);
        setLoader(false);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerSubmit = (event) => {
    event.preventDefault();
    let isValid = true;
    if (name === "") {
      setMessages({ name: "Account Name is required" });
      isValid = false;
    } else if (privatekey === "" || privatekey === "0x") {
      setMessages({ privatekey: "Private Key is required" });
      isValid = false;
    } else if (!networkData) {
      toast.error("Please Setup Network First");
      isValid = false;
    } else {
      setMessages("");
    }

    if (isValid) {
      setLoader(true);
      postRequest("importwallet", {
        name,
        privateKey: privatekey,
        network_id: networkData._id,
      })
        .then((res) => {
          setLoader(false);
          setIsWallet(true);
          setName(res.data.data.name);
          setWalletData(res.data.data);
          toast.success(`${res.data.message}`, {
            icon: `🙂`,
          });
        })
        .catch((err) => {
          setLoader(false);

          toast.error(`${err.response.data.message}`);
        });
    }
  };
  const copyKey = (privateKey) => {
    navigator.clipboard.writeText(privateKey);
    setCopy("Copied");
  };
  return (
    <>
      <div className="personal-info-tab w-full flex justify-between">
        <div className="create-wallet-details w-8/12">
          <div className="flex flex-col-reverse sm:flex-row">
            <div className="flex-1">
              <div className="fields w-full">
                {/* first name and last name */}

                <form onSubmit={handlerSubmit}>
                  <div className="xl:flex mb-6 flex flex-col">
                    <div className="field w-full mb-6 xl:mb-0">
                      <InputCom
                        label="Account Name"
                        type="text"
                        name="name"
                        placeholder="Account Name"
                        value={name}
                        inputHandler={(e) => setName(e.target.value)}
                      />
                      <small className="text-red-400 ml-6 italic font-bold">
                        {messages.name}
                      </small>
                    </div>
                    <div className="field w-full mb-6 xl:mb-0">
                      <InputCom
                        label="Private Key"
                        type="text"
                        name="privatekey"
                        placeholder="Private Key"
                        value={privatekey}
                        inputHandler={(e) => setPrivateKey(e.target.value)}
                      />
                      <small className="text-red-400 ml-6 italic font-bold">
                        {messages.privatekey}
                      </small>
                    </div>
                    <div className="field w-full">
                      <div className="flex space-x-4 ">
                        <button
                          type="submit"
                          className={`w-[152px] h-[46px] ml-auto flex justify-center items-center gradientButton text-base rounded-full text-white ${
                            isWallet === true ? "opacity-50" : ""
                          }`}
                          disabled={!!isWallet}
                        >
                          Import Wallet
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* border line */}
            </div>
          </div>
          {isWallet && networkData && (
            <WalletInfo
              walletData={walletData}
              networkData={networkData}
              tokenContractAddress={tokenContractAddress}
              copyAddress={copyKey}
              copyAdd={copy}
            />
          )}
        </div>
        {isWallet && networkData && (
          <div className="qr-code w-4/12 flex justify-center align-top">
            <div>
              <img
                src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${walletData.address}&choe=UTF-8`}
                alt="qrcode"
                width={200}
                height="200"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
