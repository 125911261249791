/* eslint-disable no-underscore-dangle */
import { useState } from "react";
import Layout from "../../components/Partials/Layout";
// import useFileUpload from "../../hooks/useFileUpload";
import LottieLoader from "../../components/Helpers/LottieLoader";
import SubscriptionServiceForm from "../../components/Subscription/SubscriptionServiceForm";

export default function AddSubscriptionService() {
  const [isLoader, setIsLoader] = useState(false);

  return (
    <Layout>
      {isLoader ? (
        <LottieLoader />
      ) : (
        <div className="settings-wrapper w-full relative mb-10">
          <div className="mainWrapper w-full">
            {/* heading */}
            <SubscriptionServiceForm
              // addCategory={(e) => addCategory(e)}
              // name={name}
              // description={description}
              // metaDescription={metaDescription}
              // setName={(e) => setName(e.target.value)}
              // setDescription={(obj) => setDescription((prev) => [...prev, obj])}
              // setMetaDescription={(e) => setMetaDescription(e.target.value)}
              // errorMessage={errorMessage}
              // status={status}
              // setCateStatus={(value, id) => setCateStatus(value, id)}
              // cateStatus={cateStatus}
              // setImage={(img) => setLogo(img)}
              // mimeType={(mType) => setLogoMimeType(mType)}
              setIsLoader={(mType) => setIsLoader(mType)}
              pageTitle="Add Subscription Service"
              cancelUrl="/subscription/service/list"
              categoryType="nftCategory"
            />
          </div>
        </div>
      )}
    </Layout>
  );
}
