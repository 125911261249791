/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState } from "react";
import { HiInformationCircle } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import InputCom from "../Helpers/Inputs";
import LottieLoader from "../Helpers/LottieLoader";
import SelectBox from "../Helpers/SelectBox";
import Layout from "../Partials/Layout";

export default function AddAdmin() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [emailAndUserName, setEmailAndUserName] = useState([]);
  const genderData = ["Select Gender", "Male", "Female", "Other's"];
  const [rolData, setRoledata] = useState(["Select Admin Role"]);

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState("Select Gender");
  const { getRequest, postRequest } = useRequestFun();
  const [adminRole, setAdminrole] = useState("Select Admin Role");
  const [isLoader, setIsLoader] = useState(false);
  const [messages, setMessages] = useState({
    userName: "",
    email: "",
  });
  const [existError, setExistError] = useState();
  const [userExistError, setUserExistError] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const getallrole = () => {
      getRequest(`getallrole`).then(async (res) => {
        await Promise.all(
          res.data.data.map((role, index) => {
            if (role.roleType !== "superadmin")
              setRoledata((prev) => [...prev, role.roleTitle]);
            return index;
          })
        );
      });
    };
    const getAllUserNameAndEmail = () => {
      getRequest(`getAdminsEmailAndUsername`).then((res) => {
        setEmailAndUserName(res.data.data);
      });
    };
    getallrole();
    getAllUserNameAndEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const userNameHandle = (value) => {
    const existUserName = emailAndUserName.find(
      (item) => item.username === value
    );
    if (existUserName) {
      messages.userName = "User name Already Exist";
      setMessages(messages);
      setUserExistError(true);
    } else {
      messages.userName = "";
      setMessages(messages);
      setUserExistError(false);
    }
    setUserName(value);
  };
  const userEmailHandle = (value) => {
    const existUserName = emailAndUserName.find((item) => item.email === value);
    if (existUserName) {
      messages.email = "Email Already Exist";
      setMessages(messages);
      setExistError(true);
    } else {
      messages.email = "";
      setMessages(messages);
      setExistError(false);
    }
    setEmail(value);
  };

  const addAdmin = async (e) => {
    e.preventDefault();
    return toast.warning("Disabled For Demo Version!");
    // After click Add Admin
    let isValid = true;
    if (fname === "") {
      setMessages({ fname: "First name is required" });
      isValid = false;
    } else if (lname === "") {
      setMessages({ lname: "Last name is required" });
      isValid = false;
    } else if (userName === "") {
      setMessages({ userName: "User name is required" });
      isValid = false;
    } else if (email === "") {
      setMessages({ email: "Email is required" });
      isValid = false;
    } else if (!gender || gender === "Select Gender") {
      setMessages({ gender: "Gender is required" });
      isValid = false;
    } else if (adminRole === "" || adminRole === "Select Admin Role") {
      setMessages({ role: "Role is required" });
      isValid = false;
    } else if (phone === "") {
      setMessages({ phone: "Phone is required" });
      isValid = false;
    } else if (password === "") {
      setMessages({ password: "Password is required" });
      isValid = false;
    } else if (userExistError === true) {
      toast.warning("User Name Already Exist");
      isValid = false;
    } else if (existError === true) {
      toast.warning("Email Already Exist");
      isValid = false;
    } else {
      setMessages("");
    }

    if (!isValid) {
      return;
    }
    setIsLoader(true);
    postRequest("addnewadmin", {
      first_name: fname,
      last_name: lname,
      username: userName,
      email,
      gender,
      role: adminRole,
      phone,
      password,
      status: "active",
    })
      .then((res) => {
        setIsLoader(false);
        toast.success(`${res.data.message}`, {
          icon: `🙂`,
        });
        postRequest("sendMail", {
          to: email,
          subject: "New Admin Creation",
          name: `${fname} ${lname}`,
          description: `Welcome to NFTO. You are selected as ${adminRole} in NFTO.`,
          mailBody: `Please find your login details below. Login email: ${email}, Password: ${password}`,
          link: "https://admin.nftarkade.com/login",
          buttonName: "Click",
        })
          .then(() => {
            toast.success(`Please check this ${email}, We send a email!`, {
              icon: `🙂`,
            });
            navigate("/admin/adminuserlist", { replace: true });
          })
          .catch((err) => {
            toast.error(`${err.response.data.message}`);
          });
      })
      .catch((err) => {
        setIsLoader(false);
        toast.error(`${err.response.data.message}`);
      });
  };

  return (
    <>
      {isLoader ? (
        <LottieLoader />
      ) : (
        <Layout>
          <div className="settings-wrapper w-full relative mb-10">
            <div className="mainWrapper w-full">
              {/* heading */}

              <form onSubmit={addAdmin}>
                <div className="content-container w-full rounded-2xl bg-white">
                  <div className="content-body-items flex-1 p-10">
                    <div className="heading w-full mb-6">
                      <h1 className="text-26 font-bold text-dark-gray antialiased">
                        Add Admin
                      </h1>
                    </div>
                    <div className="personal-info-tab w-full flex flex-col justify-between">
                      <div className="flex flex-col-reverse sm:flex-row">
                        <div className="flex-1 sm:mr-10">
                          <div className="fields w-full">
                            {/* first name and last name */}
                            <div className="xl:flex xl:space-x-7 mb-6">
                              <div className="field w-full mb-6 xl:mb-0">
                                <InputCom
                                  label="First Name"
                                  type="text"
                                  name="firstName"
                                  inputHandler={(e) => setFname(e.target.value)}
                                  value={fname}
                                  placeholder="First Name Here"
                                  error={messages.fname ? true : false}
                                />

                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.fname}
                                </small>
                              </div>
                              <div className="field w-full">
                                <InputCom
                                  label="Last Name"
                                  type="text"
                                  name="lastName"
                                  inputHandler={(e) => setLname(e.target.value)}
                                  value={lname}
                                  placeholder="Last Name Here"
                                  error={messages.lname ? true : false}
                                />

                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.lname}
                                </small>
                              </div>
                            </div>
                            {/* user name and email */}
                            <div className="xl:flex xl:space-x-7 mb-6">
                              <div className="field w-full mb-6 xl:mb-0">
                                <InputCom
                                  label="User Name"
                                  type="text"
                                  name="userName"
                                  inputHandler={(e) =>
                                    userNameHandle(e.target.value)
                                  }
                                  value={userName}
                                  placeholder="username"
                                  error={messages.userName ? true : false}
                                />

                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.userName}
                                </small>
                              </div>
                              <div className="field w-full">
                                <InputCom
                                  label="Email"
                                  type="email"
                                  name="email"
                                  inputHandler={(e) =>
                                    userEmailHandle(e.target.value)
                                  }
                                  value={email}
                                  placeholder="admin@example.com"
                                  error={messages.email ? true : false}
                                />
                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.email}
                                </small>
                              </div>
                            </div>
                            {/* gender and role */}
                            <div className="xl:flex xl:space-x-7 mb-6">
                              <div className="field w-full mb-6 xl:mb-0">
                                <div className="chart-heading mb-4 xl:flex justify-between items-center">
                                  <SelectBox
                                    label="Gender"
                                    name="Gender"
                                    datas={genderData}
                                    action={(value) => setGender(value)}
                                    selectedValue={gender}
                                    error={messages.gender ? true : false}
                                  />
                                </div>
                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.gender}
                                </small>
                              </div>
                              <div className="field w-full">
                                <div className="chart-heading mb-4 xl:flex justify-between items-center">
                                  <SelectBox
                                    label="Role"
                                    name="Role"
                                    datas={rolData}
                                    action={(value) => setAdminrole(value)}
                                    selectedValue={adminRole}
                                    error={messages.role ? true : false}
                                  />
                                </div>
                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.role}
                                </small>
                              </div>
                            </div>
                            {/* phone and password */}
                            <div className="xl:flex xl:space-x-7 mb-6">
                              <div className="field w-full mb-6 xl:mb-0">
                                <InputCom
                                  label="Phone"
                                  type="text"
                                  name="phone"
                                  inputHandler={(e) => setPhone(e.target.value)}
                                  value={phone}
                                  placeholder="+880124365464"
                                  error={messages.phone ? true : false}
                                />
                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.phone}
                                </small>
                              </div>
                              <div className="field w-full">
                                <InputCom
                                  label="Password"
                                  type="password"
                                  name="password"
                                  inputHandler={(e) =>
                                    setPassword(e.target.value)
                                  }
                                  value={password}
                                  placeholder="password"
                                  error={messages.password ? true : false}
                                />
                                <small className="text-red-400 ml-6 italic font-bold">
                                  {messages.password}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="xl:flex  mb-4">
                        <HiInformationCircle />
                        <span>
                          After create admin, a mail will send to this email
                          address for resetting password.
                        </span>
                      </div>

                      <div className="content-footer w-full">
                        <div className="w-full h-[120px] border-t border-light-purple flex justify-end items-center">
                          <div className="flex space-x-4 mr-9">
                            <Link to="/admin/adminuserlist">
                              <button
                                type="button"
                                className="text-18 text-light-red tracking-wide "
                              >
                                <span className="border-b border-light-red">
                                  Cancel
                                </span>
                              </button>
                            </Link>

                            <button
                              type="submit"
                              className="w-[152px] h-[46px] flex justify-center items-center gradientButton text-base rounded-full text-white"
                            >
                              Add Admin
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}
