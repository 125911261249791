import React from "react";
import HomeTopCardSkeleton from "./HomeTopCardSkeleton";
import SliderSkeleton from "./SliderSkeleton";
import TableSkeleton from "./TableSkeleton";
import UserAndMarketChartSkeleton from "./UserAndMarketChartSkeleton";

export default function DashboardSkeleton() {
  return (
    <div className="flex flex-col gap-6">
      <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-[30px]">
        <HomeTopCardSkeleton />
        <HomeTopCardSkeleton />
        <HomeTopCardSkeleton />
        <HomeTopCardSkeleton />
        <HomeTopCardSkeleton />
        <HomeTopCardSkeleton />
        <HomeTopCardSkeleton />
        <HomeTopCardSkeleton />
        <HomeTopCardSkeleton />
        <HomeTopCardSkeleton />
        <HomeTopCardSkeleton />
        <HomeTopCardSkeleton />
      </div>
      <div>
        <UserAndMarketChartSkeleton />
      </div>
      <div>
        <SliderSkeleton />
      </div>
      <div>
        <TableSkeleton />
      </div>
    </div>
  );
}
