import React from "react";
import NetworkSetup from "../components/Network/NetworkSetup";

export default function Network() {
  return (
    <>
      <NetworkSetup />
    </>
  );
}
