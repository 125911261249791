/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from "react";

import { AiOutlineEdit } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import nullimg from "../../assets/images/nullimg.png";
import DataTable from "../../components/Helpers/DataGrid";
import LottieLoader from "../../components/Helpers/LottieLoader";
import Layout from "../../components/Partials/Layout";
import useRequestFun from "../../hooks/Axios/useRequestFun";

export default function CategoriesList() {
  const [isLoader, setIsLoader] = useState(true);
  const [categoriesList, setCategoriesList] = useState();
  const { getRequest } = useRequestFun();
  const { role } = useSelector((store) => store.user);
  const navigate = useNavigate();
  const getRequests = useCallback(() => {
    getRequest("getallcategory").then((res) => {
      setCategoriesList(res.data.data);
      setIsLoader(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getRequests();
  }, [getRequests]);
  const columns = [
    {
      minWidth: 150,
      field: "name",
      headerName: "Category Name",
      flex: 1,
      valueGetter: (params) => `${params.row.name}`,
    },

    {
      minWidth: 150,
      field: "icon",
      headerName: "Icon",
      flex: 1,
      renderCell: (params) =>
        !params.row.icon ? (
          <img src={nullimg} alt="icon" width="50" />
        ) : (
          <img src={params.row.icon} alt="icon" width="50" />
        ),
    },

    {
      minWidth: 150,
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) =>
        params.row.status === "active" ? (
          <div className="bg-[#D2FFD4] text-[#0D9F16] rounded-md py-1 px-3 uppercase">
            {params.row.status}
          </div>
        ) : params.row.status === "inactive" ? (
          <div className="bg-[#E9DCFE] text-[#2C0270] rounded-md py-1 px-3 uppercase">
            {params.row.status}
          </div>
        ) : (
          params.row.status === "suspend" && (
            <div className="bg-[#FFD4D0] text-[#FF1600] rounded-md py-1 px-3 uppercase">
              {params.row.status}
            </div>
          )
        ),
    },
    {
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const edit = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          navigate("/edit/category", {
            state: { id: params.row._id },
          });
        };
        // const dlt = (e) => {
        //   e.stopPropagation(); // don't select this row after clicking
        //   deleteAdmin(params.row._id);
        // };
        return (
          <>
            {role === "superadmin" && (
              <button
                type="button"
                className="text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
                onClick={edit}
              >
                <AiOutlineEdit className="text-xl" />
              </button>
            )}
            {/* &nbsp;
            {(role === "superadmin" || permissions.deleteAdmin) && (
              <button
                type="button"
                className="bg-red-600 hover:bg-red-800 text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
                onClick={dlt}
              >
                <AiOutlineDelete className="text-xl" />
              </button>
            )} */}
          </>
        );
      },
    },
  ];
  return (
    <Layout>
      {isLoader ? (
        <LottieLoader />
      ) : (
        <div
          className={`tableForUpdate w-full p-8 bg-white overflow-hidden rounded-2xl shadowSection relative min-h-[520px] `}
        >
          <div className="header w-full sm:flex justify-between items-center mb-5">
            <div className="flex space-x-2 items-center mb-2 sm:mb-0">
              <h1 className="text-xl font-bold text-dark-gray tracking-wide">
                Category List
              </h1>
            </div>
          </div>
          <div className="relative w-full overflow-x-auto sm:rounded-lg h-[700px]">
            {categoriesList && (
              <DataTable rowList={categoriesList} columns={columns} />
            )}
          </div>
        </div>
      )}
    </Layout>
  );
}
