import React from "react";
import Login from "../components/Auth/Login";

function LoginLayout() {
  return (
    <>
      <Login />
    </>
  );
}

export default LoginLayout;
