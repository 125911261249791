import React from "react";
import { AiOutlineClose } from "react-icons/ai";

export default function StepOrderCancel() {
  return (
    // <div className="w-5 h-5 flex justify-center items-center rounded-full text-white bg-gradient ">
    <div className="w-5 h-5 flex justify-center items-center rounded-full text-white bg-red-400 ">
      <AiOutlineClose className="text-xs" />
    </div>
  );
}
