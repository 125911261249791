import React from "react";
import UpdatePassword from "../../components/Auth/VerifyYou/UpdatePassword";

export default function ForgetUpdatePasswordPages() {
  return (
    <>
      <UpdatePassword />
    </>
  );
}
