/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import InputCom from "../../components/Helpers/Inputs";
// import SecondSelect from "../../components/Helpers/SelectBox/SecondSelect";
import CateForm from "../../components/Categories/CateForm";
import LottieLoader from "../../components/Helpers/LottieLoader";
import Layout from "../../components/Partials/Layout";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import useFileUpload from "../../hooks/useFileUpload";

export default function AddCategory() {
  const [isLoader, setIsLoader] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [logo, setLogo] = useState("");
  const [mimeType, setLogoMimeType] = useState("");
  const navigate = useNavigate();
  const [status] = useState([
    {
      _id: 1,
      name: "Active",
      value: "active",
    },
    {
      _id: 2,
      name: "Inactive",
      value: "inactive",
    },
  ]);
  const [cateStatus, setCateStatus] = useState({
    value: "Select Status",
    id: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    cateName: "",
    status: "",
  });
  const { postRequest } = useRequestFun();
  const { fileUpload } = useFileUpload();

  const addCategory = async (e) => {
    e.preventDefault();
    return toast.warning("Disabled For Demo Version!");
    let isInvalid = false;
    if (name === "") {
      setErrorMessage({
        ...errorMessage,
        cateName: "Category Name is Required",
      });
      isInvalid = true;
    } else if (!cateStatus.id || cateStatus.id === "") {
      setErrorMessage({
        ...errorMessage,
        status: "Status is Required",
      });
      isInvalid = true;
    } else {
      setErrorMessage({
        ...errorMessage,
        cateName: "",
        status: "",
      });
    }
    if (isInvalid) {
      return;
    }
    setIsLoader(true);
    let fileUrl = "";
    if (logo) {
      fileUrl = await fileUpload(logo, "admin", mimeType);
    }

    const statusLabel = status.find((item) => item._id === cateStatus.id);
    postRequest("addcategory", {
      name,
      description,
      metaDescription,
      status: statusLabel.value,
      icon: fileUrl,
    })
      .then((res) => {
        toast.success(res.data.message);
        setIsLoader(false);
        navigate("/categories");
      })
      .catch((error) => {
        toast.warning(error.response.data.message);
        setIsLoader(false);
      });
  };

  return (
    <Layout>
      {isLoader ? (
        <LottieLoader />
      ) : (
        <div className="settings-wrapper w-full relative mb-10">
          <div className="mainWrapper w-full">
            {/* heading */}
            <CateForm
              addCategory={(e) => addCategory(e)}
              name={name}
              description={description}
              metaDescription={metaDescription}
              setName={(e) => setName(e.target.value)}
              setDescription={(e) => setDescription(e.target.value)}
              setMetaDescription={(e) => setMetaDescription(e.target.value)}
              errorMessage={errorMessage}
              status={status}
              setCateStatus={(value, id) => setCateStatus(value, id)}
              cateStatus={cateStatus}
              setImage={(img) => setLogo(img)}
              mimeType={(mType) => setLogoMimeType(mType)}
              pageTitle="Add New Category"
              cancelUrl="/categories"
              categoryType="nftCategory"
            />
          </div>
        </div>
      )}
    </Layout>
  );
}
