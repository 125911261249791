/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import CheckBox from "../Helpers/CheckBox";
import InputCom from "../Helpers/Inputs";

export default function AddRole() {
  const [roleTitle, setRoleTitle] = useState("");
  // const [allFeature, setAllFeature] = useState();
  const [permissionsList, setPermissionsList] = useState();
  const [titleError, setTitleError] = useState(false);
  const { getRequest, postRequest } = useRequestFun();
  const navigate = useNavigate();

  const setPermissionObject = (data) => {
    // eslint-disable-next-line no-shadow
    let obj = {};
    data.forEach((item) => {
      obj[item.feature] = false;
    });
    setPermissionsList(obj);
  };
  useEffect(() => {
    getRequest("getallfeature")
      .then((res) => {
        // setAllFeature(res.data.data);
        setPermissionObject(res.data.data);
      })
      .catch(() => {});
  }, []);

  const checkBoxInputHandler = (name) => {
    let per = _.clone(permissionsList);
    // eslint-disable-next-line no-unused-expressions
    if (per[name] === false) {
      per[name] = true;
    } else {
      per[name] = false;
    }
    setPermissionsList(per);
  };
  // const titleInputHandler = (event) => {
  //   setRoleTitle(event.target.value);
  // };
  const saveRoleWithPermissions = (e) => {
    e.preventDefault();
    return toast.warning("Disabled For Demo Version!");
    if (roleTitle === "") {
      setTitleError(true);
      return;
    }
    if (roleTitle !== "") {
      setTitleError(false);
    }

    postRequest("rolepermission", {
      title: roleTitle,
      permissions: { ...permissionsList },
    })
      .then((res) => {
        toast.success(`${res.data.message}`, {
          icon: `🙂`,
        });

        navigate("/roles");
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`);
      });
  };
  return (
    <div className="settings-wrapper w-full relative mb-10">
      <div className="mainWrapper w-full">
        {/* heading */}

        <div className="content-container w-full rounded-2xl bg-white">
          <div className="content-body-items flex-1 p-10">
            <div className="heading w-full mb-6">
              <h1 className="text-26 font-bold text-dark-gray antialiased">
                Add Role
              </h1>
            </div>
            <div className="personal-info-tab w-full flex flex-col justify-between">
              <form onSubmit={saveRoleWithPermissions}>
                <div className="flex flex-col-reverse sm:flex-row">
                  <div className="flex-1 sm:mr-10">
                    <div className="fields w-full">
                      {/* first name and last name */}
                      <div className="xl:flex xl:space-x-7 mb-6">
                        <div className="field md:w-1/2 mb-6 xl:mb-0">
                          <InputCom
                            label="Title"
                            type="text"
                            name="title"
                            placeholder="Role Title Here"
                            inputHandler={(e) => setRoleTitle(e.target.value)}
                            value={roleTitle}
                            error={titleError ? true : false}
                          />
                          {titleError && (
                            <small className="text-red-400 ml-6 italic font-bold">
                              Role Title Required
                            </small>
                          )}
                        </div>
                      </div>
                      <div>
                        <h1 className="text-26 font-bold text-dark-gray antialiased">
                          Permissions
                        </h1>
                        <div className="features my-5 w-full ">
                          <fieldset className="mb-3 flex border-2 p-4 rounded-lg items-center">
                            <legend className="text-md italic">
                              Administrator Permissions
                            </legend>
                            <div className="flex flex-wrap gap-3">
                              {permissionsList &&
                                Object.keys(permissionsList).map((item) => {
                                  let result = item.replace(/([A-Z])/g, " $1");
                                  const finalResult =
                                    result.charAt(0).toUpperCase() +
                                    result.slice(1);
                                  return (
                                    <>
                                      {(item === "addNewAdmin" ||
                                        item === "adminUserList" ||
                                        item === "deleteAdmin" ||
                                        item === "updateAdminInfo") && (
                                        <CheckBox
                                          name={item}
                                          title={finalResult}
                                          key={item}
                                          checkBoxHandler={checkBoxInputHandler}
                                          value={permissionsList[item]}
                                        />
                                      )}
                                    </>
                                  );
                                })}
                            </div>
                          </fieldset>

                          <div className="w-full flex flex-col sm:flex-row sm:flex-wrap gap-3">
                            {permissionsList &&
                              Object.keys(permissionsList).map((item) => {
                                let result = item.replace(/([A-Z])/g, " $1");
                                const finalResult =
                                  result.charAt(0).toUpperCase() +
                                  result.slice(1);
                                return (
                                  item !== "addNewAdmin" &&
                                  item !== "adminUserList" &&
                                  item !== "deleteAdmin" &&
                                  item !== "updateAdminInfo" && (
                                    <CheckBox
                                      name={item}
                                      title={finalResult}
                                      key={item}
                                      checkBoxHandler={checkBoxInputHandler}
                                      value={permissionsList[item]}
                                    />
                                  )
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-footer w-full">
                  <div className="w-full h-[120px] border-t border-light-purple flex justify-end items-center">
                    <div className="flex space-x-4 mr-9 items-center">
                      <Link to="/roles">
                        <button
                          type="button"
                          className="text-18 text-light-red tracking-wide "
                        >
                          <span className="border-b border-light-red">
                            Cancel
                          </span>
                        </button>
                      </Link>

                      <button
                        type="submit"
                        className="w-[152px] h-[46px] flex justify-center items-center gradientButton text-base rounded-full text-white"
                      >
                        Save Role
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
