import React from "react";
import DeployContract from "../components/MarketContract/DeployContract";

export default function MarketContract() {
  return (
    <>
      <DeployContract />
    </>
  );
}
