import axios from "axios";
import useHeader from "../useHeader";

export default function useRequestFun() {
  const { headersGeneral, headersForUpdate } = useHeader();
  const getRequest = (URL) =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/${URL}`, {
      headers: headersGeneral,
    });

  const getRequestForUsers = (URL) =>
    axios.get(`${process.env.REACT_APP_FRONT_URL}/${URL}`, {
      headers: headersGeneral,
    });
  const postRequest = (URL, payload) => {
    const res = axios.post(
      `${process.env.REACT_APP_BASE_URL}/${URL}`,
      payload,
      {
        headers: headersGeneral,
      }
    );
    return res;
  };

  const putRequest = (URL, payload) => {
    const res = axios.put(`${process.env.REACT_APP_BASE_URL}/${URL}`, payload, {
      headers: headersForUpdate,
    });
    return res;
  };
  // const deleteRequest = (URL) => axiosAdmin.delete(`/${URL}`);

  return {
    getRequest,
    postRequest,
    putRequest,
    getRequestForUsers,
    // deleteRequest,
  };
}
