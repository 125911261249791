/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useState } from "react";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import InputCom from "../Helpers/Inputs";
import SecondSelect from "../Helpers/SelectBox/SecondSelect";

export default function SubscriptionForm({
  //   addCategory,
  //   setName,
  //   setDescription,
  //   setMetaDescription,
  //   name,
  //   description,
  //   metaDescription,
  //   errorMessage,
  //   status,
  //   setSubscriptionExpire,
  //   subscriptionExpire,
  //   setImage,
  //   viewImage,
  //   mimeType,
  setIsLoader,
  pageTitle = "Add Subscription",
  cancelUrl,
  categoryType,
}) {
  const [profileImg, setProfileImg] = useState();
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [serviceList, setServiceList] = useState([]);
  const [description, setDescription] = useState([{ name: "", status: true }]);
  const [fixedService, setFixedService] = useState([
    {
      label: "Image Regulation 1024x1024",
      size: "1024x1024",
      price: "0",
      status: false,
    },
  ]);
  const [imageVariation, setImageVariation] = useState([
    {
      label: "Image Variation",
      variation: "1",
      status: false,
    },
  ]);
  const [steps, setSteps] = useState([
    {
      label: "Steps",
      steps: "30",
      status: true,
    },
  ]);
  const [cfg, setCfg] = useState([
    {
      label: "cfg",
      cfg: "5",
      status: true,
    },
  ]);
  const [subscriptionExpire, setSubscriptionExpire] = useState({
    value: "Select Status",
    id: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    cateName: "",
    status: "",
    price: "",
  });
  const navigate = useNavigate();
  const { getRequest, postRequest } = useRequestFun();
  const [status] = useState([
    {
      _id: 1,
      name: "Daily",
      value: "daily",
    },
    {
      _id: 2,
      name: "Monthly",
      value: "monthly",
    },
    {
      _id: 3,
      name: "Yearly",
      value: "yearly",
    },
  ]);
  const [titleStatus, setTitleStatus] = useState([
    {
      _id: 1,
      name: "Active",
      value: true,
    },
    {
      _id: 2,
      name: "Inactive",
      value: false,
    },
  ]);
  // const getRequests = useCallback(() => {
  //   getRequest("getallservices").then((res) => {
  //     setServiceList(res.data.data);
  //     res.data.data.forEach((item) => {
  //       setDescription((prev) => [
  //         ...prev,
  //         { name: item.name, label: item.label, status: false },
  //       ]);
  //     });
  //     setIsLoader(false);
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // useEffect(() => {
  //   getRequests();
  // }, [getRequests]);
  const addSubscription = async (e) => {
    e.preventDefault();
    return toast.warning("Disabled For Demo Version!");
    let isInvalid = false;
    if (name === "") {
      setErrorMessage({
        ...errorMessage,
        cateName: "Subscription Name is Required",
      });
      isInvalid = true;
    } else if (price === "") {
      setErrorMessage({
        ...errorMessage,
        price: "Subscription Price is Required",
      });
      isInvalid = true;
    } else if (!subscriptionExpire.id || subscriptionExpire.id === "") {
      setErrorMessage({
        ...errorMessage,
        status: "Expire is Required",
      });
      isInvalid = true;
    } else {
      setErrorMessage({
        ...errorMessage,
        cateName: "",
        status: "",
        price: "",
      });
    }
    if (isInvalid) {
      return;
    }
    setIsLoader(true);

    const statusLabel = status.find(
      (item) => item._id === subscriptionExpire.id
    );
    postRequest("addPlan", {
      name,
      price,
      expiry: subscriptionExpire.value.toLowerCase(),
      description: {
        general: description,
        fixed: [...fixedService, ...imageVariation, ...steps, ...cfg],
      },
    })
      .then((res) => {
        toast.success(res.data.message);
        setIsLoader(false);
        navigate("/subscription/list");
      })
      .catch((error) => {
        toast.warning(error.response.data.message);
        setIsLoader(false);
      });
  };

  const [messages, setMessages] = useState({});
  const desArray = {
    title: "",
    status: true,
  };
  const addDescription = () => {
    setDescription((prev) => [...prev, desArray]);
  };
  const deleteDescription = (index) => {
    if (description.length > 1) {
      const des = [...description];
      des.splice(index, 1);

      setDescription(des);
    }
  };
  const handleTypeInput = (typeName, value, index) => {
    const obj = [...description];
    obj[index][typeName] = value;
    setDescription(obj);
  };
  const handleImageFixedServiceInput = (typeName, value, index) => {
    const obj = [...fixedService];
    obj[index][typeName] = value;
    setFixedService(obj);
  };
  const handleImageVariationServiceInput = (typeName, value, index) => {
    const obj = [...imageVariation];
    obj[index][typeName] = value;
    setImageVariation(obj);
  };
  const handleImageSteps = (typeName, value, index) => {
    const obj = [...steps];
    obj[index][typeName] = value;
    setSteps(obj);
  };
  const handleImageCfg = (typeName, value, index) => {
    const obj = [...cfg];
    obj[index][typeName] = value;
    setCfg(obj);
  };
  return (
    <form onSubmit={addSubscription}>
      <div className="content-container w-full rounded-2xl bg-white">
        <div className="content-body-items flex-1 p-10">
          <div className="heading w-full mb-6">
            <h1 className="text-26 font-bold text-dark-gray antialiased">
              {pageTitle}
            </h1>
          </div>
          <div className="personal-info-tab w-full flex flex-col justify-between">
            <div className="flex flex-col-reverse sm:flex-row">
              <div className="flex-1 sm:mr-10">
                <div className="fields w-full">
                  {/* first name and last name */}
                  <div className="xl:flex xl:space-x-7 mb-6">
                    <div className="field w-full mb-6 xl:mb-0">
                      <InputCom
                        label="Name"
                        type="text"
                        name="name"
                        inputHandler={(e) => setName(e.target.value)}
                        value={name}
                        placeholder="Plan Name"
                        error={!!errorMessage.cateName}
                      />

                      <small className="text-red-400 ml-6 italic font-bold">
                        {errorMessage.cateName}
                      </small>
                    </div>
                    <div className="field w-full mb-6 xl:mb-0">
                      <InputCom
                        label="Price"
                        type="text"
                        name="price"
                        inputHandler={(e) => setPrice(e.target.value)}
                        value={price}
                        placeholder="Price"
                        error={!!errorMessage.price}
                      />

                      <small className="text-red-400 ml-6 italic font-bold">
                        {errorMessage.price}
                      </small>
                    </div>
                  </div>
                  <div className="xl:flex xl:space-x-7 mb-6">
                    <div className="field w-full mb-6 xl:mb-0">
                      <div className="chart-heading mb-4 xl:flex justify-between items-center">
                        <SecondSelect
                          label="Expiry"
                          name="Status"
                          datas={status}
                          action={(value, id) =>
                            setSubscriptionExpire({ value, id })
                          }
                          selectedValue={subscriptionExpire.value}
                          error={!!errorMessage.status}
                        />
                      </div>
                      <small className="text-red-400 ml-6 italic font-bold">
                        {errorMessage.status}
                      </small>
                    </div>
                  </div>
                  <div className="xl:flex xl:space-x-7 mb-6">
                    <div className="field w-full mb-6 xl:mb-0">
                      <label
                        className="input-label text-dark-gray text-xl font-bold block mb-2.5"
                        htmlFor="Description"
                      >
                        Fixed Services
                        <small className="text-red-400">*</small>
                      </label>

                      <div className="chart-heading mb-4 xl:flex flex-col justify-center items-center bg-gray-200 p-5 rounded-lg">
                        {fixedService.map((item, i) => (
                          <div
                            className="xl:flex xl:space-x-7 w-full mb-4"
                            key={i}
                          >
                            <div className="field flex-1 mb-6 xl:mb-0">
                              <InputCom
                                label="Label"
                                type="text"
                                name="label"
                                value={item.label}
                                readOnlyValue={true}
                                // error={!!errorMessage.cateName}
                              />
                            </div>
                            <div className="field flex-1 mb-6 xl:mb-0">
                              <InputCom
                                label="Size"
                                type="text"
                                name="size"
                                value={item.size}
                                readOnlyValue={true}
                                // error={!!errorMessage.cateName}
                              />
                            </div>
                            <div className="field flex-1 mb-6 xl:mb-0">
                              <InputCom
                                label="Price"
                                type="text"
                                name="price"
                                inputHandler={(e) =>
                                  handleImageFixedServiceInput(
                                    "price",
                                    e.target.value,
                                    i
                                  )
                                }
                                value={item.price}
                                // readOnlyValue={true}
                                // error={!!errorMessage.cateName}
                              />
                            </div>
                            <div className="field flex-1 mb-6 xl:mb-0">
                              <SecondSelect
                                label="Status"
                                name="status"
                                datas={titleStatus}
                                action={(value, id) =>
                                  handleImageFixedServiceInput(
                                    "status",
                                    value === "Active" ? true : false,
                                    i
                                  )
                                }
                                selectedValue={
                                  item.status === true ? "Active" : "Inactive"
                                }

                                // error={!!errorMessage.status}
                              />
                            </div>
                          </div>
                        ))}
                        {imageVariation.map((item, i) => (
                          <div
                            className="xl:flex xl:space-x-7 w-full mb-4"
                            key={i}
                          >
                            <div className="field flex-1 mb-6 xl:mb-0">
                              <InputCom
                                label="Label"
                                type="text"
                                name="label"
                                value={item.label}
                                readOnlyValue={true}
                                // error={!!errorMessage.cateName}
                              />
                            </div>
                            <div className="field flex-1 mb-6 xl:mb-0">
                              <InputCom
                                label="Amount"
                                type="text"
                                name="amount"
                                value={item.variation}
                                inputHandler={(e) =>
                                  handleImageVariationServiceInput(
                                    "variation",
                                    e.target.value,
                                    i
                                  )
                                }
                                // readOnlyValue={true}
                                // error={!!errorMessage.cateName}
                              />
                            </div>
                            <div className="field flex-1 mb-6 xl:mb-0">
                              <SecondSelect
                                label="Status"
                                name="status"
                                datas={titleStatus}
                                action={(value, id) =>
                                  handleImageVariationServiceInput(
                                    "status",
                                    value === "Active" ? true : false,
                                    i
                                  )
                                }
                                selectedValue={
                                  item.status === true ? "Active" : "Inactive"
                                }
                              />
                            </div>
                          </div>
                        ))}
                        {steps.map((item, i) => (
                          <div
                            className="xl:flex xl:space-x-7 w-full mb-4"
                            key={i}
                          >
                            <div className="field flex-1 mb-6 xl:mb-0">
                              <InputCom
                                label="Label"
                                type="text"
                                name="label"
                                value={item.label}
                                readOnlyValue={true}
                                // error={!!errorMessage.cateName}
                              />
                            </div>
                            <div className="field flex-1 mb-6 xl:mb-0">
                              <InputCom
                                label="Amount"
                                type="text"
                                name="amount"
                                value={item.steps}
                                inputHandler={(e) =>
                                  handleImageSteps("steps", e.target.value, i)
                                }
                                // readOnlyValue={true}
                                // error={!!errorMessage.cateName}
                              />
                            </div>
                            <div className="field flex-1 mb-6 xl:mb-0">
                              <SecondSelect
                                label="Status"
                                name="status"
                                datas={titleStatus}
                                action={(value, id) =>
                                  handleImageVariationServiceInput(
                                    "status",
                                    value === "Active" ? true : false,
                                    i
                                  )
                                }
                                selectedValue={
                                  item.status === true ? "Active" : "Inactive"
                                }
                              />
                            </div>
                          </div>
                        ))}
                        {cfg.map((item, i) => (
                          <div
                            className="xl:flex xl:space-x-7 w-full mb-4"
                            key={i}
                          >
                            <div className="field flex-1 mb-6 xl:mb-0">
                              <InputCom
                                label="Label"
                                type="text"
                                name="label"
                                value={item.label}
                                readOnlyValue={true}
                                // error={!!errorMessage.cateName}
                              />
                            </div>
                            <div className="field flex-1 mb-6 xl:mb-0">
                              <InputCom
                                label="Amount"
                                type="text"
                                name="amount"
                                value={item.cfg}
                                inputHandler={(e) =>
                                  handleImageCfg("cfg", e.target.value, i)
                                }
                                // readOnlyValue={true}
                                // error={!!errorMessage.cateName}
                              />
                            </div>
                            <div className="field flex-1 mb-6 xl:mb-0">
                              <SecondSelect
                                label="Status"
                                name="status"
                                datas={titleStatus}
                                action={(value, id) =>
                                  handleImageVariationServiceInput(
                                    "status",
                                    value === "Active" ? true : false,
                                    i
                                  )
                                }
                                selectedValue={
                                  item.status === true ? "Active" : "Inactive"
                                }
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="xl:flex xl:space-x-7 mb-6">
                    <div className="field w-full mb-6 xl:mb-0">
                      <label
                        className="input-label text-dark-gray text-xl font-bold block mb-2.5"
                        htmlFor="Description"
                      >
                        Services
                        <small className="text-red-400">*</small>
                      </label>
                      <small className="text-green-300 italic">
                        This services will be showing on frontend
                      </small>

                      <div className="chart-heading mb-4 xl:flex flex-col justify-center items-center bg-gray-200 p-5 rounded-lg">
                        {description.map((item, i) => (
                          <div
                            className="xl:flex xl:space-x-7 w-full mb-4"
                            key={i}
                          >
                            <div className="field flex-1 mb-6 xl:mb-0">
                              <InputCom
                                label="Name"
                                type="text"
                                name="name"
                                inputHandler={(e) =>
                                  handleTypeInput("name", e.target.value, i)
                                }
                                value={item.name}

                                // error={!!errorMessage.cateName}
                              />
                            </div>
                            <div className="field flex-1 mb-6 xl:mb-0">
                              <SecondSelect
                                label="Status"
                                name="status"
                                datas={titleStatus}
                                action={(value, id) =>
                                  handleTypeInput(
                                    "status",
                                    value === "Active" ? true : false,
                                    i
                                  )
                                }
                                selectedValue={
                                  item.status === true ? "Active" : "Inactive"
                                }

                                // error={!!errorMessage.status}
                              />
                              {/* <small className="text-red-400 ml-6 italic font-bold">
                                    {errorMessage.status}
                                  </small> */}
                            </div>
                            <div className="field mb-6 xl:mb-0 flex items-end">
                              <div
                                className="text-red-400 font-medium cursor-pointer"
                                onClick={() => deleteDescription(i)}
                              >
                                <AiFillDelete className="text-5xl" />
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="w-full flex justify-end mt-5 px-5">
                          <button
                            type="button"
                            className="w-[152px] h-[46px] flex justify-center items-center gradientButton text-base rounded-full text-white"
                            onClick={addDescription}
                          >
                            Add Description
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* {categoryType === "nftCategory" && (
                    <>
                      <div className="xl:flex xl:space-x-7 mb-6">
                        <div className="field w-full mb-6 xl:mb-0">
                          <label
                            className="input-label text-dark-gray text-xl font-bold block mb-2.5"
                            htmlFor="Description"
                          >
                            Description
                            <small className="text-red-400">*</small>
                          </label>
                          <div className="chart-heading mb-4 xl:flex flex-col justify-center items-center bg-gray-200 p-5 rounded-lg">
                            {description.map((item, i) => (
                              <div
                                className="xl:flex xl:space-x-7 w-full mb-4"
                                key={i}
                              >
                                <div className="field flex-1 mb-6 xl:mb-0">
                                  <InputCom
                                    label="Title"
                                    type="text"
                                    name="title"
                                    inputHandler={(e) =>
                                      handleTypeInput(
                                        "title",
                                        e.target.value,
                                        i
                                      )
                                    }
                                    value={item.title}
                                    placeholder="Title"
                                    // error={!!errorMessage.cateName}
                                  />
                                </div>
                                <div className="field flex-1 mb-6 xl:mb-0">
                                  <SecondSelect
                                    label="Status"
                                    name="status"
                                    datas={titleStatus}
                                    action={(value, id) =>
                                      handleTypeInput(
                                        "status",
                                        value === "Active" ? true : false,
                                        i
                                      )
                                    }
                                    selectedValue={
                                      item.status === true
                                        ? "Active"
                                        : "Inactive"
                                    }
                                  />
                               
                                </div>

                                <div className="field mb-6 xl:mb-0 flex items-end">
                                  <div
                                    className="text-red-400 font-medium cursor-pointer"
                                    onClick={() => deleteDescription(i)}
                                  >
                                    <AiFillDelete className="text-5xl" />
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="w-full flex justify-end mt-5 px-5">
                              <button
                                type="button"
                                className="w-[152px] h-[46px] flex justify-center items-center gradientButton text-base rounded-full text-white"
                                onClick={addDescription}
                              >
                                Add Description
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )} */}
                </div>
              </div>
            </div>

            <div className="content-footer w-full">
              <div className="w-full h-[120px] border-t border-light-purple flex justify-end items-center">
                <div className="flex space-x-4 mr-9">
                  <Link to={cancelUrl}>
                    <button
                      type="button"
                      className="text-18 text-light-red tracking-wide "
                    >
                      <span className="border-b border-light-red">Cancel</span>
                    </button>
                  </Link>

                  <button
                    type="submit"
                    className="w-[152px] h-[46px] flex justify-center items-center gradientButton text-base rounded-full text-white"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
