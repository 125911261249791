import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export default function CheckNewPass({ children }) {
  const { isLogin, isNewAdmin } = useSelector((store) => store.user);

  if (isLogin) {
    if (!isNewAdmin) {
      return <Navigate to="/" replace />;
    }
  }
  if (!isLogin) {
    return <Navigate to="/login" replace />;
  }
  return children || <Outlet />;
}
