import React from "react";

export default function CheckBox({ title, name, checkBoxHandler, value }) {
  return (
    <>
      <div className="flex items-center py-1 px-3 rounded-md bg-gray-200">
        <input
          type="checkbox"
          id={name}
          className="indeterminate:bg-gray-300 w-4 h-4 mr-3"
          onChange={() => checkBoxHandler(name)}
          checked={value === true ?? false}
        />
        <label htmlFor={name} className="text-md">
          {title}
        </label>
      </div>
    </>
  );
}
