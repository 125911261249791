/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
/* eslint-disable no-useless-return */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CateForm from "../../components/Categories/CateForm";
import LottieLoader from "../../components/Helpers/LottieLoader";
import Layout from "../../components/Partials/Layout";
import useRequestFun from "../../hooks/Axios/useRequestFun";
import useFileUpload from "../../hooks/useFileUpload";

export default function EditProCategory() {
  const [isLoader, setIsLoader] = useState(false);
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [logo, setLogo] = useState("");
  const [mimeType, setLogoMimeType] = useState("");

  const { getRequest, putRequest } = useRequestFun();
  const { fileUpload } = useFileUpload();
  const location = useLocation();
  const navigate = useNavigate();
  const [status] = useState([
    {
      _id: 1,
      name: "Active",
      value: "active",
    },
    {
      _id: 2,
      name: "Inactive",
      value: "inactive",
    },
  ]);
  const [cateStatus, setCateStatus] = useState({
    value: "Select Status",
    id: "",
  });

  const [errorMessage, setErrorMessage] = useState({
    cateName: "",
    status: "",
  });

  useEffect(() => {
    getRequest(`getproductcategorybyid/${location.state.id}`).then((res) => {
      setName(res.data.data.name);
      setImage(res.data.data.icon);
      const id = res.data.data.status === "active" ? 1 : 2;
      const hvStatus =
        res.data.data.status === "active" ? "Active" : "Inactive";

      setCateStatus({ value: hvStatus, id });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UpdateCategoryHandle = async (e) => {
    e.preventDefault();
    return toast.warning("Disabled For Demo Version!");
    let isInvalid = false;
    if (name === "") {
      setErrorMessage({
        ...errorMessage,
        cateName: "Category Name is Required",
      });
      isInvalid = true;
    } else if (!cateStatus.id || cateStatus.id === "") {
      setErrorMessage({
        ...errorMessage,
        status: "Status is Required",
      });
      isInvalid = true;
    } else {
      setErrorMessage({
        ...errorMessage,
        status: "",
      });
    }
    if (isInvalid) {
      return;
    }
    setIsLoader(true);
    // eslint-disable-next-line no-underscore-dangle
    const statusLabel = status.find((item) => item._id === cateStatus.id);

    const updateData = {
      name,
      status: statusLabel.value,
    };

    let fileUrl = "";
    if (logo) {
      fileUrl = await fileUpload(logo, "admin", mimeType);
      updateData.icon = fileUrl;
    }

    putRequest(`updateproductcategory/${location.state.id}`, updateData)
      .then((res) => {
        toast.success(res.data.message);
        setIsLoader(false);
        navigate("/list/product-category");
      })
      .catch((error) => {
        toast.warning(error.response.data.message);
        setIsLoader(false);
      });
  };
  return (
    <>
      <Layout>
        {isLoader ? (
          <LottieLoader />
        ) : (
          <div className="settings-wrapper w-full relative mb-10">
            <div className="mainWrapper w-full">
              {/* heading */}
              <CateForm
                addCategory={(e) => UpdateCategoryHandle(e)}
                name={name}
                setName={(e) => setName(e.target.value)}
                errorMessage={errorMessage}
                status={status}
                setCateStatus={(values) =>
                  setCateStatus({ value: values.value, id: values.id })
                }
                cateStatus={cateStatus}
                setImage={(img) => setLogo(img)}
                viewImage={image}
                mimeType={(mType) => setLogoMimeType(mType)}
                pageTitle="Update Product Category"
                cancelUrl="/list/product-category"
              />
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}
